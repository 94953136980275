// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { createAction } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
// -------------------------------------------------
// Authentication API functions
import { onAuthStateChanged } from "firebase/auth";
import auth from "../api-routes/auth";
import { fbAuth } from "../firebase";
// -------------------------------------------------
// Page for loading
import Loading from "../pages/Loading";
// -------------------------------------------------
// Redux
import { setAlert, setScreenSize } from "../redux/reducers/ui";
import {
  fetchUserByFirebaseId,
  postNewUser,
  patchCurrentUser,
  setUserStatus,
} from "../redux/reducers/user";
import { fetchDefaultStudy } from "../redux/reducers/studies";
import { fetchNotificationListByUserId } from "../redux/reducers/notifications";
import {
  postTicketList,
  fetchTicketsFromOneUserAndStudy,
  fetchTicketListFromOwnedStudies,
  setTicketAvailabilityList,
  ticketsSelectors,
} from "../redux/reducers/tickets";
import {
  fetchTaskResponsesFromOneUser,
  fetchTaskResponsesFromOwnedStudies,
} from "../redux/reducers/taskResponses";
import {
  conversationsSelectors,
  fetchConversationList,
} from "../redux/reducers/conversations";
import { fetchMessageListByRecipient } from "../redux/reducers/messages";
import { fetchSurveyList, setSurveysStatus } from "../redux/reducers/surveys";
import { fetchSupervisionFromOwnedStudies } from "../redux/reducers/supervision";
import {
  fetchConsumerList,
  fetchConsumerById,
} from "../redux/reducers/consumers";
// -------------------------------------------------
// Support Functions
import { getTicketAvailability } from "../supportFunc/getTicketAvailability";
import { getTicketsForUserAndTimepoint } from "../supportFunc/getTicketsForUserAndTimepoint";
import { unique } from "../supportFunc/unique";
// =================================================
// CREATE CONTEXT
const AuthContext = React.createContext();
// =================================================
// EXPORT
// -------------------------------------------------
// USE_CONTEXT FUNCTION
export function useAuth() {
  return useContext(AuthContext);
}
// -------------------------------------------------
// PROVIDER FUNCTION
export function AuthProvider({ children }) {
  const { t } = useTranslation("errors");
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const initStore = createAction("store/init");
  const currentUser = useSelector((state) => state.user.currentUser);
  const ghostUser = useSelector((state) => state.user.ghostUser);
  const userStatus = useSelector((state) => state.user.status);
  const userError = useSelector((state) => state.user.errorMsg);
  const notificationsStatus = useSelector(
    (state) => state.notifications.status,
  );
  const consumersStatus = useSelector((state) => state.consumers.status);
  const ticketList = useSelector((state) => ticketsSelectors.selectAll(state));
  const ticketsStatus = useSelector((state) => state.tickets.status);
  const ticketsError = useSelector((state) => state.tickets.errorMsg);
  const responsesStatus = useSelector((state) => state.taskResponses.status);
  const responsesError = useSelector((state) => state.taskResponses.errorMsg);
  const surveysStatus = useSelector((state) => state.surveys.status);
  const surveysError = useSelector((state) => state.surveys.errorMsg);
  const conversationsStatus = useSelector(
    (state) => state.conversations.status,
  );
  const conversationList = useSelector((state) =>
    conversationsSelectors.selectAll(state),
  );
  const supervisionStatus = useSelector((state) => state.supervision.status);
  const conversationsError = useSelector(
    (state) => state.conversations.errorMsg,
  );
  const messagesStatus = useSelector((state) => state.messages.status);
  const messagesError = useSelector((state) => state.messages.errorMsg);
  // -----------------------------------------------
  // Local state
  const [currentAuth, setCurrentAuth] = useState(null);
  const [verifiedAuth, setVerifiedAuth] = useState(null);
  const [authStatus, setAuthStatus] = useState("loading"); // 'idle' | 'loading' | 'succeeded' | 'failed'
  const [ticketAvailabilityStatus, setTicketAvailabilityStatus] =
    useState("idle");
  // -----------------------------------------------
  // Timeout for ticket availability calculation
  const [ticketsAvailabilityTimeoutId, setTicketsAvailabilityTimeoutId] =
    useState(null);
  // =================================================
  // FUNCTIONS
  const getLocaleFromBrowser = () => {
    let locale = window.navigator.language.toLowerCase();
    if (locale.length < 2) {
      locale = "en";
    }
    locale = locale.substring(0, 2);
    if (locale !== "en" && locale !== "nl" && locale !== "de") {
      locale = "en";
    }
    return locale;
  };
  // -----------------------------------------------
  // Posts a new user object to the data base and creates tickets for default study
  const createNewUser = async (authObj) => {
    // Initilize ticket list
    let newTicketList = [];
    let appendTickets;
    let id = uuid();
    const locale = getLocaleFromBrowser();
    // Post new user to DB
    const resUser = await dispatch(
      postNewUser({
        requestingUser: authObj,
        body: {
          data: {
            _id: id,
            firebaseId: currentAuth.uid,
            email: currentAuth.email,
            locale,
          },
        },
      }),
    );
    // Fetch the Default study object from database
    const resStudy = await dispatch(
      fetchDefaultStudy({ requestingUser: authObj }),
    );
    if (!resStudy.payload || !resStudy.payload.defaultStudy) {
      // Set user status to "succeeded"
      dispatch(setUserStatus({ status: "succeeded" }));
      return;
    }
    // Create new ticket list
    for (
      var i = 0;
      i <
      resUser.payload.user.studyEnrollmentList[0].timepointAssignmentList
        .length;
      i++
    ) {
      if (
        resUser.payload.user.studyEnrollmentList[0].timepointAssignmentList[i]
          .startDate
      ) {
        appendTickets = getTicketsForUserAndTimepoint(
          resUser.payload.user,
          resStudy.payload.defaultStudy,
          resUser.payload.user.studyEnrollmentList[0].timepointAssignmentList[i]
            .timepointId,
          "taskResponses",
        );
        if (appendTickets) {
          newTicketList = [...newTicketList, ...appendTickets];
        }
      }
    }
    if (newTicketList.length > 0) {
      await dispatch(
        postTicketList({
          requestingUser: authObj,
          body: {
            data: newTicketList,
            meta: { userId: resUser._id, ticketsToCreate: "new" },
          },
        }),
      );
    }
    // Set user status to "succeeded"
    dispatch(setUserStatus({ status: "succeeded" }));
  };
  // -----------------------------------------------
  // Throws and error if something has gone wrong
  const trowError = async (error) => {
    await auth.signOut();
    dispatch(
      setAlert({
        type: "dialog",
        title: t("critical-error"),
        message: t("reload-auth-error", {
          error: auth.parseErrorMessage(error),
        }),
      }),
    );
    setAuthStatus("failed");
  };
  // -----------------------------------------------
  // Reloads current auth object and gets account details and study enrollment
  const reloadCurrentAuth = async (authObj) => {
    setAuthStatus("loading");
    try {
      if (authObj) {
        await authObj.reload();
        await authObj.getIdToken(/* forceRefresh */ true);
      }
      // Set the auth state
      setCurrentAuth(authObj);
      // If there is no authObj, set 'verified' to false
      if (!authObj) {
        console.log("AUTH: No user signed in");
        setVerifiedAuth(false);
        setAuthStatus("succeeded");
        // If we have a authObj, but not verified, set 'verified' to false
      } else if (!authObj.emailVerified) {
        console.log("AUTH: Unverified user signed in");
        setVerifiedAuth(false);
        setAuthStatus("succeeded");
      } else if (authObj.emailVerified) {
        // If we have a verified user, set 'verified' to true
        console.log("AUTH: Verified user signed in");
        setVerifiedAuth(true);
        // Do not set the Auth status yet,
        // ... in the 'useEffect' below, we first fetch the user from the database
        // ... and set Auth status to 'succeeded' when all is done.
      }
    } catch (error) {
      trowError(error);
    }
  };
  // -----------------------------------------------
  // Recalculates the ticket availability
  const calculateTicketsAvailability = () => {
    let id;
    if (ticketList.length === 0) {
      return;
    }
    // Call the action function to mutate the ticket list
    const tmp = ticketList.map((ticket) => getTicketAvailability(ticket));
    dispatch(setTicketAvailabilityList({ ticketAvailabilityList: tmp }));
    // Get which ticket has the shortest refresh interval
    const shortest = tmp.reduce((a, b) => {
      return a.refreshEvery < b.refreshEvery ? a : b;
    });
    // Set a timeout to recalculate the ticket availability again on the next interval
    if (
      shortest &&
      shortest.refreshEvery >= 1000 &&
      shortest.refreshEvery <= 24 * 60 * 60 * 1000
    ) {
      id = setTimeout(calculateTicketsAvailability, shortest.refreshEvery);
      setTicketsAvailabilityTimeoutId(id);
    } else if (ticketsAvailabilityTimeoutId) {
      clearTimeout(ticketsAvailabilityTimeoutId);
      setTicketsAvailabilityTimeoutId(null);
    }
  };
  // =================================================
  // USE EFFECT #1
  // -----------------------------------------------
  // Add event listener to monitor if the Auth state changes
  useEffect(() => {
    const eventListenerFcn = onAuthStateChanged(fbAuth, async (authObj) => {
      console.log("AUTH: state changed");
      await reloadCurrentAuth(authObj);
    });
    return eventListenerFcn;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // =================================================
  // USE EFFECT #2
  // -----------------------------------------------
  // Upon changing the user, fetch additonal stuff from the database
  useEffect(
    () => {
      // -----------------------------------------------
      // If no user is signed in, then initialize the Redux store
      // Except when someone is completing an anonymous survey
      if (!currentAuth || !verifiedAuth) {
        dispatch(initStore());
        dispatch(setScreenSize());
        return;
      }
      // -----------------------------------------------
      // Deal with errors
      // -----------------------------------------------
      // Error with fetching or creating a user
      if (userStatus === "failed" && userError !== "Not Found") {
        // An unexpected critical error has occurred
        setAuthStatus("idle");
        dispatch(
          setAlert({
            type: "dialog",
            title: t("critical-error"),
            message: t("fetch-user-error", { error: userError }),
          }),
        );
        return; // Do not continue, there is a critical error
      }
      // -----------------------------------------------
      // Error with fetching task responses
      if (responsesStatus === "failed") {
        // An unexpected critical error has occurred
        setAuthStatus("idle");
        dispatch(
          setAlert({
            type: "dialog",
            title: t("critical-error"),
            message: t("fetch-task-responses-error", { error: responsesError }),
          }),
        );
        return; // Do not continue, there is a critical error
      }
      // -----------------------------------------------
      // Error with fetching or creating tickets
      if (ticketsStatus === "failed") {
        // An unexpected critical error has occurred
        setAuthStatus("idle");
        dispatch(
          setAlert({
            type: "dialog",
            title: t("critical-error"),
            message: t("fetch-tickets-error", { error: ticketsError }),
          }),
        );
        return; // Do not continue, there is a critical error
      }
      // -----------------------------------------------
      // Error with fetching surveys
      if (surveysStatus === "failed") {
        // An unexpected critical error has occurred
        setAuthStatus("idle");
        dispatch(
          setAlert({
            type: "dialog",
            title: t("critical-error"),
            message: t("fetch-surveys-error", { error: surveysError }),
          }),
        );
        return; // Do not continue, there is a critical error
      }
      // -----------------------------------------------
      // Error with fetching conversations
      if (conversationsStatus === "failed") {
        // An unexpected critical error has occurred
        setAuthStatus("idle");
        dispatch(
          setAlert({
            type: "dialog",
            title: t("critical-error"),
            message: t("fetch-conversations-error", {
              error: conversationsError,
            }),
          }),
        );
        return; // Do not continue, there is a critical error
      }
      // -----------------------------------------------
      // Error with fetching messages
      if (messagesStatus === "failed") {
        // An unexpected critical error has occurred
        setAuthStatus("idle");
        dispatch(
          setAlert({
            type: "dialog",
            title: t("critical-error"),
            message: t("fetch-messages-error", { error: messagesError }),
          }),
        );
        return; // Do not continue, there is a critical error
      }
      // -----------------------------------------------
      // Fetch the user information from the database
      if (userStatus === "idle") {
        dispatch(
          fetchUserByFirebaseId({
            requestingUser: currentAuth,
            firebaseId: currentAuth.uid,
          }),
        );
        return; // Do not continue before this is successfully done
      }
      // -----------------------------------------------
      // Ok, no errors, continue
      // -----------------------------------------------
      // This block deals with setting the user account information on the database
      if (userStatus === "failed" && userError === "Not Found") {
        // If user account does not exist, create now
        createNewUser(currentAuth);
        return; // Do not continue before this is successfully done
      } else if (
        userStatus === "succeeded" &&
        currentUser &&
        !ghostUser &&
        currentAuth &&
        currentUser.email !== currentAuth.email &&
        currentUser.deletedOn === null
      ) {
        // The email field in the Auth object has changed but not yet changed in the database
        // This can happen when an email-link is used to revert back to a previous email address while not signed in
        dispatch(
          patchCurrentUser({
            requestingUser: currentAuth,
            body: {
              data: {
                _id: currentUser._id,
                email: currentAuth.email,
              },
            },
          }),
        );
        return; // Do not continue before this is successfully done
      }
      // -----------------------------------------------
      // Get all the survey responses this user has ever given
      // Or if the user is not a volunteer, get all the responses to owned studies
      if (userStatus === "succeeded" && responsesStatus === "idle") {
        if (currentUser && currentUser.primaryRole === "participant") {
          dispatch(
            fetchTaskResponsesFromOneUser({
              requestingUser: currentAuth,
              userId: currentUser._id,
            }),
          );
        } else if (
          currentUser &&
          (currentUser.primaryRole === "superuser" ||
            currentUser.primaryRole === "admin")
        ) {
          dispatch(
            fetchTaskResponsesFromOwnedStudies({
              requestingUser: currentAuth,
              userId: currentUser._id,
            }),
          );
        }
      }
      // -----------------------------------------------
      // Get all the notifications related to this user
      if (userStatus === "succeeded" && notificationsStatus === "idle") {
        dispatch(
          fetchNotificationListByUserId({
            requestingUser: currentAuth,
            userId: currentUser._id,
          }),
        );
      }
      // -----------------------------------------------
      // Fetch all supervision documents (admins only)
      if (
        userStatus === "succeeded" &&
        supervisionStatus === "idle" &&
        currentUser &&
        currentUser.primaryRole !== "participant"
      ) {
        dispatch(
          fetchSupervisionFromOwnedStudies({
            requestingUser: currentAuth,
            userId: currentUser._id,
          }),
        );
      }
      // -----------------------------------------------
      // Fetch all conversations that the current user is owner of
      if (userStatus === "succeeded" && conversationsStatus === "idle") {
        dispatch(
          fetchConversationList({
            requestingUser: currentAuth,
            userId: currentUser._id,
          }),
        );
      }
      // -----------------------------------------------
      // Fetch all conversations that the current user is owner of
      if (userStatus === "succeeded" && messagesStatus === "idle") {
        dispatch(
          fetchMessageListByRecipient({
            requestingUser: currentAuth,
            recipientId: currentUser._id,
          }),
        );
      }
      // -----------------------------------------------
      // Fetch all consumers
      if (conversationsStatus === "succeeded" && consumersStatus === "idle") {
        if (
          currentUser &&
          (currentUser.primaryRole === "superuser" ||
            currentUser.primaryRole === "admin")
        ) {
          dispatch(fetchConsumerList({ requestingUser: currentAuth }));
        } else {
          let consumerIds = [];
          conversationList.forEach(
            (conversation) =>
              (consumerIds = [...consumerIds, ...conversation.userIdList]),
          );
          consumerIds = unique(consumerIds);
          consumerIds.forEach((consumerId) =>
            dispatch(
              fetchConsumerById({
                requestingUser: currentAuth,
                userId: consumerId,
              }),
            ),
          );
        }
      }
      // -----------------------------------------------
      // Now get all the tickets from the database
      if (
        userStatus === "succeeded" &&
        responsesStatus === "succeeded" &&
        ticketsStatus === "idle"
      ) {
        if (currentUser && currentUser.primaryRole === "participant") {
          dispatch(
            fetchTicketsFromOneUserAndStudy({
              requestingUser: currentAuth,
              userId: currentUser._id,
              studyId:
                currentUser.studyEnrollmentList &&
                currentUser.studyEnrollmentList.length > 0
                  ? currentUser.studyEnrollmentList[0].studyId
                  : "no-enrolled-study",
            }),
          );
        } else if (
          currentUser &&
          (currentUser.primaryRole === "superuser" ||
            currentUser.primaryRole === "admin")
        ) {
          dispatch(
            fetchTicketListFromOwnedStudies({
              requestingUser: currentAuth,
              userId: currentUser._id,
            }),
          );
        }
      }
      // -----------------------------------------------
      // Fetch all surveys listed in the ticketlist
      if (
        userStatus === "succeeded" &&
        ticketsStatus === "succeeded" &&
        surveysStatus === "idle"
      ) {
        // First extract all the unique survey id's from all the tickets
        const surveyIds = unique(ticketList.map((ticket) => ticket.surveyId));
        // Check if there are any surveys to get
        if (surveyIds.length === 0) {
          dispatch(setSurveysStatus({ status: "partial" }));
        } else {
          // Fetch all surveys from the tickets
          dispatch(fetchSurveyList({ requestingUser: currentAuth }));
        }
      }
      // -----------------------------------------------
      // Calculate the ticket availability and set a timeout to do that again when needed
      if (
        userStatus === "succeeded" &&
        responsesStatus === "succeeded" &&
        ticketsStatus === "succeeded" &&
        ticketAvailabilityStatus === "idle"
      ) {
        setTicketAvailabilityStatus("succeeded");
      }
      // -----------------------------------------------
      // Ok, if all is well, then set auth status to 'succeeded'
      // -----------------------------------------------
      // Set 'userStatus' to succeeded
      if (
        authStatus !== "succeeded" &&
        userStatus === "succeeded" &&
        responsesStatus === "succeeded" &&
        ticketsStatus === "succeeded" &&
        (surveysStatus === "partial" || surveysStatus === "succeeded")
      ) {
        setAuthStatus("succeeded");
      }
    }, // eslint-disable-next-line
    [
      currentAuth,
      verifiedAuth,
      currentUser,
      authStatus,
      userStatus,
      responsesStatus,
      ticketsStatus,
      ticketAvailabilityStatus,
      surveysStatus,
    ],
  );
  // =================================================
  // USE EFFECT #3
  // -----------------------------------------------
  // Create/delete tickets and recalculate ticket availability when ticket list changes
  useEffect(() => {
    if (ticketsAvailabilityTimeoutId !== null) {
      clearTimeout(ticketsAvailabilityTimeoutId);
      setTicketsAvailabilityTimeoutId(null);
    }
    calculateTicketsAvailability();
  }, [ticketsStatus]); // eslint-disable-line react-hooks/exhaustive-deps
  // -----------------------------------------------
  // Combine all methods as a prop of the provider
  const value = {
    authStatus,
    currentAuth,
    verifiedAuth,
    reloadCurrentAuth,
  };
  // -----------------------------------------------
  // Return the Provider
  return (
    <AuthContext.Provider value={value}>
      {authStatus === "loading" ? <Loading /> : children}
    </AuthContext.Provider>
  );
}
