// ########################################
// IMPORT
// ========================================
// Dependencies
import { v4 as uuid } from "uuid";
import { io } from "socket.io-client";
import { DateTime } from "luxon";
import auth from "./auth";
// ########################################
// VARIABLES
// ========================================
// Local variables
const appUrl =
  process.env.REACT_APP_NODE_ENV === "development"
    ? `http://${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}`
    : `https://${process.env.REACT_APP_URL}`;
export const rooturl = `${appUrl}/api`;
// ########################################
// FUNCTIONS
// ========================================
// Creates and executes an API call
export const client = async (method, endpoint, requestingUser, body) => {
  // ----------------------------------------
  // Initialize header
  const reqId = uuid().replace("-", "").slice(0, 6);
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("x-req-id", reqId);
  // ----------------------------------------
  // Add authorization if there is a requesting user
  if (requestingUser) {
    const idToken = await auth.getJWT(requestingUser, false);
    headers.append("authorization", `Bearer ${idToken}`);
  }
  const clientStartTime = DateTime.now();
  // ----------------------------------------
  // Create the config
  const config = {
    method,
    headers,
  };
  // ----------------------------------------
  // Add the payload if there is one
  if (body) {
    config.body = JSON.stringify(body);
  }
  // ----------------------------------------
  // Excecute the API call
  let data;
  try {
    // Log the call to console
    console.log(
      `${reqId} | ${DateTime.now().startOf("second").toISO({
        includeOffset: false,
        suppressMilliseconds: true,
      })} ${method}\n${endpoint}`
    );
    // Make the call and await the response
    const response = await window.fetch(endpoint, config);
    let statusText;
    // Check the status code and hard-code the response text
    // (Note that the response text was not properly set in the live app)
    switch (response.status) {
      case 200:
        statusText = "OK";
        break;
      case 204:
        statusText = "No Content";
        break;
      case 401:
        statusText = "Unauthorized";
        break;
      case 403:
        statusText = "Forbidden";
        break;
      case 404:
        statusText = "Not Found";
        break;
      case 500:
        statusText = "Server Error";
        break;
      default:
        statusText = "I'm a teapot";
        break;
    }
    // Log the response to console
    console.log(
      `${reqId} | ${method} ${response.status} ${statusText} (${DateTime.now()
        .diff(clientStartTime)
        .toFormat("S")}ms)`
    );
    // Check if response is ok
    if (response.ok) {
      // Return a result object
      data = await response.json();
      return {
        status: response.status,
        data,
        headers: response.headers,
        url: response.url,
      };
    }
    // ... response was not ok, throw error
    throw new Error(statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data);
  }
};
// ========================================
// Create simple functions for ease of use
// ----------------------------------------
// Get requests
client.get = (endpoint, requestingUser, options) => {
  return client("GET", endpoint, requestingUser, options);
};
// Post requests (create new)
client.post = (endpoint, requestingUser, body) => {
  return client("POST", endpoint, requestingUser, body);
};
// Patch requests (update)
client.patch = (endpoint, requestingUser, body) => {
  return client("PATCH", endpoint, requestingUser, body);
};
// Delete requests
client.delete = (endpoint, requestingUser) => {
  return client("DELETE", endpoint, requestingUser);
};
// ========================================
// Creates a Socket
// TODO Implement proper header token
export const initSocket = () => {
  console.log(`SOCKET: connecting to ${appUrl}`);
  return io(appUrl, {
    withCredentials: true,
    transports: ["websocket"],
  });
};
