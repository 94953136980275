// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------
// Redux
import { toggleSecDrawer } from "../../redux/reducers/ui";
// -------------------------------------------------
// Support functions
import { array2string } from "../../supportFunc/array2string";
// -------------------------------------------------
// Component elements
import SurveysEditGridContainer from "./Surveys_EditGridContainer";
import TopDrawer from "../App_TopDrawer";
// -------------------------------------------------
// Support functions
import { getRGBA } from "../../supportFunc/getRGBA";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Collapse from "@mui/material/Collapse";
// -------------------------------------------------
// Icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteForever from "@mui/icons-material/DeleteForever";
import ContentCopy from "@mui/icons-material/ContentCopy";
import Link from "@mui/icons-material/Link";
import Save from "@mui/icons-material/Save";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
// =================================================
// FUNCTIONAL COMPONENT
// -----------------------------------------------
const SurveysContentGridContainer = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "surveys.Surveys_ContentGridContainer",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const surveysStatus = useSelector((state) => state.surveys.status);
  const secDrawerIsOpen = useSelector((state) => state.ui.secDrawerIsOpen);
  // -----------------------------------------------
  // Local state
  const hasDependencies =
    props.gridContainer.gridItemList &&
    props.gridContainer.gridItemList.some(
      (gi) =>
        gi.articleList &&
        gi.articleList.some(
          (article) =>
            (props.dependencies &&
              Object.keys(props.dependencies).some((dep) =>
                dep.includes(article._id),
              )) ||
            (props.scoring &&
              props.scoring.some(
                (score) =>
                  (score.arithmetic &&
                    score.arithmetic.some((a) =>
                      a.aliasId.includes(article._id),
                    )) ||
                  (score.grouping &&
                    score.grouping.aliasId.includes(article._id)),
              )),
        ),
    );
  const [obj, setObj] = useState(props.gridContainer);
  const [isCollapsed, setIsCollapsed] = useState(
    props.gridContainer.options.defaultCollapsed,
  );
  const [deleteMenuIsOpen, setDeleteMenuIsOpen] = useState(false);
  const [deleteMenuAnchor, setDeleteMenuAnchor] = useState(null);
  const backgroundColor = getRGBA(
    props.gridContainer.layout.bgColor,
    props.gridContainer.layout.bgOpacity,
    props.gridContainer.options.noCard,
  );
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Handles opening the top drawer for changing survey info
  const handleEdit = (id) => {
    dispatch(toggleSecDrawer({ isOpen: true, id }));
  };
  // -----------------------------------------------
  // Resets local state upon closing of the drawer
  useEffect(() => {
    if (!secDrawerIsOpen) {
      setObj(props.gridContainer);
    }
  }, [props.gridContainer, secDrawerIsOpen]);
  // ===============================================
  // RENDER COMPONENT
  // -----------------------------------------------
  return (
    <>
      {/* ================================================== */}
      {/* TOP DRAWER */}
      {obj && (
        <TopDrawer
          id={obj._id}
          title={t("Edit container")}
          buttons={
            <Button
              disabled={surveysStatus === "loading" || props.disabled}
              color="inherit"
              startIcon={<Save />}
              className="m-2"
              onClick={() =>
                props.handleMutateCurrentSurvey("gridContainer", obj, {
                  page: props.pageIdx,
                  gridContainer: props.gridContainerIdx,
                })
              }
            >
              {surveysStatus === "loading" ? (
                <CircularProgress size="1.5rem" className="text-light" />
              ) : (
                t("Save")
              )}
            </Button>
          }
        >
          <SurveysEditGridContainer
            obj={obj}
            setObj={setObj}
            aliasList={props.aliasList}
            dependencies={props.dependencies}
            scoring={props.scoring}
          />
        </TopDrawer>
      )}
      {/* ================================================== */}
      {/* PAGE SETTINGS*/}
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className="mb-1 border border-grey border-dashed rounded"
        sx={{ padding: "2px" }}
      >
        {/* ================================================== */}
        {/* PAGE NUMBER */}
        <Grid item sx={{ height: "21px" }}>
          <Typography variant="overline" color="slategray" className="ps-2">
            {`container ${props.gridContainerIdx + 1} `}
          </Typography>
        </Grid>
        {!props.disabled && (
          <Grid item>
            {/* ================================================== */}
            {/* PAGE EDIT BUTTONS */}
            <Tooltip arrow title={t("Edit")} placement="top">
              <span>
                <IconButton
                  disabled={surveysStatus === "loading"}
                  edge="end"
                  className="me-1"
                  onClick={() => handleEdit(props.gridContainer._id)}
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip arrow title={t("Copy")} placement="top">
              <span>
                <IconButton
                  disabled={surveysStatus === "loading"}
                  edge="end"
                  className="me-1"
                  onClick={() =>
                    props.handleCopy(
                      props.pageIdx,
                      props.gridContainerIdx,
                      props.gridContainer,
                    )
                  }
                >
                  <ContentCopy />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              arrow
              title={
                props.pageIdx === 0 && props.gridContainerIdx === 0
                  ? t("Can't move up")
                  : t("Move up")
              }
              placement="top"
            >
              <span>
                <IconButton
                  disabled={
                    surveysStatus === "loading" ||
                    (props.pageIdx === 0 && props.gridContainerIdx === 0)
                  }
                  edge="end"
                  className="me-1"
                  onClick={() =>
                    props.handleMove(props.pageIdx, props.gridContainerIdx, -1)
                  }
                >
                  <ArrowUpward />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              arrow
              title={props.isLast ? t("Can't move down") : t("Move down")}
              placement="top"
            >
              <span>
                <IconButton
                  disabled={surveysStatus === "loading" || props.isLast}
                  edge="end"
                  className="me-1"
                  onClick={() =>
                    props.handleMove(props.pageIdx, props.gridContainerIdx, 1)
                  }
                >
                  <ArrowDownward />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              arrow
              title={hasDependencies ? t("Can't delete") : t("Delete")}
              placement="top"
            >
              <span>
                <IconButton
                  disabled={surveysStatus === "loading" || hasDependencies}
                  edge="end"
                  className="me-0"
                  onClick={(e) => {
                    setDeleteMenuIsOpen(true);
                    setDeleteMenuAnchor(e.currentTarget);
                  }}
                >
                  <DeleteForever />
                </IconButton>
              </span>
            </Tooltip>
            <Menu
              anchorEl={deleteMenuAnchor}
              open={deleteMenuIsOpen}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              onClose={() => {
                setDeleteMenuIsOpen(false);
                setDeleteMenuAnchor(null);
              }}
            >
              <MenuItem
                onClick={() =>
                  props.handleDelete(props.pageIdx, props.gridContainerIdx)
                }
              >
                {surveysStatus === "loading" ? (
                  <CircularProgress size="1.5rem" />
                ) : (
                  "Delete"
                )}
              </MenuItem>
            </Menu>
          </Grid>
        )}
        <Grid
          container
          className={props.gridContainer.options.isCollapsable ? "" : "pb-1"}
        >
          <Grid
            item
            xs={12}
            className={props.gridContainer.options.isCollapsable ? "px-2" : ""}
          >
            <Grid
              container
              className={
                props.gridContainer.options.isCollapsable && !isCollapsed
                  ? "py-1 bg-gradient-secondary rounded-top"
                  : props.gridContainer.options.isCollapsable && isCollapsed
                    ? "py-1 bg-gradient-secondary rounded"
                    : "pb-1"
              }
              onClick={(e) =>
                props.gridContainer.options.isCollapsable &&
                setIsCollapsed(!isCollapsed)
              }
            >
              {/* ================================================== */}
              {/* PAGE TITLE */}
              {((props.gridContainer.title && props.gridContainer.title.text) ||
                props.gridContainer.logic) && (
                <Grid
                  item
                  className={
                    props.gridContainer.options.isCollapsable
                      ? "ps-2 flex-grow-1"
                      : "flex-grow-1"
                  }
                >
                  {" "}
                  {props.gridContainer.title && (
                    <Typography
                      variant={props.gridContainer.title.variant}
                      color={props.gridContainer.title.color}
                      align={props.gridContainer.title.align}
                    >
                      {props.gridContainer.title.text}
                    </Typography>
                  )}
                  {props.gridContainer.logic && (
                    <Chip
                      icon={<Link />}
                      label={`conditional on ${array2string(
                        props.gridContainer.logic.map((logicGroup) =>
                          logicGroup.itemList.map(
                            (logicItem) =>
                              props.aliasList[logicItem.condition.aliasId]
                                .alias,
                          ),
                        ),
                      )}`}
                      size="small"
                      variant="outlined"
                      color="info"
                      className={
                        props.gridContainer.title &&
                        props.gridContainer.title.text
                          ? "ms-3 border-0"
                          : "border-0"
                      }
                    />
                  )}
                </Grid>
              )}
              {props.gridContainer.options.isCollapsable && (
                <Grid item className="flex-grow-0 pe-2">
                  <IconButton>
                    {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={props.isDisabled ? "" : "mb-3"}>
          <Grid item xs={12}>
            <Collapse
              in={!isCollapsed || !props.gridContainer.options.isCollapsable}
            >
              {/* ================================================== */}
              {/* PAGE CONTENT */}
              {props.gridContainer.options.isCard ? (
                <Card
                  className={
                    props.gridContainer.options.hasShadow && !props.disabled
                      ? "w-100 shadow"
                      : "w-100 shadow-none"
                  }
                  sx={{
                    backgroundColor:
                      backgroundColor === null ? "#FFFFFF" : backgroundColor,
                  }}
                >
                  <Grid
                    container
                    id="grid-container"
                    direction={props.gridContainer.layout.direction}
                    justifyContent={props.gridContainer.layout.justifyContent}
                    alignContent={props.gridContainer.layout.alignContent}
                    alignItems={props.gridContainer.layout.alignItems}
                    rowSpacing={
                      props.gridContainer.layout.direction === "row"
                        ? props.gridContainer.layout.rowSpacing
                        : { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }
                    }
                    columnSpacing={
                      props.gridContainer.layout.direction === "row"
                        ? props.gridContainer.layout.columnSpacing
                        : { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }
                    }
                    className={props.gridContainer.layout.classNames}
                    sx={{
                      minHeight: `${props.gridContainer.layout.minHeight}px`,
                      paddingLeft:
                        props.gridContainer.layout.padding.left === "0"
                          ? "1px"
                          : `${props.gridContainer.layout.padding.left}rem`,
                      paddingRight:
                        props.gridContainer.layout.padding.right === "0"
                          ? "1px"
                          : `${props.gridContainer.layout.padding.right}rem`,
                      paddingTop:
                        props.gridContainer.layout.padding.top === "0"
                          ? "1px"
                          : `${props.gridContainer.layout.padding.top}rem`,
                      paddingBottom:
                        props.gridContainer.layout.padding.bottom === "0"
                          ? "1px"
                          : `${props.gridContainer.layout.padding.bottom}rem`,
                      backgroundImage: `url("${props.gridContainer.layout.bgImage}")`,
                      backgroundOrigin:
                        props.gridContainer.layout.bgImageOrigin,
                      backgroundPosition:
                        props.gridContainer.layout.bgImagePosition,
                      backgroundRepeat:
                        props.gridContainer.layout.bgImageRepeat,
                      backgroundSize: props.gridContainer.layout.bgImageSize,
                    }}
                  >
                    {props.children}
                  </Grid>
                </Card>
              ) : (
                <Grid
                  container
                  direction={props.gridContainer.layout.direction}
                  justifyContent={props.gridContainer.layout.justifyContent}
                  alignContent={props.gridContainer.layout.alignContent}
                  alignItems={props.gridContainer.layout.alignItems}
                  rowSpacing={props.gridContainer.layout.rowSpacing}
                  columnSpacing={props.gridContainer.layout.columnSpacing}
                  className={`${props.gridContainer.layout.classNames}`}
                  sx={{
                    backgroundColor:
                      backgroundColor === null
                        ? "rgba(0, 0, 0, 0)"
                        : backgroundColor,
                    minHeight: `${props.gridContainer.layout.minHeight}px`,
                    paddingLeft:
                      props.gridContainer.layout.padding.left === "0"
                        ? "1px"
                        : `${props.gridContainer.layout.padding.left}rem`,
                    paddingRight:
                      props.gridContainer.layout.padding.right === "0"
                        ? "1px"
                        : `${props.gridContainer.layout.padding.right}rem`,
                    paddingTop:
                      props.gridContainer.layout.padding.top === "0"
                        ? "1px"
                        : `${props.gridContainer.layout.padding.top}rem`,
                    paddingBottom:
                      props.gridContainer.layout.padding.bottom === "0"
                        ? "1px"
                        : `${props.gridContainer.layout.padding.bottom}rem`,
                    backgroundImage: `url("${props.gridContainer.layout.bgImage}")`,
                    backgroundOrigin: props.gridContainer.layout.bgImageOrigin,
                    backgroundPosition:
                      props.gridContainer.layout.bgImagePosition,
                    backgroundRepeat: props.gridContainer.layout.bgImageRepeat,
                    backgroundSize: props.gridContainer.layout.bgImageSize,
                  }}
                >
                  {props.children}
                </Grid>
              )}
            </Collapse>
          </Grid>
        </Grid>
      </Grid>
      {/* ================================================== */}
      {/* ADD PAGE BUTTON */}
      {!props.disabled && <div className="mt-2">{props.ButtonGroup}</div>}
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveysContentGridContainer;
