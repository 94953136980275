// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
// -------------------------------------------------
// Redux
import { studiesSelectors } from "../redux/reducers/studies";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// -------------------------------------------------
// Icons
import DeleteForever from "@mui/icons-material/DeleteForever";
// =================================================
// FUNCTIONAL COMPONENT
const StudiesHeader = (props) => {
  const { t } = useTranslation("components", { keyPrefix: "Studies_Header" });
  // ===============================================
  // VARIABLES
  // -------------------------------------------------
  // Local state
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  // -------------------------------------------------
  // Redux
  const currentUser = useSelector((state) => state.user.currentUser);
  const currentStudy = useSelector((state) =>
    studiesSelectors.selectById(state, props.currentStudyId)
  );
  const defaultStudyId = useSelector((state) =>
    state.studies.defaultStudy ? state.studies.defaultStudy._id : null
  );
  // ===============================================
  // RENDER COMPONENT
  // -------------------------------------------------
  return currentStudy ? (
    <>
      <Grid container alignItems="center" wrap="nowrap">
        {currentUser &&
          (currentUser.primaryRole === "superuser" ||
            (currentUser.primaryRole === "admin" &&
              currentStudy.userIdList.includes(currentUser._id))) && (
            <Grid item className="pe-2">
              {props.numberOfEnrolledConsumers == 0 && (
                <Tooltip
                  arrow
                  title={
                    currentStudy._id === defaultStudyId
                      ? t("Can't delete default study")
                      : t("Delete")
                  }
                  placement="top"
                >
                  <span>
                    <IconButton
                      disabled={currentStudy._id === defaultStudyId}
                      id="delete"
                      color="inherit"
                      onClick={(e) => {
                        setMenuIsOpen(true);
                        setMenuAnchor(e.currentTarget);
                      }}
                    >
                      <DeleteForever />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </Grid>
          )}
        <Grid item>
          <Tooltip arrow title={t("Invite participants")} placement="top">
            <span>
              <Button
                variant="contained"
                size="small"
                component={NavLink}
                to={`/volunteers?inviteInStudyId=${props.currentStudyId}`}
                className="text-nowrap"
              >
                {t("Invite")}
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
      <Menu
        anchorEl={menuAnchor}
        open={menuIsOpen}
        onClose={() => {
          setMenuIsOpen(false);
          setMenuAnchor(null);
        }}
      >
        <MenuItem
          onClick={() => {
            props.handleDeleteStudy();
            setMenuIsOpen(false);
          }}
        >
          <ListItemIcon>
            <DeleteForever fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("Delete forever")}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  ) : null;
};
// =================================================
// EXPORT COMPONENT
export default StudiesHeader;
