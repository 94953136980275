// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
// =================================================
// FUNCTIONAL COMPONENT
const SurveysEditInfo = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "surveys.Surveys_EditInfo",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const isXS = useSelector((state) => state.ui.isXS);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Sets the local state
  const handleSetObj = (e) => {
    props.setObj((prevState) => {
      let newState = { ...prevState };
      if (e.target.name === "acronym") {
        newState[e.target.name] = e.target.value
          .toUpperCase()
          .replace(/\W/g, "_");
      } else {
        newState[e.target.name] = e.target.value === "" ? null : e.target.value;
      }
      return newState;
    });
  };
  // Survey information keys
  const infoKeys = [
    { key: "officialName", label: t("Official name"), required: true },
    { key: "displayName", label: t("Display name"), required: true },
    {
      key: "acronym",
      label: t("Acronym (3-6 characters)"),
      required: true,
      divider: true,
    },
    {
      key: "DOI",
      label: "DOI",
      required: false,
      title: "Reference to DOI and or PDF of publication",
    },
    { key: "PDF", label: t("URL to PDF"), required: false, divider: true },
    {
      key: "version",
      label: t("Version"),
      required: true,
      title: "Survey version and change log",
    },
    { key: "changelog", label: t("Changelog"), required: false, divider: true },
    {
      key: "httpUrl",
      label: "HTTP URL",
      required: false,
      title: "Alternative HTTP URL",
      explainer:
        "Personal identifiable information should not be stored with other survey data. If this survey collects identifiable information, insert an alternative HTTP URL to post the data to. It will then not be saved on the registry's database.",
    },
  ];
  // ===============================================
  // RENDER COMPONENT
  return (
    <Paper className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-625px"}>
      <Grid container>
        {infoKeys.map((item) => (
          <React.Fragment key={item.key}>
            <Grid item xs={12}>
              {item.title && (
                <Typography variant="h6" className="font-weight-bold">
                  {item.title}
                </Typography>
              )}
              {item.explainer && (
                <Alert severity="info">{item.explainer}</Alert>
              )}
              <Typography variant="overline" className="font-weight-bold">
                {item.required ? `${item.label} *` : `${item.label} (optional)`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                hiddenLabel
                error={item.required && !props.obj[item.key]}
                helperText={
                  item.required &&
                  !props.obj[item.key] &&
                  "Please provide a value"
                }
                disabled={props.isLive && item.key === "acronym"}
                size="small"
                type="text"
                name={item.key}
                value={props.obj[item.key] ? props.obj[item.key] : ""}
                color="secondary"
                variant="filled"
                className="bg-white answer-text-green mt-1 mb-3"
                onChange={handleSetObj}
              />
              {item.divider && <Divider className="my-1" />}
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Paper>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveysEditInfo;
