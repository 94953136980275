// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
// -------------------------------------------------
// Redux
import { toggleSecDrawer } from "../../redux/reducers/ui";
// -------------------------------------------------
// Component elements
import SurveysEditArticle from "./Surveys_EditArticle";
import TopDrawer from "../App_TopDrawer";
import FormArticle from "../Form_Article";
// -------------------------------------------------
// Support functions
import { defaultArticle } from "../../supportFunc/defaultSurveys";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ButtonGroup from "@mui/material/ButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
// -------------------------------------------------
// Icons
import Add from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Save from "@mui/icons-material/Save";
// =================================================
// FUNCTIONAL COMPONENT
// -----------------------------------------------
const SurveysContentArticle = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "surveys.Surveys_ContentArticle",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const surveysStatus = useSelector((state) => state.surveys.status);
  const secDrawerIsOpen = useSelector((state) => state.ui.secDrawerIsOpen);
  // -----------------------------------------------
  // Local state
  const [obj, setObj] = useState(props.article);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Handles opening the top drawer for adding survey article
  const handleAdd = (category) => {
    let newArticle = JSON.parse(JSON.stringify(defaultArticle));
    newArticle._id = uuid();
    newArticle.category = category;
    newArticle.type = category === "media" ? "text" : "textbox";
    setObj(newArticle);
    dispatch(toggleSecDrawer({ isOpen: true, id: newArticle._id }));
  };
  // -----------------------------------------------
  // Handles opening the top drawer for changing survey article
  const handleEdit = (id) => {
    dispatch(toggleSecDrawer({ isOpen: true, id }));
  };
  // -----------------------------------------------
  // Resets local state upon closing of the drawer
  useEffect(() => {
    if (!secDrawerIsOpen) {
      setObj(props.article);
    }
  }, [props.article, secDrawerIsOpen]);
  // ===============================================
  // RENDER COMPONENT
  // -----------------------------------------------
  return (
    <>
      {/* ================================================== */}
      {/* TOP DRAWER */}
      {obj && (
        <TopDrawer
          id={obj._id}
          title={t("Edit article")}
          buttons={
            <Button
              disabled={surveysStatus === "loading" || props.disabled}
              color="inherit"
              startIcon={<Save />}
              className="m-2"
              onClick={() =>
                props.handleMutateCurrentSurvey("article", obj, {
                  page: props.pageIdx,
                  gridContainer: props.gridContainerIdx,
                  gridItem: props.gridItemIdx,
                  article: props.articleIdx,
                })
              }
            >
              {surveysStatus === "loading" ? (
                <CircularProgress size="1.5rem" className="text-light" />
              ) : (
                t("Save")
              )}
            </Button>
          }
        >
          <SurveysEditArticle
            obj={obj}
            setObj={setObj}
            aliasList={props.aliasList}
            dependencies={props.dependencies}
            scoring={props.scoring}
          />
        </TopDrawer>
      )}
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className={
          props.article
            ? "p-1 mb-1 border border-grey border-dashed rounded"
            : ""
        }
      >
        {/* ================================================== */}
        {/* NUMBER */}
        {props.article && <Grid item />}
        {!props.disabled && props.article && (
          <Grid item className="z-index-1000">
            {/* ================================================== */}
            {/* EDIT BUTTONS */}
            <Tooltip arrow title={t("Edit")} placement="top">
              <span>
                <IconButton
                  disabled={surveysStatus === "loading"}
                  edge="end"
                  className="me-0 bg-transparant-white-33"
                  onClick={() => handleEdit(props.article._id)}
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        )}
        {/* ================================================== */}
        {/* CONTENT */}
        {props.article && (
          <Grid item xs={12} sx={props.disabled ? {} : { marginTop: "-25px" }}>
            <FormArticle
              doShow
              noDivider
              isLast={props.isLast}
              article={props.article}
              aliasList={props.aliasList}
              dependencies={props.dependencies}
              scoring={props.scoring}
              classNames={surveysStatus === "loading" && "opacity-25"}
            />
          </Grid>
        )}
      </Grid>
      {!props.disabled && (
        <div className="mt-1">
          <Divider>
            <ButtonGroup>
              <Button
                disabled={surveysStatus === "loading"}
                variant="outlined"
                size="small"
                color="inherit"
                startIcon={<Add />}
                className="border-dashed bg-transparant-white-33"
                onClick={() => handleAdd("question")}
              >
                {t("question")}
              </Button>
              <Button
                disabled={surveysStatus === "loading"}
                variant="outlined"
                size="small"
                color="inherit"
                startIcon={<Add />}
                className="border-dashed bg-transparant-white-33"
                onClick={() => handleAdd("media")}
              >
                {t("media")}
              </Button>
            </ButtonGroup>
          </Divider>
        </div>
      )}
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveysContentArticle;
