// ===============================================
// SURVEY LOGIC
// -----------------------------------------------
const isAnswered = (val) => {
  return val !== "" && val !== null && val !== undefined;
};
// Performs the appropriate logical operation
const checkLogicItem = (currentValue, testValue, operator) => {
  let regex;
  switch (operator) {
    case "EQ":
      if (typeof currentValue === "boolean") {
        return isAnswered(currentValue) && currentValue === true;
      } else {
        return (
          isAnswered(currentValue) &&
          parseFloat(currentValue) === parseFloat(testValue)
        );
      }
    case "NEQ":
      if (typeof currentValue === "boolean") {
        return isAnswered(currentValue) && currentValue === false;
      } else {
        return (
          isAnswered(currentValue) &&
          parseFloat(currentValue) !== parseFloat(testValue)
        );
      }
    case "GT":
      return (
        isAnswered(currentValue) &&
        parseFloat(currentValue) > parseFloat(testValue)
      );
    case "GTEQ":
      return (
        isAnswered(currentValue) &&
        parseFloat(currentValue) >= parseFloat(testValue)
      );
    case "LT":
      return (
        isAnswered(currentValue) &&
        parseFloat(currentValue) < parseFloat(testValue)
      );
    case "LTEQ":
      return (
        isAnswered(currentValue) &&
        parseFloat(currentValue) <= parseFloat(testValue)
      );
    case "ANS":
      return (
        currentValue !== "" &&
        currentValue !== null &&
        currentValue !== undefined
      );
    case "NANS":
      return (
        currentValue === "" ||
        currentValue === null ||
        currentValue === undefined
      );
    case "CONT":
      if (testValue && currentValue) {
        regex = new RegExp(testValue.toLowerCase());
        return regex.test(currentValue.toLowerCase());
      } else {
        return false;
      }
    case "NCONT":
      if (testValue && currentValue) {
        regex = new RegExp(testValue.toLowerCase());
        return !regex.test(currentValue.toLowerCase());
      } else {
        return false;
      }
    default:
      return false;
  }
};
// -----------------------------------------------
// Concatenates two logical values with either AND or OR
const concatLogicBools = (A, B, operator) => {
  switch (operator) {
    case "AND":
      return A && B;
    case "OR":
      return A || B;
    default:
      return false;
  }
};
// -----------------------------------------------
// Checks whether the article logic is true or false
const evaluateLogicObject = (logic, testValues, aliasList) => {
  // If there is no article logic or testing values, return true
  if (!logic) {
    return true;
  }
  if (!testValues) {
    return true;
  }
  // Initialize vars
  // Start by assuming the question should not be shown
  let bool = false;
  let thisGroupBool = false;
  let prevGroupBool = false;
  // For each logic group, test the logic items and concatenate logic
  logic.forEach((logicGroup, i) => {
    // Initialize vars
    let thisItemBool = false;
    let prevItemBool = false;
    // After testing the first logic group, save the logic group value to be concatenated later
    if (i > 0) {
      prevGroupBool = thisGroupBool;
    }
    // For each item in the logic group, test the logic and concatenate
    logicGroup.itemList.forEach((logicItem, j) => {
      // After testing the first logic item, save it for later concatenation
      if (j > 0) {
        prevItemBool = thisItemBool;
      }
      // Perform logical test
      thisItemBool = checkLogicItem(
        testValues[aliasList[logicItem.condition.aliasId].alias],
        logicItem.condition.value,
        logicItem.condition.operator,
      );
      // After the first iteration, concatenate the two booleans with AND or OR
      if (j > 0) {
        thisItemBool = concatLogicBools(
          thisItemBool,
          prevItemBool,
          logicItem.itemOperator,
        );
      }
    });
    // Store the item bool as this groups bool
    thisGroupBool = thisItemBool;
    // After the first iteration, concatenate the two group booleans with AND or OR
    if (i > 0) {
      thisGroupBool = concatLogicBools(
        thisGroupBool,
        prevGroupBool,
        logicGroup.groupOperator,
      );
    }
  });
  // Get final result
  bool = thisGroupBool;
  return bool;
};
// ===============================================
// MAIN EXPORTED FUNCTION
// -------------------------------------------------
// Runs each logic object in a survey
export const evaluateSurveyLogic = (survey, responses) => {
  let isEnabled = {};
  survey.pageList &&
    survey.pageList.forEach((page) => {
      // Check and set 'enabled' of individual pages based on their logic
      isEnabled[page._id] = page.logic
        ? evaluateLogicObject(page.logic, responses, survey.aliasList)
        : true;
      page.gridContainerList &&
        page.gridContainerList.forEach((gc) => {
          // Check and set 'enabled' of individual grid containers based on their logic
          isEnabled[gc._id] = gc.logic
            ? evaluateLogicObject(gc.logic, responses, survey.aliasList)
            : true;
          gc.gridItemList &&
            gc.gridItemList.forEach((gi) => {
              // Check and set 'enabled' of individual grid items based on their logic
              isEnabled[gi._id] = gi.logic
                ? evaluateLogicObject(gi.logic, responses, survey.aliasList)
                : true;
              gi.articleList &&
                gi.articleList.forEach((article) => {
                  // Check and set 'enabled' of individual articles based on their logic
                  isEnabled[article._id] = article.logic
                    ? evaluateLogicObject(
                        article.logic,
                        responses,
                        survey.aliasList,
                      )
                    : true;
                  article.rowList &&
                    article.rowList.forEach((row) => {
                      // Check and set 'enabled' of individual rows based on their logic
                      isEnabled[row._id] = row.logic
                        ? evaluateLogicObject(
                            row.logic,
                            responses,
                            survey.aliasList,
                          )
                        : true;
                    });
                  // If all of the rows are disabled, disable the article
                  if (
                    article.rowList &&
                    article.rowList
                      .map((row) => isEnabled[row._id])
                      .every((bool) => !bool)
                  ) {
                    isEnabled[article._id] = false;
                  }
                });
              // If all of the articles are disabled, disable the grid item
              if (
                gi.articleList &&
                gi.articleList.length > 0 &&
                gi.articleList
                  .map((article) => isEnabled[article._id])
                  .every((bool) => !bool)
              ) {
                isEnabled[gi._id] = false;
              }
            });
          // If all of the grid items are disabled, disable the grid container
          if (
            gc.gridItemList &&
            gc.gridItemList.length > 0 &&
            gc.gridItemList
              .map((gi) => isEnabled[gi._id])
              .every((bool) => !bool)
          ) {
            isEnabled[gc._id] = false;
          }
        });
      // If all of the grid containers are disabled, disable the page
      if (
        page.gridContainerList &&
        page.gridContainerList.length > 0 &&
        page.gridContainerList
          .map((gc) => isEnabled[gc._id])
          .every((bool) => !bool)
      ) {
        isEnabled[page._id] = false;
      }
    });
  return isEnabled;
};
