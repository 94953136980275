// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// -------------------------------------------------
// Redux
import { surveysSelectors } from "../redux/reducers/surveys";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import ListSubheader from "@mui/material/ListSubheader";
// -------------------------------------------------
// Icons
import Star from "@mui/icons-material/Star";
import Archive from "@mui/icons-material/Archive";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import DeleteForever from "@mui/icons-material/DeleteForever";
import School from "@mui/icons-material/School";
import ContentCopy from "@mui/icons-material/ContentCopy";
import ForkRight from "@mui/icons-material/ForkRight";
import Preview from "@mui/icons-material/Preview";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
// =================================================
// FUNCTIONAL COMPONENT
const SurveysHeader = (props) => {
  const { t } = useTranslation("components", { keyPrefix: "Surveys_Header" });
  // ===============================================
  // VARIABLES
  // -------------------------------------------------
  // Local state
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  // -------------------------------------------------
  // Redux
  const currentUser = useSelector((state) => state.user.currentUser);
  const currentSurvey = useSelector((state) =>
    surveysSelectors.selectById(state, props.currentSurveyId),
  );
  // ===============================================
  // RENDER COMPONENT
  // -------------------------------------------------
  return currentSurvey ? (
    <>
      <Grid container alignItems="center" wrap="nowrap">
        <Grid item className="pe-2">
          <Tooltip arrow title={t("Export PDF")} placement="top">
            <span>
              <IconButton
                color="inherit"
                onClick={() => {
                  props.handleSetModalOpen(true);
                }}
              >
                <PictureAsPdf />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
        <Grid item className="pe-2">
          <Tooltip arrow title={t("Preview")} placement="top">
            <span>
              <IconButton
                id="preview"
                color="inherit"
                onClick={() => props.handlePreviewSurvey(0)}
              >
                <Preview />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
        {!props.disabled &&
          currentSurvey.folder !== "archive" &&
          currentUser &&
          (currentUser.primaryRole === "admin" ||
            currentUser.primaryRole === "superuser") && (
            <Grid item className="pe-2">
              <Tooltip arrow title={t("Copy survey")} placement="top">
                <span>
                  <IconButton
                    id="copy"
                    color="inherit"
                    onClick={(e) => {
                      props.handleCopySurvey(currentSurvey);
                    }}
                  >
                    <ContentCopy />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          )}
        {!props.disabled &&
          currentSurvey.folder !== "archive" &&
          currentUser &&
          (currentUser.primaryRole === "admin" ||
            currentUser.primaryRole === "superuser") && (
            <Grid item className="pe-2">
              <Tooltip arrow title={t("Fork survey")} placement="top">
                <span>
                  <IconButton
                    id="copy"
                    color="inherit"
                    onClick={(e) => {
                      props.handleForkSurvey(currentSurvey);
                    }}
                  >
                    <ForkRight />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          )}
        {currentUser &&
          (currentUser.primaryRole === "admin" ||
            currentUser.primaryRole === "superuser") && (
            <Grid item className="pe-2">
              <Tooltip arrow title={t("Move to folder")} placement="top">
                <span>
                  <IconButton
                    id="move"
                    color="inherit"
                    onClick={(e) => {
                      setMenuIsOpen(true);
                      setMenuAnchor(e.currentTarget);
                    }}
                  >
                    <DriveFileMoveIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          )}
        {currentUser.primaryRole === "superuser" &&
          !currentSurvey.isLive &&
          currentSurvey.folder !== "standardized" &&
          (currentSurvey.folder === "archive" ||
            !props.studyList.some(
              (study) => currentSurvey.folder === study._id,
            )) && (
            <Grid item>
              <Tooltip arrow title={t("Delete")} placement="top">
                <span>
                  <IconButton
                    id="delete"
                    color="inherit"
                    onClick={(e) => {
                      setMenuIsOpen(true);
                      setMenuAnchor(e.currentTarget);
                    }}
                  >
                    <DeleteForever />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          )}
      </Grid>
      <Menu
        anchorEl={menuAnchor}
        open={menuIsOpen}
        onClose={() => {
          setMenuIsOpen(false);
          setMenuAnchor(null);
        }}
      >
        {menuAnchor && menuAnchor.id === "move" && (
          <div>
            <MenuItem
              selected={currentSurvey.folder === "standardized"}
              onClick={() => {
                currentSurvey.folder !== "standardized" &&
                  props.handleMoveSurvey("standardized");
                setMenuIsOpen(false);
              }}
            >
              <ListItemIcon>
                <Star fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t("Standardized surveys")}</ListItemText>
            </MenuItem>
            {props.studyList && props.studyList.length > 0 && (
              <ListSubheader>{t("Study folders")}</ListSubheader>
            )}
            {props.studyList &&
              props.studyList.map((study) => (
                <MenuItem
                  key={study._id}
                  selected={currentSurvey.folder === study._id}
                  onClick={() => {
                    currentSurvey.folder !== study._id &&
                      props.handleMoveSurvey(study._id);
                    setMenuIsOpen(false);
                  }}
                >
                  <ListItemIcon>
                    <School fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>{study.acronym}</ListItemText>
                </MenuItem>
              ))}
            <Divider />
            <MenuItem
              selected={currentSurvey.folder === "archive"}
              onClick={() => {
                currentSurvey.folder !== "archive" &&
                  props.handleMoveSurvey("archive");
                setMenuIsOpen(false);
              }}
            >
              <ListItemIcon>
                <Archive fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t("Archive")}</ListItemText>
            </MenuItem>
          </div>
        )}
        {menuAnchor && menuAnchor.id === "delete" && (
          <div>
            <MenuItem
              onClick={() => {
                props.handleDeleteSurvey();
                setMenuIsOpen(false);
              }}
            >
              <ListItemIcon>
                <DeleteForever fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t("Delete forever")}</ListItemText>
            </MenuItem>
          </div>
        )}
      </Menu>
    </>
  ) : null;
};
// =================================================
// EXPORT COMPONENT
export default SurveysHeader;
