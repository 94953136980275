// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useSelector } from "react-redux";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import PublicIcon from '@mui/icons-material/Public';
import PublicOffIcon from '@mui/icons-material/PublicOff';
// =================================================
// FUNCTIONAL COMPONENT
const CmsEditorEditInfo = (props) => {
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const isXS = useSelector((state) => state.ui.isXS);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Sets the local state
  const handleSetObj = (e) => {
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState[e.target.name] = e.target.value;
      return newState;
    });
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <Paper className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-625px"}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="overline" className="font-weight-bold">
            Webpage title
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            hiddenLabel
            size="small"
            type="text"
            name="title"
            value={props.obj.title ? props.obj.title : ""}
            color="secondary"
            variant="filled"
            className="bg-white answer-text-green mt-1 mb-3"
            onChange={handleSetObj}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="overline" className="font-weight-bold">
            URL (no spaces)
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            hiddenLabel
            size="small"
            type="text"
            name="url"
            value={props.obj.url ? props.obj.url : ""}
            color="secondary"
            variant="filled"
            className="bg-white answer-text-green mt-1 mb-3"
            onChange={handleSetObj}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="overline" className="font-weight-bold">
            (Google search) description
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            hiddenLabel
            size="small"
            type="text"
            name="description"
            value={props.obj.description ? props.obj.description : ""}
            color="secondary"
            variant="filled"
            className="bg-white answer-text-green mt-1 mb-3"
            onChange={handleSetObj}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl
            fullWidth
            component="fieldset"
            variant="filled"
            className="mb-3"
          >
            <InputLabel id="status-label" color="secondary">
              Status
            </InputLabel>
            <Select
              name="status"
              value={props.obj.status}
              variant="filled"
              label="Status"
              labelId="status-label"
              color="secondary"
              className="answer-text-green"
              onChange={handleSetObj}
            >
              <MenuItem value="concept">
              <PublicOffIcon fontSize="small" className="me-2" />
              Concept
              </MenuItem>
              <MenuItem value="published">
              <PublicIcon fontSize="small" className="me-2" />
              Published (listed)
              </MenuItem>
              <MenuItem value="unlisted">
              <PublicIcon fontSize="small" className="me-2" />
              Published (unlisted)
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth component="fieldset" variant="filled">
            <InputLabel id="type-label" color="secondary">
              Type
            </InputLabel>
            <Select
              name="type"
              value={props.obj.type}
              variant="filled"
              label="Type"
              labelId="type-label"
              color="secondary"
              className="answer-text-green"
              onChange={handleSetObj}
            >
              <MenuItem value="page">Page</MenuItem>
              <MenuItem value="home">Home</MenuItem>
              <MenuItem value="study">Study</MenuItem>
              <MenuItem value="news">News</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  );
};
// =================================================
// EXPORT COMPONENT
export default CmsEditorEditInfo;
