// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { v4 as uuid } from "uuid";
// -------------------------------------------------
// Contexts
import { useAuth } from "../contexts/auth";
import { useSocket } from "../contexts/socket";
// -------------------------------------------------
// Components
import AppRoot from "../components/App_Root";
import ScrollColumn from "../components/App_ScrollColumn";
import ContentPanel from "../components/App_ContentPanel";
import StudiesColumnContent from "../components/Studies_ColumnContent";
import StudiesHeader from "../components/Studies_Header";
import StudiesContent from "../components/Studies_Content";
// -------------------------------------------------
// Redux
import { togglePrimDrawer } from "../redux/reducers/ui";
import {
  fetchStudyList,
  patchCurrentStudy,
  deleteCurrentStudy,
  studiesSelectors,
} from "../redux/reducers/studies";
import {
  patchCurrentSupervision,
  deleteSupervisionByStudyId,
} from "../redux/reducers/supervision";
import { fetchSurveyList } from "../redux/reducers/surveys";
import {
  fetchConsumerList,
  consumersSelectors,
} from "../redux/reducers/consumers";
import { taskResponsesSelectors } from "../redux/reducers/taskResponses";
// -------------------------------------------------
// Helper functions
import { defaultStudies } from "../supportFunc/defaultStudies";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
// -------------------------------------------------
// Icons
import AddCircle from "@mui/icons-material/AddCircle";
// =================================================
// FUNCTIONAL COMPONENT
const Studies = () => {
  const { t } = useTranslation("pages", { keyPrefix: "Studies" });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Contexts
  const { currentAuth } = useAuth();
  const { socket } = useSocket();
  // -----------------------------------------------
  // Local state
  const [studyId, setStudyId] = useState(null);
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const primDrawerIsOpen = useSelector((state) => state.ui.primDrawerIsOpen);
  const currentUserId = useSelector(
    (state) => state.user.currentUser && state.user.currentUser._id
  );
  const currentRole = useSelector(
    (state) => state.user.currentUser && state.user.currentUser.primaryRole
  );
  const currentStudy = useSelector((state) =>
    studiesSelectors.selectById(state, studyId)
  );
  const studiesStatus = useSelector((state) => state.studies.status);
  const surveysStatus = useSelector((state) => state.surveys.status);
  const consumersStatus = useSelector((state) => state.consumers.status);
  const consumerList = useSelector((state) =>
    consumersSelectors.selectAll(state)
  );
  const enrolledConsumerList = consumerList
    .filter((consumer) => consumer.deletedOn === null)
    .filter((consumer) => consumer.primaryRole === "participant")
    .filter((consumer) =>
      currentStudy
        ? consumer.studyEnrollmentList.length > 0 &&
          consumer.studyEnrollmentList[0].studyId === currentStudy._id
        : false
    );
  const studyResponseList = useSelector((state) =>
    taskResponsesSelectors.selectAll(state)
  ).filter((response) =>
    currentStudy ? response.data.studyId === currentStudy._id : false
  );
  // -----------------------------------------------
  // Browser URL location
  const location = useLocation();
  const navigate = useNavigate();
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Upon render, get the studies list and set the redux state
  useEffect(() => {
    // Fetch all studies from DB if not done yet
    if (studiesStatus === "idle") {
      dispatch(fetchStudyList({ requestingUser: currentAuth }));
    }
  }); 

  useEffect(() => {
    // Fetch all surveys from DB if not done yet
    if (surveysStatus === "idle" || surveysStatus === "partial") {
      dispatch(fetchSurveyList({ requestingUser: currentAuth }));
    }
  }); 

  useEffect(() => {
    // Fetch all consumers from DB if not done yet
    if (consumersStatus === "idle") {
      dispatch(fetchConsumerList({ requestingUser: currentAuth }));
    } 
  }); 

  useEffect(() => {
    // Get the study-id from URL and get the current study
    if (studiesStatus === "succeeded") {
      const searchParams = new URLSearchParams(location.search);
      setStudyId(searchParams.get("studyId"));
      if (currentStudy && !primDrawerIsOpen) {
        dispatch(togglePrimDrawer({ isOpen: true }));
      } else if (primDrawerIsOpen) {
        dispatch(togglePrimDrawer({ isOpen: false }));
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    studiesStatus,
    currentStudy,
    location.search,
  ]);
  // -----------------------------------------------
  // Adds new study to the database and sets the current study to this new study
  const handleAddNewStudy = async () => {
    const newStudy = defaultStudies.study;
    newStudy._id = uuid();
    newStudy.userIdList = [currentUserId];
    newStudy.timepointList[0]._id = uuid();
    newStudy.groupList.forEach((group) => (group._id = uuid()));
    const newSupervision = {
      _id: uuid(),
      userId: currentUserId,
      studyId: newStudy._id,
      role: "principal-investigator",
      userIdList: [],
      isAllParticipants: true,
      isBlinded: false,
    };
    dispatch(
      patchCurrentSupervision({
        socket,
        requestingUser: currentAuth,
        body: { data: newSupervision },
      })
    );
    const res = await dispatch(
      patchCurrentStudy({
        socket,
        requestingUser: currentAuth,
        body: {
          meta: { isDefaultStudy: false },
          data: newStudy,
        },
      })
    );
    if (res.payload) {
      navigate(`/studies?studyId=${res.payload.study._id}`, { replace: true });
    }
  };
  // Deletes a study from the database
  const handleDeleteStudy = async () => {
    await dispatch(
      deleteCurrentStudy({
        socket,
        requestingUser: currentAuth,
        studyId: currentStudy._id,
      })
    );
    await dispatch(
      deleteSupervisionByStudyId({
        socket,
        requestingUser: currentAuth,
        studyId: currentStudy._id,
      })
    );
    navigate("/studies", { replace: true });
  };
  // ===============================================
  // SUB-COMPONENTS
  // -----------------------------------------------
  const ColumnHeader =
    currentRole && (currentRole === "superuser" || currentRole === "admin") ? (
      <Tooltip arrow title={t("Add new study")} placement="top">
        <IconButton color="inherit" onClick={(e) => handleAddNewStudy()}>
          <AddCircle />
        </IconButton>
      </Tooltip>
    ) : null;
  // ===============================================
  // RENDER COMPONENT
  return (
    <AppRoot header={ColumnHeader}>
      <Helmet>
        <title>
          {process.env.REACT_APP_TITLE} | {t("Studies")}
        </title>
      </Helmet>
      <Grid container className="w-100" wrap="nowrap">
        {/* Scroll column */}
        <ScrollColumn
          header={ColumnHeader}
          isLoading={studiesStatus === "loading"}
        >
          <StudiesColumnContent
            currentStudyId={currentStudy && currentStudy._id}
          />
        </ScrollColumn>
        {/* Content panel */}
        <ContentPanel
          title={currentStudy ? currentStudy.acronym : null}
          isLoading={studiesStatus === "loading"}
          buttons={
            <StudiesHeader
              currentStudyId={currentStudy && currentStudy._id}
              handleDeleteStudy={handleDeleteStudy}
              numberOfEnrolledConsumers={enrolledConsumerList.length}
              numberOfStudyResponses={studyResponseList.length}
            />
          }
        >
          <StudiesContent
            currentStudy={currentStudy}
            enrolledConsumerList={enrolledConsumerList}
            numberOfEnrolledConsumers={enrolledConsumerList.length}
            numberOfStudyResponses={studyResponseList.length}
          />
        </ContentPanel>
      </Grid>
    </AppRoot>
  );
};
// =================================================
// EXPORT COMPONENT
export default Studies;
