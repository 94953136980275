// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
// -------------------------------------------------
// Redux
import { surveysSelectors } from "../redux/reducers/surveys";
// -------------------------------------------------
// Components
import AppListItem from "../components/App_ListItem";
import SurveyListWithVersions from "./surveys/Surveys_ColumnListWithVersions"; // Import the new component
// -------------------------------------------------
// Basic elements
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
// -------------------------------------------------
// Icons
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Star from "@mui/icons-material/Star";
import Archive from "@mui/icons-material/Archive";
import School from "@mui/icons-material/School";
// =================================================
// FUNCTIONAL COMPONENT
const SurveysColumnContent = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "Surveys_ColumnContent",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Local State
  const [open, setOpen] = useState({
    standardized: false,
    archive: false,
    local: false,
  });
  // -----------------------------------------------
  // Redux
  const defaultStudyId = useSelector(
    (state) => state.studies.defaultStudy && state.studies.defaultStudy._id
  );
  const surveyList = useSelector((state) => surveysSelectors.selectAll(state));
  const currentUser = useSelector((state) => state.user.currentUser);
  // -----------------------------------------------
  // For navigating
  const navigate = useNavigate();
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Opens and closes the various subdirectories
  const handleClick = (key) => {
    setOpen((prevState) => {
      let newState = { ...prevState };
      newState[key] = newState[key] === undefined ? false : !newState[key];
      return newState;
    });
  };

  // Sets the URL search query to the survey identifier
  const handleSetCurrentSurvey = (id) => {
    let el;
    el = document.querySelector("#scroll-top-app");
    if (el) {
      el.scrollIntoView({ block: "end" });
    }
    navigate(`/surveys?surveyId=${id}`, { replace: true });
  };
  // -----------------------------------------------
  // Function to filter surveys by folder
  const getSurveysByFolder = (folder) => {
    return surveyList.filter((survey) => survey.folder === folder);
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <List disablePadding>
      {/* ----------------------------------------------- */}
      {/* STANDARDIZED SURVEYS */}
      <AppListItem
        divider
        slideIn
        subheader
        disabled={getSurveysByFolder("standardized").length === 0}
        icon={<Star fontSize="small" className="text-warning" />}
        iconEnd={open.standardized ? <ExpandLess /> : <ExpandMore />}
        textPrimary={
          <Typography noWrap variant="h5">
            {t("Standardized")}
          </Typography>
        }
        className="bg-light"
        onClick={() => handleClick("standardized")}
      />
      <Collapse in={open.standardized} timeout="auto" unmountOnExit>
        <SurveyListWithVersions
          surveys={getSurveysByFolder("standardized")}
          currentSurveyId={props.currentSurveyId}
          handleSetCurrentSurvey={handleSetCurrentSurvey}
          currentSurvey={props.currentSurvey}
        />
      </Collapse>
      {/* ----------------------------------------------- */}
      {/* MAP OVER ALL STUDIES */}
      {props.studyList
        .sort((a, b) => (a._id === defaultStudyId ? -1 : 1))
        .map((study) => (
          <React.Fragment key={study._id}>
            <AppListItem
              divider
              slideIn
              disabled={getSurveysByFolder(study._id).length === 0}
              icon={
                <School
                  fontSize="small"
                  className={
                    study._id === defaultStudyId ? "text-warning" : ""
                  }
                />
              }
              iconEnd={
                getSurveysByFolder(study._id).length === 0
                  ? null
                  : open[study._id] === undefined
                  ? <ExpandLess />
                  : open[study._id]
                  ? <ExpandLess />
                  : <ExpandMore />
              }
              textPrimary={
                <Typography noWrap variant="h5">
                  {study.acronym}
                </Typography>
              }
              className="bg-light"
              onClick={() => handleClick(study._id)}
            />
            <Collapse
              in={open[study._id] === undefined || open[study._id]}
              timeout="auto"
              unmountOnExit
            >
              <SurveyListWithVersions
                surveys={getSurveysByFolder(study._id)}
                currentSurveyId={props.currentSurveyId}
                handleSetCurrentSurvey={handleSetCurrentSurvey}
              />
            </Collapse>
          </React.Fragment>
        ))}
      {/* ----------------------------------------------- */}
      {/* ARCHIVE */}
      <AppListItem
        divider
        slideIn
        disabled={
          surveyList.filter(
            (survey) =>
              survey.folder === "archive" ||
              (currentUser &&
                currentUser.primaryRole === "superuser" &&
                survey.folder !== "standardized" &&
                !props.studyList.some((study) => survey.folder === study._id))
          ).length === 0
        }
        icon={<Archive fontSize="small" />}
        iconEnd={
          surveyList.filter(
            (survey) =>
              survey.folder === "archive" ||
              (currentUser &&
                currentUser.primaryRole === "superuser" &&
                survey.folder !== "standardized" &&
                !props.studyList.some((study) => survey.folder === study._id))
          ).length === 0
            ? null
            : open.archive
            ? <ExpandLess />
            : <ExpandMore />
        }
        textPrimary={
          <Typography noWrap variant="h5">
            {t("Archive")}
          </Typography>
        }
        className="bg-light"
        onClick={() => handleClick("archive")}
      />
      <Collapse in={open.archive} timeout="auto" unmountOnExit>
        <SurveyListWithVersions
          surveys={surveyList.filter(
            (survey) =>
              survey.folder === "archive" ||
              (currentUser &&
                currentUser.primaryRole === "superuser" &&
                survey.folder !== "standardized" &&
                !props.studyList.some((study) => survey.folder === study._id))
          )}
          currentSurveyId={props.currentSurveyId}
          handleSetCurrentSurvey={handleSetCurrentSurvey}
          currentSurvey={props.currentSurvey}
        />
      </Collapse>
    </List>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveysColumnContent;