// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import ReactMarkdown from "react-markdown";
// =================================================
// FUNCTIONAL COMPONENT
const MarkdownText = (props) => {
  // ===============================================
  // RENDER COMPONENT
  return (
    <ReactMarkdown skipHtml >
      {props.children}
    </ReactMarkdown>
  );
};
// =================================================
// EXPORT COMPONENT
export default MarkdownText;
