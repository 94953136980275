// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
// -------------------------------------------------
// Redux
import { studiesSelectors } from "../../redux/reducers/studies";
import { surveysSelectors } from "../../redux/reducers/surveys";
// -------------------------------------------------
// Component elements
import SurveyVersionMenuList from "../surveys/Surveys_SurveyVersionMenuList";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import InputAdornment from "@mui/material/InputAdornment";
import { TimePicker } from "antd";
import Icon from "@mui/material/Icon";
// -------------------------------------------------
// Helper functions
import { translateUnit } from "../../supportFunc/getTicketsForUserAndTimepoint";
// =================================================
// FUNCTIONAL COMPONENT
const VolunteersEditTicketSeries = (props) => {
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const isXS = useSelector((state) => state.ui.isXS);
  const currentStudy = useSelector((state) =>
    studiesSelectors.selectById(state, props.obj.studyId),
  );
  const studyList = useSelector((state) => studiesSelectors.selectAll(state));
  const surveyEntities = useSelector((state) =>
    surveysSelectors.selectEntities(state),
  );
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  const interpretRepeat = (r) => {
    if (r === -1) {
      return " indefinately";
    } else if (r === 1) {
      return " just once";
    } else {
      return ` for a total of ${r} times`;
    }
  };
  // -----------------------------------------------
  const interpretTimeUnit = (t, u) => {
    let unit;
    switch (u) {
      case "h":
        unit = "hour";
        break;
      case "d":
        unit = "day";
        break;
      case "w":
        unit = "week";
        break;
      case "m":
        unit = "month";
        break;
      default:
        return "";
    }
    if (t === -1) {
      return `indefinate`;
    } else if (t === 0) {
      return "no";
    } else if (t === 1) {
      return `1 ${unit}`;
    } else {
      return `${t} ${unit}s`;
    }
  };
  // -----------------------------------------------
  // Sets the local state
  const handleSetObj = (key, value) => {
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState[key] = value;
      return newState;
    });
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <>
      <Paper
        className={isXS ? "m-2 px-3 pt-3" : "m-2 px-3 pt-3 fix-width-625px"}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4">Ticket series information</Typography>
          </Grid>
          <Grid item xs={12}>
            <List>
              <ListItem divider>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm="auto">
                    <ListItemText
                      secondary="STUDY"
                      className="min-width-125px"
                    />
                  </Grid>
                  <Grid item xs={9} sm="auto">
                    <Typography paragraph={false}>
                      {currentStudy.name}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm="auto">
                    <ListItemText
                      secondary="TIMEPOINT"
                      className="min-width-125px"
                    />
                  </Grid>
                  <Grid item xs={9} sm="auto">
                    <Typography paragraph={false}>
                      {
                        currentStudy.timepointList.find(
                          (timepoint) =>
                            timepoint._id === props.obj.timepointId,
                        ).label
                      }
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm="auto">
                    <ListItemText
                      secondary="LEVEL"
                      className="min-width-125px"
                    />
                  </Grid>
                  <Grid item xs={9} sm="auto">
                    <TextField
                      hiddenLabel
                      type="number"
                      variant="filled"
                      size="small"
                      color="secondary"
                      value={props.obj.level}
                      onChange={(e) => handleSetObj("level", e.target.value)}
                      onWheel={(e) => e.target.blur()}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Paper>
      <Paper
        className={isXS ? "m-2 px-3 pt-3" : "m-2 px-3 pt-3 fix-width-625px"}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4" className="mb-2">
              Survey
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SurveyVersionMenuList
                    surveyEntities={surveyEntities}
                    studyList={studyList}
                    selectedSurveyId={props.obj.surveyId}
                    onSurveyIdChange={(surveyId) =>
                      handleSetObj("surveyId", surveyId)
                    }
                  />              
            <List>
              <ListItem divider>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm="auto">
                    <ListItemText
                      secondary="TO BE COMPLETED"
                      className="min-width-125px"
                    />
                  </Grid>
                  <Grid item xs={9} sm="auto">
                    <ToggleButtonGroup
                      value={props.obj.completer}
                      exclusive
                      size="small"
                      onChange={(e, newValue) =>
                        newValue && handleSetObj("completer", newValue)
                      }
                    >
                      <ToggleButton value="participant">
                        By Participant
                      </ToggleButton>
                      <ToggleButton value="investigator">
                        By Investigator
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
            <FormControlLabel
              control={
                <Switch
                  checked={props.obj.remainVisible}
                  color="secondary"
                  onChange={(e) =>
                    handleSetObj("remainVisible", e.target.checked)
                  }
                />
              }
              label={
                props.obj.remainVisible ? (
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{ fontSize: "0.8125rem" }}
                  >
                    Remains in task list upon completion
                  </Typography>
                ) : (
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{ fontSize: "0.8125rem" }}
                  >
                    Disapears from task list upon completion
                  </Typography>
                )
              }
              className="ms-2 mb-2"
            />
            {props.obj.remainVisible && (
              <>
                <br />
                <FormControlLabel
                  control={
                    <Switch
                      checked={props.obj.remainEditable}
                      color="secondary"
                      onChange={(e) =>
                        handleSetObj("remainEditable", e.target.checked)
                      }
                    />
                  }
                  label={
                    props.obj.remainEditable ? (
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        sx={{ fontSize: "0.8125rem" }}
                      >
                        Remains editable upon completion
                      </Typography>
                    ) : (
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        sx={{ fontSize: "0.8125rem" }}
                      >
                        Is no longer editable upon completion
                      </Typography>
                    )
                  }
                  className="ms-2 mb-2"
                />
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
      <Paper
        className={
          isXS ? "m-2 mb-3 px-3 pt-3" : "m-2 mb-3 px-3 pt-3 fix-width-625px"
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4">Scheduling</Typography>
          </Grid>
          <Grid item xs={12} className="mb-2">
            <Typography variant="caption">
              {`Occurs every ${interpretTimeUnit(
                props.obj.interval,
                props.obj.intervalUnit,
              )} effective ${dayjs(props.obj.timepointStartDate, "YYYY-MM-DD")
                .add(Math.floor(props.obj.delay) - 1, "day")
                .format("dddd D MMMM YYYY")} at ${dayjs(
                props.obj.timepointStartDate,
                "YYYY-MM-DD",
              )
                .add((props.obj.delay % 1) * 24 * 60, "minute")
                .format("H:mm")} ${
                props.obj.repeat > 1
                  ? dayjs(props.obj.timepointStartDate, "YYYY-MM-DD")
                      .add((props.obj.delay - 1) * 24 * 60, "minute")
                      .add(
                        (props.obj.repeat - 1) * props.obj.interval,
                        translateUnit(props.obj.intervalUnit),
                      )
                      .format("until dddd D MMMM YYYY H:mm")
                  : ""
              }${interpretRepeat(props.obj.repeat)}, with ${interpretTimeUnit(
                props.obj.availability,
                props.obj.availabilityUnit,
              )} availability and ${interpretTimeUnit(
                props.obj.allowance,
                props.obj.allowanceUnit,
              )}
              allowance.`}
            </Typography>
          </Grid>
          <Grid item xs={3} className="mb-2 pe-2">
            <TextField
              fullWidth
              label="Start day"
              type="number"
              name="startDay"
              variant="filled"
              size="small"
              value={Math.floor(props.obj.delay)}
              color="secondary"
              InputProps={{
                inputProps: { min: 1 },
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon className="me-2 align-middle" fontSize="small">
                      schedule_send
                    </Icon>
                  </InputAdornment>
                ),
              }}
              className="answer-text-green"
              onChange={(e) => {
                let newValue =
                  e.target.value === ""
                    ? 1
                    : Math.floor(parseInt(e.target.value));
                newValue = newValue < 1 ? 1 : newValue;
                handleSetObj("delay", newValue + (props.obj.delay % 1));
              }}
              onWheel={(e) => e.target.blur()}
            />
          </Grid>
          <Grid item xs={3}>
            <TimePicker
              allowClear={false}
              inputReadOnly
              className="timepicker2mui"
              value={dayjs("2020-01-01T00:00:00").add(
                (props.obj.delay % 1) * 24,
                "hour",
              )}
              placeholder="Click to select a time"
              size="small"
              format="HH:mm"
              minuteStep={5}
              use12Hours={false}
              showNow={false}
              style={{ padding: "13px 12px 12px 12px" }}
              onSelect={(newValue) => {
                const v =
                  Math.floor(props.obj.delay) +
                  newValue.diff(dayjs("2020-01-01T00:00:00")) /
                    (24 * 60 * 60 * 1000);
                handleSetObj("delay", v);
              }}
            />
          </Grid>
          <Grid item xs={3} className="mb-2 pe-2">
            {props.obj.repeat > 1 && (
              <TextField
                fullWidth
                disabled={props.obj.repeat === -1}
                label="Number of repeats"
                type="number"
                name="repeat"
                variant="filled"
                size="small"
                value={props.obj.repeat ? props.obj.repeat : ""}
                color="secondary"
                InputProps={{
                  inputProps: { min: 1 },
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon className="me-2 align-middle" fontSize="small">
                        repeat
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                className="answer-text-green"
                onChange={(e) =>
                  e.target.value !== "" && parseInt(e.target.value) > 0
                    ? handleSetObj("repeat", parseInt(e.target.value))
                    : handleSetObj("repeat", 1)
                }
                onWheel={(e) => e.target.blur()}
              />
            )}
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={3} className="mb-2 pe-2">
            {props.obj.repeat !== 1 && (
              <TextField
                fullWidth
                label="Repeat interval"
                type="number"
                name="interval"
                variant="filled"
                size="small"
                value={props.obj.interval ? props.obj.interval : ""}
                color="secondary"
                InputProps={{
                  inputProps: { min: 1 },
                }}
                className="answer-text-green"
                onChange={(e) =>
                  e.target.value !== "" && parseInt(e.target.value) > 0
                    ? handleSetObj("interval", parseInt(e.target.value))
                    : handleSetObj("interval", 1)
                }
                onWheel={(e) => e.target.blur()}
              />
            )}
          </Grid>
          <Grid item xs={3} className="mb-2 pe-2">
            {props.obj.repeat !== 1 && (
              <ToggleButtonGroup
                value={props.obj.intervalUnit}
                exclusive
                size="small"
                onChange={(e, newValue) =>
                  newValue && handleSetObj("intervalUnit", newValue)
                }
              >
                <ToggleButton value="h">h</ToggleButton>
                <ToggleButton value="d">d</ToggleButton>
                <ToggleButton value="w">w</ToggleButton>
                <ToggleButton value="m">m</ToggleButton>
              </ToggleButtonGroup>
            )}
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={3} className="mb-2 pe-2">
            {props.obj.availability !== -1 && (
              <TextField
                fullWidth
                label="availability"
                type="number"
                name="availability"
                variant="filled"
                size="small"
                value={props.obj.availability}
                color="secondary"
                InputProps={{
                  inputProps: { min: 1 },
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon className="me-2 align-middle" fontSize="small">
                        lock_clock
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                className="answer-text-green"
                onChange={(e) =>
                  parseInt(e.target.value) > 0 &&
                  handleSetObj("availability", parseInt(e.target.value))
                }
                onWheel={(e) => e.target.blur()}
              />
            )}
          </Grid>
          <Grid item xs={3} className="mb-2 pe-2">
            {props.obj.availability !== -1 && (
              <ToggleButtonGroup
                value={props.obj.availabilityUnit}
                exclusive
                size="small"
                onChange={(e, newValue) =>
                  newValue && handleSetObj("availabilityUnit", newValue)
                }
              >
                <ToggleButton value="h">h</ToggleButton>
                <ToggleButton value="d">d</ToggleButton>
                <ToggleButton value="w">w</ToggleButton>
                <ToggleButton value="m">m</ToggleButton>
              </ToggleButtonGroup>
            )}
          </Grid>
          {props.obj.availability !== -1 && (
            <>
              <Grid item xs={6} className="mb-2 pe-2">
                <FormControlLabel
                  control={
                    <Switch
                      checked={props.obj.allowance !== 0}
                      color="secondary"
                      onChange={(e) =>
                        handleSetObj("allowance", e.target.checked ? 1 : 0)
                      }
                    />
                  }
                  label={
                    props.obj.allowance !== 0 ? (
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        sx={{ fontSize: "0.8125rem" }}
                      >
                        Can be overdue by
                      </Typography>
                    ) : (
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        sx={{ fontSize: "0.8125rem" }}
                      >
                        Cannot be overdue
                      </Typography>
                    )
                  }
                />
              </Grid>
              <Grid item xs={3} className="mb-2 pe-2">
                {props.obj.allowance !== 0 && (
                  <TextField
                    fullWidth
                    label="allowance"
                    type="number"
                    name="allowance"
                    variant="filled"
                    size="small"
                    value={props.obj.allowance}
                    color="secondary"
                    InputProps={{
                      inputProps: { min: 1 },
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon className="me-2 align-middle" fontSize="small">
                            lock_clock
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                    className="answer-text-green"
                    onChange={(e) =>
                      parseInt(e.target.value) > 0 &&
                      handleSetObj("allowance", parseInt(e.target.value))
                    }
                    onWheel={(e) => e.target.blur()}
                  />
                )}
              </Grid>
              <Grid item xs={3} className="mb-2 pe-2">
                {props.obj.allowance !== 0 && (
                  <ToggleButtonGroup
                    value={props.obj.allowanceUnit}
                    exclusive
                    size="small"
                    onChange={(e, newValue) =>
                      newValue && handleSetObj("allowanceUnit", newValue)
                    }
                  >
                    <ToggleButton value="h">h</ToggleButton>
                    <ToggleButton value="d">d</ToggleButton>
                    <ToggleButton value="w">w</ToggleButton>
                    <ToggleButton value="m">m</ToggleButton>
                  </ToggleButtonGroup>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default VolunteersEditTicketSeries;
