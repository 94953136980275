// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, NavLink } from "react-router-dom";
import { DateTime } from "luxon";
// -------------------------------------------------
// Redux
import { studiesSelectors } from "../../redux/reducers/studies";
import { surveysSelectors } from "../../redux/reducers/surveys";
import { toggleCloak } from "../../redux/reducers/user";
// -------------------------------------------------
// Basic elements
import Chip from "@mui/material/Chip";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// -----------------------------------------------
// Icons
import SvgIcon from "@mui/material/SvgIcon";
import EditIcon from "@mui/icons-material/Edit";
import PendingIcon from "@mui/icons-material/Pending";
import PlayDisabledIcon from "@mui/icons-material/PlayDisabled";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BlockIcon from "@mui/icons-material/Block";
import Icon from "@mui/material/Icon";
import { mdiGhost } from "@mdi/js";
// -------------------------------------------------
// Support functions
import { str2color } from "../../supportFunc/str2color";
// =================================================
// FUNCTIONAL COMPONENT
// -----------------------------------------------
const VolunteersContentMeasurementItem = ({
  ticket,
  isExpired,
  isOverdue,
  showAvatar,
  disableButtons,
  handleEditTicket,
  currentConsumer,
}) => {
  // ===============================================
  // VARIABLES
  // -------------------------------------------------
  // Router
  const navigate = useNavigate();
  // -----------------------------------------------
  // Local
  const [anchorElEdit, setAnchorElEdit] = useState(null);
  const isOpenEditMenu = Boolean(anchorElEdit);
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const isMD = useSelector((state) => state.ui.isMD);
  const surveyEntities = useSelector((state) =>
    surveysSelectors.selectEntities(state)
  );
  const currentStudyId = useSelector((state) => state.consumers.currentStudyId);
  const currentStudy = useSelector(
    (state) =>
      currentStudyId && studiesSelectors.selectById(state, currentStudyId)
  );
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  const handleToggleEditMenu = (el) => {
    setAnchorElEdit(el);
  };
  // -----------------------------------------------
  const getDateAvailableStr = (d) => {
    if (d.split("T")[1] === "00:00") {
      return DateTime.fromISO(d).toFormat("d LLL");
    }
    return DateTime.fromISO(d).toFormat("d LLL H:mm");
  };
  const getDateDueStr = (d) => {
    let format = "d LLL H:mm";
    if (d.split("T")[1] === "00:00") {
      return DateTime.fromISO(d).minus({ seconds: 1 }).toFormat(format);
    }
    return DateTime.fromISO(d).toFormat(format);
  };
  const getDateStr = (d) => {
    let format = "d LLL H:mm";
    return DateTime.fromISO(d).toFormat(format);
  };
  // -------------------------------------------------
  // Enable cloaking
  const handleCloak = () => {
    dispatch(toggleCloak({ cloak: true, currentUser: currentConsumer }));
  };
  // -----------------------------------------------
  const stringAvatar = (id, name) => {
    const abbrev = name.trim().split(" ");
    return {
      sx: {
        bgcolor: str2color(id),
      },
      children:
        abbrev.length === 1
          ? `${name[0].toUpperCase()}`
          : `${name.split(" ")[0][0].toUpperCase()}${name
              .split(" ")[1][0]
              .toUpperCase()}`,
    };
  };
  // ===============================================
  // RENDER COMPONENT
  // -----------------------------------------------
  return (
    <>
      {/* ================================================== */}
      {/* MENU TO EDIT A SINGLE TICKET OR THE ENTIRE SERIES  */}
      <Menu
        id="edit-ticket-menu"
        anchorEl={anchorElEdit}
        open={isOpenEditMenu}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        onClose={() => {
          handleToggleEditMenu(null);
        }}
      >
        <MenuItem
          onClick={(e) => {
            handleToggleEditMenu(null);
            handleEditTicket(ticket, "occurence");
          }}
        >
          Edit occurence
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleToggleEditMenu(null);
            handleEditTicket(ticket, "series");
          }}
        >
          Edit series
        </MenuItem>
      </Menu>
      <Grid container wrap="nowrap" className="w-100 my-1">
        <Grid
          item
          className="px-2 flex-grow-0  overflow-hidden"
          sx={{ minWidth: "75px", maxWidth: "75px" }}
        >
          <Grid
            wrap="nowrap"
            container
            direction="column"
            alignItems="flex-end"
          >
            <Grid item>
              <Typography
                noWrap
                variant="caption"
                color={
                  ticket.isCompleted
                    ? "GreyText"
                    : isExpired ||
                      DateTime.fromISO(ticket.dateExpire) < DateTime.now()
                    ? "red"
                    : isOverdue
                    ? "orangered"
                    : "GrayText"
                }
                className="text-truncate"
              >
                {ticket.isCompleted
                  ? "completed"
                  : isExpired ||
                    DateTime.fromISO(ticket.dateExpire) < DateTime.now()
                  ? "expired on"
                  : isOverdue
                  ? "expires on"
                  : getDateAvailableStr(ticket.dateAvailable)}
              </Typography>
            </Grid>
            {ticket.isCompleted ? (
              <Grid item>
                <Typography
                  noWrap
                  variant="caption"
                  color="CaptionText"
                  className="text-truncate"
                >
                  {getDateStr(ticket.updatedAt)}
                </Typography>
              </Grid>
            ) : isOverdue ||
              isExpired ||
              DateTime.fromISO(ticket.dateExpire) < DateTime.now() ? (
              <Grid item>
                <Typography
                  noWrap
                  variant="caption"
                  color={
                    isExpired ||
                    DateTime.fromISO(ticket.dateExpire) < DateTime.now()
                      ? "red"
                      : "orangered"
                  }
                  className="text-truncate"
                >
                  {getDateStr(ticket.dateExpire)}
                </Typography>
              </Grid>
            ) : (
              <Grid item>
                <Typography
                  noWrap
                  variant="caption"
                  color="CaptionText"
                  className="text-truncate"
                >
                  {ticket.dateDue ? getDateDueStr(ticket.dateDue) : "∞"}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          className="rounded-pill"
          sx={{
            width: "4px",
            minWidth: "4px",
            backgroundColor: str2color(ticket.measurementId),
          }}
        />
        <Grid item className="flex-grow-1 overflow-hidden ps-2">
          <Typography
            noWrap
            variant="overline"
            color="GrayText"
            className="text-truncate"
            sx={{ lineHeight: "1rem" }}
          >
            {currentStudy.timepointList.find(
              (tp) => tp._id === ticket.timepointId
            )
              ? `TP${
                  currentStudy.timepointList.findIndex(
                    (tp) => tp._id === ticket.timepointId
                  ) + 1
                } ${
                  currentStudy.timepointList.find(
                    (tp) => tp._id === ticket.timepointId
                  ).label
                }`
              : "no timepoint label"}
          </Typography>
          <Typography
            noWrap
            variant="subtitle1"
            color="textPrimary"
            className="text-truncate"
            sx={{ lineHeight: "1.5rem" }}
          >
            {surveyEntities[ticket.surveyId]
              ? surveyEntities[ticket.surveyId].name.official
              : "Survey does not exist"}
          </Typography>
        </Grid>
        <Grid item className="flex-grow-0" textAlign="end">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              color: "ActiveBorder",
            }}
          >
            <Tooltip arrow placement="top" title="entry number">
              <Typography variant="caption" className="me-1">
                {ticket.entryNumber}/
                {ticket.repeat === -1 ? "∞" : ticket.repeat}
              </Typography>
            </Tooltip>
            <Tooltip arrow placement="top" title="level">
              <Icon fontSize="small">low_priority</Icon>
            </Tooltip>
            <Typography
              variant="caption"
              className="me-1"
              sx={{ top: "-10px" }}
            >
              {ticket.level}
            </Typography>
            {ticket.isCompleted ? (
              <Tooltip arrow placement="top" title="completed">
                <Icon fontSize="small" color="success" className="me-1">
                  check_circle
                </Icon>
              </Tooltip>
            ) : DateTime.fromISO(ticket.dateExpire) < DateTime.now() ? (
              <Tooltip arrow placement="top" title="expired">
                <Icon fontSize="small" color="error" className="me-1">
                  block
                </Icon>
              </Tooltip>
            ) : DateTime.fromISO(ticket.dateAvailable) > DateTime.now() ? (
              <Tooltip arrow placement="top" title="not yet available">
                <Icon fontSize="small" color="action" className="me-1">
                  pending
                </Icon>
              </Tooltip>
            ) : !ticket.hasStarted && !ticket.isCompleted ? (
              <Tooltip arrow placement="top" title="not yet started">
                <Icon fontSize="small" color="primary" className="me-1">
                  play_disabled
                </Icon>
              </Tooltip>
            ) : ticket.hasStarted && !ticket.isCompleted ? (
              <Tooltip arrow placement="top" title="started">
                <Icon fontSize="small" color="info" className="me-1">
                  play_arrow
                </Icon>
              </Tooltip>
            ) : (
              ""
            )}
            {ticket.completer === "participant" ? (
              <Tooltip arrow placement="top" title="participant task">
                <Icon fontSize="small" color="action" className="me-1">
                  account_circle
                </Icon>
              </Tooltip>
            ) : (
              <Tooltip arrow placement="top" title="investigator task">
                <Icon fontSize="small" color="action" className="me-1">
                  supervised_user_circle
                </Icon>
              </Tooltip>
            )}
            {ticket.remainVisible ? (
              <Tooltip
                arrow
                placement="top"
                title="Remains in task list upon completion"
              >
                <Icon fontSize="small" color="action" className="me-1">
                  visibility
                </Icon>
              </Tooltip>
            ) : (
              <Tooltip arrow placement="top" title="Disapears upon completion">
                <Icon fontSize="small" color="action" className="me-1">
                  disabled_visible
                </Icon>
              </Tooltip>
            )}
            {ticket.remainEditable ? (
              <Tooltip
                arrow
                placement="top"
                title="Remains editable after completion"
              >
                <Icon fontSize="small" color="action" className="me-1">
                  published_with_changes
                </Icon>
              </Tooltip>
            ) : (
              <Tooltip
                arrow
                placement="top"
                title="Not editable after completion"
              >
                <Icon fontSize="small" color="action" className="me-1">
                  unpublished
                </Icon>
              </Tooltip>
            )}
          </div>
          {showAvatar && (
            <Tooltip
              arrow
              placement="left"
              title={currentConsumer.studyEnrollmentList[0].userStudyNumber}
            >
              <Chip
                size="small"
                label={
                  currentConsumer && currentConsumer.name
                    ? currentConsumer.name
                    : currentConsumer.email
                }
                variant="filled"
                className="me-1"
                onClick={() =>
                  !disableButtons &&
                  navigate(
                    `/volunteers?studyId=${ticket.studyId}&consumerId=${currentConsumer._id}`,
                    {
                      replace: false,
                    }
                  )
                }
              />
            </Tooltip>
          )}
          {handleEditTicket && (
            <Tooltip arrow title="Edit ticket" placement="top">
              <IconButton
                size="small"
                onClick={(e) => {
                  if (ticket.repeat === -1) {
                    handleToggleEditMenu(null);
                    handleEditTicket(ticket, "occurence");
                  } else if (ticket.isSeries) {
                    handleToggleEditMenu(e.currentTarget);
                  } else {
                    handleToggleEditMenu(null);
                    handleEditTicket(ticket, "occurence");
                  }
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {!disableButtons && (
            <Tooltip arrow title="Cloak and complete task" placement="top">
              <IconButton
                component={NavLink}
                size="small"
                to={`/tasks?ticketId=${ticket._id}`}
                onClick={handleCloak}
              >
                <SvgIcon>
                  <path d={mdiGhost} />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default VolunteersContentMeasurementItem;
