// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useSelector } from "react-redux";
// -------------------------------------------------
// Basic elements
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// -------------------------------------------------
// Redux
import { taskResponsesSelectors } from "../../redux/reducers/taskResponses";
// -------------------------------------------------
// Support function
import { calculateTaskProgress } from "../../supportFunc/calculateTaskProgress";
// -------------------------------------------------
// Icons
import CheckIcon from "@mui/icons-material/Check";
// =================================================
// FUNCTIONAL COMPONENT
const CircularProgressWithLabel = (props) => {
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const taskResponseEntities = useSelector((state) =>
    taskResponsesSelectors.selectEntities(state),
  );
  const prog = Math.ceil(
    calculateTaskProgress(taskResponseEntities[props.task.responseId]),
  );
  // ===============================================
  // RENDER COMPONENT
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        marginRight: "-2px",
        marginLeft: "-2px",
      }}
    >
      <CircularProgress
        variant="determinate"
        size={24}
        thickness={5}
        value={prog < 100 ? prog : 0}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: "text.secondary", fontSize: "8px" }}
        >
          {prog > 99 ? (
            <CheckIcon fontSize="small" color="primary" />
          ) : (
            `${prog}%`
          )}
        </Typography>
      </Box>
    </Box>
  );
};
// =================================================
// EXPORT COMPONENT
export default CircularProgressWithLabel;
