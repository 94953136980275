// ########################################
// FUNCTION
// ----------------------------------------
// Extract the subset of tickets in X that exist in Y
// and also return the set of tickets of X that do not appear in Y
export const intersectTickets = (X, Y) => {
  const XinY = X.filter((x) => {
    const xIsIndefinatelyRepeating = x.repeat === -1;
    return (
      x &&
      Y.some((y) => {
        const yIsIndefinatelyRepeating = y.repeat === -1;
        const bothIndefinatelyRepeating =
          xIsIndefinatelyRepeating && yIsIndefinatelyRepeating;
        const exclusiveOneIndefinatelyRepeating =
          xIsIndefinatelyRepeating !== yIsIndefinatelyRepeating;
        return (
          y &&
          y.userId &&
          y.userId === x.userId &&
          y.studyId === x.studyId &&
          y.measurementId === x.measurementId &&
          ((y.entryNumber === x.entryNumber &&
            !exclusiveOneIndefinatelyRepeating) ||
            bothIndefinatelyRepeating)
        );
      })
    );
  });
  const XnotinY = X.filter((x) => !XinY.some((xy) => xy._id === x._id));
  return { XinY, XnotinY };
};
