// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { DateTime } from "luxon";
// -------------------------------------------------
// Support functions
import { validateSurveyItem } from "../../supportFunc/validateSurveyItem";
// -------------------------------------------------
// Component elements
import ArticleFormControl from "./ArticleFormControl";
// -------------------------------------------------
// Redux actions
import {
  initValidationByAlias,
  appendValidation,
  setFormSurveyLogicByAlias,
  selectSingleResponseByAlias,
} from "../../redux/reducers/form";
import { setSingleTaskResponseByAlias } from "../../redux/reducers/taskResponses";
import { setSinglePreviewResponseByAlias } from "../../redux/reducers/previewResponses";
import { updateTicketByIdWithKeyValue } from "../../redux/reducers/tickets";
// -------------------------------------------------
// Basic elements
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
// =================================================
// FUNCTIONAL COMPONENT
const DateSelectorItem = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "form.DateSelectorItem",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Local state
  const [localValidation, setLocalValidation] = useState(null);
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const value = useSelector((state) =>
    selectSingleResponseByAlias(state, props.alias)
  );
  const formTicketId = useSelector((state) => state.form.ticketId);
  const responseCollection = useSelector(
    (state) =>
      state.tickets.currentTicket &&
      state.tickets.currentTicket.responseCollection
  );
  const responseId = useSelector(
    (state) =>
      state.tickets.currentTicket && state.tickets.currentTicket.responseId
  );
  const isEnabled = useSelector(
    (state) => state.form.isEnabled[props.article._id]
  );
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Initialize upon render
  useEffect(() => {
    // If this items is forced to be shown, there is no need to initialize and perform validation
    if (props.doShow) {
      return;
    }
  
    // Validate answer(s)
    const validation = validateSurveyItem(
      props.required,
      props.article.validation,
      props.alias,
      value
    );
    if (validation) {
      setLocalValidation(validation);
      dispatch(appendValidation({ validation }));
    }
    // Clear any validation when component unmounts
    return () => {
      dispatch(initValidationByAlias({ alias: props.alias }));
    };
  }, [isEnabled]); // eslint-disable-line react-hooks/exhaustive-deps
  // -------------------------------------------------
  // Updates the state when an answer is given
  const handleSetSingleResponse = async (alias, value) => {
    // Value is a DateTime object, convert this to string for in the db
    if (value !== null) {
      value = value.toISODate();
    }
    switch (responseCollection) {
      case "taskResponses":
        await dispatch(
          setSingleTaskResponseByAlias({ responseId, alias, value })
        );
        break;
      case "previewResponses":
        await dispatch(
          setSinglePreviewResponseByAlias({ responseId, alias, value })
        );
        break;
      default:
        return;
    }
    // Evaluate survey logic after this response
    dispatch(setFormSurveyLogicByAlias({ alias }));
    // Set ticket 'hasStarted'
    dispatch(
      updateTicketByIdWithKeyValue({
        ticketId: formTicketId,
        key: "hasStarted",
        value: true,
      })
    );
    // Validate answer
    const validation = validateSurveyItem(
      props.required,
      props.article.validation,
      props.alias,
      value
    );
    setLocalValidation(validation);
    dispatch(appendValidation({ validation }));
  };
  // ===============================================
  // RENDER COMPONENT
  return (
      <ArticleFormControl
      article={props.article}
      required={props.required}
      isInvalid={localValidation && localValidation.isInvalid}
      validationMsg={localValidation && localValidation.msg[props.alias]}
    >
      <MobileDatePicker
       disabled={props.uneditable}
       label={!value ? t("Click to select a date") : t("Selected date")}
       value={
         value && DateTime.fromISO(value).isValid
           ? DateTime.fromISO(value)
           : null
       }
       format={props.article.options.dateTimeFormat}
       minDate={
         props.article.options.disablePast
           ? DateTime.now()
           : DateTime.fromISO(props.article.options.min)
       }
       maxDate={
         props.article.options.disableFuture
           ? DateTime.now()
           : DateTime.fromISO(props.article.options.max)
       }
       slotProps={{
         toolbar: false,
         textField: {
           name: props.alias,
           variant: "filled",
           color: "secondary",
           helperText: props.article.options.dateTimeFormat.toLowerCase(),
           className: "bg-white",
         },
         actionBar: {
           actions: ['clear', 'today', 'cancel', 'accept'],
         },
       }}
       onChange={(newValue) => handleSetSingleResponse(props.alias, newValue)}
     /> 
   </ArticleFormControl>
  );
};
// =================================================
// EXPORT COMPONENT
export default DateSelectorItem;
