// ########################################
// IMPORT
// ========================================
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { DateTime } from "luxon";
// ========================================
// Redux
import { toggleNav } from "../redux/reducers/ui";
import { ticketsSelectors } from "../redux/reducers/tickets";
import { conversationsSelectors } from "../redux/reducers/conversations";
import { publicWebpageSelectors } from "../redux/reducers/publicWebpages";
// ========================================
// Context
import { useAuth } from "../contexts/auth";
// ========================================
// Components
import NavigationItem from "./navigation/NavigationItem";
// ========================================
// Material UI
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import { TransitionGroup } from "react-transition-group";
import Collapse from "@mui/material/Collapse";
// ----------------------------------------
// Icons
import Close from "@mui/icons-material/Close";
// ########################################
// FUNCTIONAL COMPONENT
const Navigation = (props) => {
  const { t } = useTranslation("components", { keyPrefix: "App_Navigation" });
  // ========================================
  // VARIABLES
  // ----------------------------------------
  // Redux
  const dispatch = useDispatch();
  const isXS = useSelector((state) => state.ui.isXS);
  const isSM = useSelector((state) => state.ui.isSM);
  const navIsOpen = useSelector((state) => state.ui.navIsOpen);
  const webpageList = useSelector((state) =>
    publicWebpageSelectors.selectAll(state),
  );
  const currentUser = useSelector((state) => state.user.currentUser);
  const ghostUser = useSelector((state) => state.user.ghostUser);
  const ticketList = useSelector((state) => ticketsSelectors.selectAll(state));
  const conversationList = useSelector((state) =>
    conversationsSelectors.selectAll(state),
  );
  const recipientList = useSelector((state) => state.messages.recipientList);
  // -----------------------------------------------
  // Context
  const { currentAuth, verifiedAuth } = useAuth();
  // ----------------------------------------
  // Local variables
  const drawerVariant = props.drawerVariant
    ? props.drawerVariant
    : !isXS && !isSM
      ? "permanent"
      : "temporary";
  const drawerOpen = drawerVariant === "permanent" ? true : navIsOpen;
  const drawerSx =
    drawerVariant === "permanent"
      ? {
          width: 191,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 191,
            border: "none",
            background: "none",
          },
        }
      : { zIndex: 1301 };
  // ----------------------------------------
  // Navigation items
  const navItemList = [
    ...webpageList
      .filter((page) => page.type === "home")
      .map((page) => {
        return {
          primary: page.title,
          icon: "home",
          to: `/${page.url}`,
          security: ["anonymous"],
          skip: !isXS && !(isSM && currentAuth),
        };
      }),
    ...webpageList
      .filter((page) => page.type === "page" && page.status === "published")
      .map((page) => {
        return {
          primary: page.title,
          icon: "web",
          to: `/${page.url}`,
          security: ["anonymous"],
          skip: !isXS && !(isSM && currentAuth),
        };
      }),
    ...webpageList
      .filter((page) => page.type === "study" && page.status === "published")
      .map((page) => {
        return {
          primary: page.title,
          icon: "school",
          to: `/${page.url}`,
          security: ["anonymous"],
          skip: !isXS && !(isSM && currentAuth),
        };
      }),
    {
      primary: t("Studies"),
      icon: "school",
      to: "/studies",
      security: ["admin", "superuser"],
      divider: isXS || (isSM && currentAuth),
    },
    {
      primary: t("Surveys"),
      icon: "library_books",
      to: "/surveys",
      security: ["admin", "superuser"],
    },
    {
      primary: t("Results"),
      icon: "poll",
      to: "/results",
      security: ["admin", "superuser"],
    },
    {
      primary: t("Volunteers"),
      icon: "groups",
      to: "/volunteers",
      security: ["admin", "superuser"],
    },
    {
      primary: t("Investigators"),
      icon: "admin_panel_settings",
      to: "/investigators",
      security: ["admin", "superuser"],
    },
    {
      primary: "CMS Editor",
      icon: "edit_note",
      to: "/cmseditor",
      security: ["superuser"],
    },
    {
      primary: t("Tasks"),
      icon: "assignment",
      to: "/tasks",
      security: ["participant"],
      divider: isXS || (isSM && currentAuth),
      badge: ticketList
        .filter((ticket) =>
          !ghostUser && ticket.completer !== "participant" ? false : true,
        )
        .filter((ticket) => currentUser && ticket.userId === currentUser._id)
        .filter((ticket) => !ticket.isCompleted)
        .filter(
          (ticket) =>
            DateTime.fromISO(ticket.dateAvailable).diffNow().valueOf() < 0 &&
            DateTime.fromISO(
              ticket.dateExpire ? ticket.dateExpire : "2199-12-31",
            )
              .diffNow()
              .valueOf() > 0,
        ).length,
    },
    conversationList.length > 0 ||
    (currentUser &&
      currentUser.primaryRole &&
      currentUser.primaryRole !== "participant")
      ? {
          primary: t("Messages"),
          icon: "question_answer",
          to: "/messages",
          security: ["verified", "admin", "superuser"],
          badge:
            currentUser &&
            recipientList &&
            recipientList.filter(
              (r) => r.userId === currentUser._id && !r.isRead,
            ).length,
        }
      : { skip: true },
    {
      primary: t("Account"),
      icon: "account_box",
      to: "/account",
      security: ["verified", "admin", "superuser"],
      divider: true,
    },
    {
      primary: t("Sign out"),
      icon: "logout",
      to: "/sign-out",
      security: ["verified", "admin", "superuser"],
    },
  ];
  const filteredNavItemList = navItemList
    .filter((navItem) => !navItem.skip)
    .filter(
      (navItem) =>
        navItem.security.includes("anonymous") ||
        (navItem.security.includes("verified") && verifiedAuth) ||
        (navItem.security.includes("participant") &&
          verifiedAuth &&
          currentUser &&
          currentUser.primaryRole === "participant") ||
        (navItem.security.includes("admin") &&
          verifiedAuth &&
          currentUser &&
          currentUser.primaryRole === "admin") ||
        (navItem.security.includes("superuser") &&
          verifiedAuth &&
          currentUser &&
          currentUser.primaryRole === "superuser"),
    );
  // ========================================
  // FUNCTIONS
  // ----------------------------------------
  // Toggles the navigation
  const handleCloseNav = () => {
    navIsOpen && dispatch(toggleNav({ isOpen: false }));
  };
  // ========================================
  // RETURN
  return filteredNavItemList.length === 0 ? null : (
    <Drawer
      variant={drawerVariant}
      open={drawerOpen}
      onClose={handleCloseNav}
      sx={drawerSx}
    >
      {drawerVariant === "permanent" ? (
        // Add empty toolbar as to set the navigation drawer below the header toolbar
        <Toolbar />
      ) : (
        // Add close button for SM and XS screens
        <div className="d-flex justify-content-center mt-2">
          <IconButton onClick={handleCloseNav}>
            <Close />
          </IconButton>
        </div>
      )}
      <Box sx={{ overflow: "auto" }}>
        <List>
          <TransitionGroup>
            {filteredNavItemList.map((navItem) => (
              <Collapse key={navItem.to} unmountOnExit>
                <NavigationItem
                  navItem={navItem}
                  handleCloseNav={handleCloseNav}
                />
              </Collapse>
            ))}
          </TransitionGroup>
        </List>
      </Box>
    </Drawer>
  );
};
// ########################################
// EXPORT
export default Navigation;
