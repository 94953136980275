// ########################################
// IMPORT DEPENDENCIES
// ----------------------------------------
const { intersectTickets } = require("./intersectTickets");
const {
  getTicketsForUserAndTimepoint,
} = require("./getTicketsForUserAndTimepoint");
// ########################################
// SUB-FUNCTION
// ----------------------------------------
// Filters objects from the array that has at least one field changed
function filterChangedTickets(original, modified) {
  return original.filter((originalObj) => {
    const isIndefinatelyRepeating = originalObj.repeat === -1;
    const modifiedObj = modified.find(
      (obj) =>
        obj &&
        obj.userId &&
        obj.userId === originalObj.userId &&
        obj.measurementId === originalObj.measurementId &&
        (obj.entryNumber === originalObj.entryNumber ||
          (obj.repeat === -1 && isIndefinatelyRepeating)),
    );
    // Compare each field in the object to see if any value has changed
    for (let key in originalObj) {
      if (
        key === "__v" ||
        key === "_id" ||
        key === "responseId" ||
        key === "repeat" ||
        key === "entryNumber" ||
        key === "hasStarted" ||
        key === "isCompleted" ||
        key === "viewIdx" ||
        key === "notificationStatus" ||
        key === "createdAt" ||
        key === "updatedAt"
      ) {
        continue;
      }
      if (originalObj[key] !== modifiedObj[key]) {
        return true; // At least one field is different, so keep this object
      }
    }
    return false; // No field has changed, so discard this object
  });
}

// ########################################
// FUNCTION
// ----------------------------------------
// Add leading zeros
export const getCreateUpdateDeleteTicketList = (
  study,
  timepoint,
  ticketList,
  enrolledConsumerList,
  doRecreateCompletedTickets,
) => {
  // -----------------------------------------------
  // Get existing tickets that may need to be updated or deleted
  const currentTicketList = ticketList.filter(
    (ticket) => ticket.timepointId === timepoint._id,
  );
  // -----------------------------------------------
  // Get all new tickets for this timepoint
  const newTicketList = enrolledConsumerList
    ? enrolledConsumerList
        .map((participant) =>
          getTicketsForUserAndTimepoint(
            participant,
            study,
            timepoint,
            "taskResponses",
          ),
        )
        .flat()
        .filter((t) => t !== undefined)
    : [];
  // -----------------------------------------------
  // Set of tickets to be deleted (i.e., tickets that do not exist in the new set or are indefinately repeating)
  let { XinY: ticketListToPatch, XnotinY: ticketListToDelete } =
    intersectTickets(currentTicketList, newTicketList);
  // Set of tickets to be created (i.e., new tickets that do not match one in the existing set, or are indefinately repeating)
  let { XinY: remainingTicketList, XnotinY: ticketListToPost } =
    intersectTickets(newTicketList, currentTicketList);
  // -----------------------------------------------
  // To-be-deleted tickets: Filter out completed tickets if the user did not want to delete them
  ticketListToDelete = ticketListToDelete.filter(
    (ticket) =>
      (doRecreateCompletedTickets && ticket.isCompleted) || !ticket.isCompleted,
  );
  // -----------------------------------------------
  // To-be-patched tickets: Filter out tickets that are not actually any different
  ticketListToPatch = filterChangedTickets(ticketListToPatch, newTicketList);
  // Also for the to-be-patched ticketList, filter out completed tickets (if requested), and update the properties
  ticketListToPatch = ticketListToPatch
    .filter(
      (ticket) =>
        (doRecreateCompletedTickets && ticket.isCompleted) ||
        !ticket.isCompleted,
    )
    .map((ticket) => {
      let t = JSON.parse(JSON.stringify(ticket));
      const { XinY: newTicket } = intersectTickets(newTicketList, [t]);
      t.orig = JSON.parse(JSON.stringify(ticket));
      t.allowance = newTicket[0].allowance;
      t.allowanceUnit = newTicket[0].allowanceUnit;
      t.availability = newTicket[0].availability;
      t.availabilityUnit = newTicket[0].availabilityUnit;
      t.completer = newTicket[0].completer;
      t.createdAt = newTicket[0].createdAt;
      t.dateAvailable = newTicket[0].dateAvailable;
      t.dateDue = newTicket[0].dateDue;
      t.dateExpire = newTicket[0].dateExpire;
      t.entryNumber = newTicket[0].entryNumber;
      t.groupId = newTicket[0].groupId;
      t.hasStarted = false;
      t.interval = newTicket[0].interval;
      t.intervalUnit = newTicket[0].intervalUnit;
      t.isCompleted = false;
      t.level = newTicket[0].level;
      t.measurementId = newTicket[0].measurementId;
      t.notificationStatus = "idle";
      t.remainEditable = newTicket[0].remainEditable;
      t.remainVisible = newTicket[0].remainVisible;
      t.repeat = newTicket[0].repeat;
      t.responseCollection = newTicket[0].responseCollection;
      t.responseId = newTicket[0].responseId;
      t.surveyId = newTicket[0].surveyId;
      t.viewIdx = 0;
      return t;
    });
  // Return the various ticketLists
  return {
    ticketListToPost,
    ticketListToPatch,
    ticketListToDelete,
  };
};
