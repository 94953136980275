// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// -------------------------------------------------
// Components
import ColorSelector from "../form/ColorSelector";
import FontSelector from "../form/FontSelector";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import Collapse from "@mui/material/Collapse";
// =================================================
// FUNCTIONAL COMPONENT
const SurveysEditArticleContent = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "surveys.Surveys_EditArticleContent",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Local State
  const [isEnabled, setIsEnabled] = useState(props.obj ? true : false);
  const [memory, setMemory] = useState(props.obj);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Sets the local state
  const handleSetObj = (key, value) => {
    props.setObj((prevState) => {
      let newState = { ...prevState };
      newState = { ...newState, [key]: value };
      if (newState && newState.text === "") {
        newState = null;
      } else if (newState && newState.text !== "") {
        if (!newState.align) {
          newState.align = "left";
        }
        if (!newState.variant) {
          newState.variant = "body1";
        }
        if (!newState.color) {
          newState.color = "inherit";
        }
      }
      return newState;
    });
  };
  // -----------------------------------------------
  // Turns on and off the content
  const handleToggleIsEnabled = (e) => {
    setIsEnabled(e.target.checked);
    if (e.target.checked) {
      props.setObj(
        memory
          ? memory
          : {
              text: "",
              variant: "body1",
              color: "inherit",
              align: "left",
            }
      );
    } else {
      setMemory(props.obj);
      props.setObj(null);
    }
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <>
      <Grid container alignItems="center">
        {!props.alwaysEnable && (
          <Grid item xs={2}>
            <Switch
              color="secondary"
              checked={isEnabled}
              onChange={(e) => handleToggleIsEnabled(e)}
            />
          </Grid>
        )}
        <Grid item xs={10}>
          <Typography variant="h4">{props.label}</Typography>
        </Grid>
      </Grid>
      <Collapse in={isEnabled || props.alwaysEnable}>
        <Grid container alignItems="center" className="mt-2">
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              label={props.label}
              type="text"
              name="text"
              variant="filled"
              value={props.obj ? props.obj.text : ""}
              color="secondary"
              className="answer-text-green"
              onChange={(e) => handleSetObj(e.target.name, e.target.value)}
            />
          </Grid>
          {props.showOptions && props.obj && (
            <>
              <Grid item xs="auto" className="mt-3 me-3">
                <ToggleButtonGroup
                  exclusive
                  name="align"
                  value={props.obj ? props.obj.align : "left"}
                  onChange={(e, val) => val && handleSetObj("align", val)}
                >
                  <Tooltip arrow title={t("align left")} placement="top">
                    <ToggleButton
                      value="left"
                      className={
                        props.obj && props.obj.align === "left" ? "bg-grey" : ""
                      }
                    >
                      <FormatAlignLeftIcon />
                    </ToggleButton>
                  </Tooltip>
                  <Tooltip arrow title={t("align center")} placement="top">
                    <ToggleButton
                      value="center"
                      className={
                        props.obj && props.obj.align === "center"
                          ? "bg-grey"
                          : ""
                      }
                    >
                      <FormatAlignCenterIcon />
                    </ToggleButton>
                  </Tooltip>
                  <Tooltip arrow title={t("align right")} placement="top">
                    <ToggleButton
                      value="right"
                      className={
                        props.obj && props.obj.align === "right"
                          ? "bg-grey"
                          : ""
                      }
                    >
                      <FormatAlignRightIcon />
                    </ToggleButton>
                  </Tooltip>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs="auto" className="mt-3 me-3">
                <FontSelector
                  selFont={props.obj.variant}
                  setSelFont={(e) => handleSetObj("variant", e.target.value)}
                />
              </Grid>
              <Grid item xs="auto" className="mt-3">
                <ColorSelector
                  selColor={props.obj.color}
                  setSelColor={(e) => handleSetObj("color", e.target.value)}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Collapse>
      <Divider className="my-3" />
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveysEditArticleContent;
