import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import AppListItem from "../App_ListItem";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import Tooltip from "@mui/material/Tooltip";
import Wifi from "@mui/icons-material/Wifi";
import Chip from "@mui/material/Chip";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const SurveyListWithVersions = ({
  surveys,
  currentSurveyId,
  handleSetCurrentSurvey,
}) => {
  const { t } = useTranslation("components", {
    keyPrefix: "Surveys_ColumnContent",
  });
  const [versionOpen, setVersionOpen] = useState({});

  // Group surveys by parentId
  const surveyGroups = useMemo(() => {
    const groups = Object.values(
      surveys.reduce((acc, survey) => {
        const parentId = survey.parentId || survey._id;
        if (!acc[parentId]) {
          acc[parentId] = {
            parentId: parentId,
            name: survey.name,
            versions: [],
          };
        }
        acc[parentId].versions.push(survey);
        return acc;
      }, {}),
    );

    // Sort the survey groups
    groups.sort((a, b) => (a.name.official < b.name.official ? -1 : 1));

    // Sort versions and identify the latest survey
    groups.forEach((group) => {
      group.sortedVersions = [...group.versions].sort(
        (a, b) => b.version - a.version,
      );
      group.latestSurvey = group.sortedVersions[0];
    });

    return groups;
  }, [surveys]);

  // Open version list if the non-latest version is selected (e.g. through navigating by URL)
  useEffect(() => {
    if (currentSurveyId) {
      // Find the survey group that contains the currentSurveyId
      const surveyGroup = surveyGroups.find((group) =>
        group.versions.some((version) => version._id === currentSurveyId),
      );

      if (
        surveyGroup &&
        surveyGroup.versions.length > 1 &&
        surveyGroup.latestSurvey._id !== currentSurveyId
      ) {
        const parentId = surveyGroup.parentId;

        setVersionOpen((prev) => {
          if (prev[parentId]) {
            return prev; // Avoid unnecessary state updates
          }
          return {
            ...prev,
            [parentId]: true,
          };
        });
      }
    }
  }, [currentSurveyId, surveyGroups]);

  return (
    <List component="div" disablePadding>
      {surveyGroups.map((surveyGroup) => {
        const latestSurvey = surveyGroup.latestSurvey;
        const sortedVersions = surveyGroup.sortedVersions;
        const isCurrentInGroup = surveyGroup.versions.some(
          (version) => version._id === currentSurveyId,
        );

        return (
          <React.Fragment key={surveyGroup.parentId}>
            <AppListItem
              divider
              slideIn
              isSelected={latestSurvey._id === currentSurveyId}
              onClick={() => handleSetCurrentSurvey(latestSurvey._id)}
              textPrimary={
                surveyGroup.name.official
                  ? surveyGroup.name.official
                  : "Unnamed survey"
              }
              icon={
                latestSurvey.isLive && (
                  <Tooltip arrow title={t("live")} placement="top">
                    <span>
                      <Wifi color="primary" className="font-size-085rem" />
                    </span>
                  </Tooltip>
                )
              }
              iconEnd={
                isCurrentInGroup ? (
                  <Chip
                    label={`v${
                      surveyGroup.versions.find(
                        (version) => version._id === currentSurveyId,
                      )?.version || latestSurvey.version
                    }`}
                    size="small"
                    sx={{ fontSize: "0.67rem", height: "18px" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setVersionOpen((prev) => ({
                        ...prev,
                        [surveyGroup.parentId]: !prev[surveyGroup.parentId],
                      }));
                    }}
                    onDelete={(e) => {
                      e.stopPropagation();
                      setVersionOpen((prev) => ({
                        ...prev,
                        [surveyGroup.parentId]: !prev[surveyGroup.parentId],
                      }));
                    }}
                    deleteIcon={
                      versionOpen[surveyGroup.parentId] ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    }
                  />
                ) : null
              }
            />
            <Collapse
              in={versionOpen[surveyGroup.parentId] && isCurrentInGroup}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {sortedVersions.map((survey) => (
                  <AppListItem
                    key={survey._id}
                    divider
                    slideIn
                    isSelected={survey._id === currentSurveyId}
                    onClick={() => handleSetCurrentSurvey(survey._id)}
                    textPrimary={`v${survey.version}`}
                    icon={
                      survey.isLive && (
                        <Tooltip arrow title={t("live")} placement="top">
                          <span>
                            <Wifi
                              color="primary"
                              className="font-size-085rem"
                            />
                          </span>
                        </Tooltip>
                      )
                    }
                  />
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default SurveyListWithVersions;
