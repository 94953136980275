// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
// -------------------------------------------------
// Component elements
import Hero from "./Hero";
import CmsContent from "./CmsContent";
// -------------------------------------------------
// Basic elements
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import StudyCards from "./StudyCards";



const HomeTemplate = ({ currentWebpage }) => {
  const [videoURL, setVideoURL] = useState("");

  useEffect(() => {
    const url = currentWebpage.contentList.find((item) => item.fieldname === "body_video")?.content.match(/<p>(.*?)<\/p>/)?.[1] ?? "";
    setVideoURL(url);
  }, [currentWebpage]);

  
  return (
    <>
      <Hero
        title={
          <CmsContent currentWebpage={currentWebpage} fieldname="hero_title" />
        }
        description={
          <CmsContent
            currentWebpage={currentWebpage}
            fieldname="hero_description"
          />
        }
      />
      <Box
        className="py-4"
        sx={{
          backgroundImage: "url('images/sleepfix/bg-02.png')",
          backgroundOrigin: "border-box",
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Container>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={12} md={7} lg={6} className="pe-4">
              <Typography>
                <CmsContent
                  currentWebpage={currentWebpage}
                  fieldname="body_description"
                />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <iframe
                width="100%"
                height="300"
                src={videoURL}
                title="Sleep Registry Introduction"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
                className="pt-3"
                sx={{ maxWidth: "100%", border: "none" }}
              />
            </Grid>
          </Grid>
        </Container>
        <StudyCards />
      </Box>
    </>
  );
};

export default HomeTemplate;
