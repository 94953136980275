// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// -------------------------------------------------
// Redux
import { surveysSelectors } from "../../redux/reducers/surveys";
// -------------------------------------------------
// MUI components
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

// =================================================
// FUNCTIONAL COMPONENT
const SurveysSelectVersion = (props) => {
  const { currentSurvey } = props;
  const { t } = useTranslation("components", {
    keyPrefix: "surveys.SurveyVersionSelector",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const isXS = useSelector((state) => state.ui.isXS);
  const surveyList = useSelector((state) => surveysSelectors.selectAll(state));

  const navigate = useNavigate();

  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  const surveyVersions = useMemo(() => {
    if (!currentSurvey || !currentSurvey._id || !surveyList) return [];

    const surveyId = currentSurvey._id;
    const currentSurveyItem = surveyList.find(
      (survey) => survey._id === surveyId,
    );
    if (!currentSurveyItem) return [];

    return surveyList
      .filter(
        (survey) =>
          survey.parentId === currentSurveyItem.parentId &&
          survey.folder !== "archive",
      )
      .map((survey) => ({
        _id: survey._id,
        name: survey.name,
        version: survey.version,
        updatedAt: survey.updatedAt,
        changelog: survey.changelog,
      }));
  }, [currentSurvey, surveyList]);

  // Sets the URL search query to the survey identifier
  const handleSetCurrentSurvey = (id) => {
    props.handleCloseDrawer();
    let el;
    el = document.querySelector("#scroll-top-app");
    if (el) {
      el.scrollIntoView({ block: "end" });
    }
    navigate(`/surveys?surveyId=${id}`, { replace: true });
  };

  // ===============================================
  // RENDER COMPONENT
  return (
    <Paper className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-625px"}>
      <Typography variant="h6" className="mb-3 font-weight-bold">
        {"Select Survey Version"}
      </Typography>
      <List>
        {surveyVersions &&
          surveyVersions
            .sort((a, b) => {
              return b.version - a.version;
            })
            .filter((survey) => survey.folder !== "archive")
            .map((survey, index) => (
              <React.Fragment key={survey._id}>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => handleSetCurrentSurvey(survey._id)}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          variant="subtitle1"
                          className="font-weight-bold"
                        >
                          Version {survey.version}
                        </Typography>
                      }
                      secondary={
                        <>
                          <Typography variant="body2" color="textSecondary">
                            Last updated:{" "}
                            {new Date(survey.updatedAt).toLocaleDateString()}
                          </Typography>
                          {survey.changelog ? (
                            <Typography
                              variant="textSecondary"
                              className="mt-1"
                            >
                              {survey.changelog}
                            </Typography>
                          ) : (
                            <Typography color="textSecondary" className="mt-1">
                              No changelog available.
                            </Typography>
                          )}
                        </>
                      }
                    />
                  </ListItemButton>
                </ListItem>
                {index < surveyVersions.length - 1 && (
                  <Divider component="li" />
                )}
              </React.Fragment>
            ))}
      </List>
    </Paper>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveysSelectVersion;
