// =================================================
// IMPORT
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
// =================================================
// IMPORT API
import { client, rooturl } from "../../api-routes/client";
const apiurl = `${rooturl}/feedback`;
// -------------------------------------------------
// Use 'createEntityAdapter' to store the response data in a normalized state
const adapter = createEntityAdapter({
  selectId: (a) => a._id,
});
// =================================================
// INIT STATE
const initialState = adapter.getInitialState({
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed',
  error: null,
});
// =================================================
// ASYNC API ACTIONS
// -------------------------------------------------
// API post feedback
export const postFeedback = createAsyncThunk(
  "feedback/postFeedback",
  async ({ socket, requestingUser, feedbackData }) => {
    // Make the call to the database
    const response = await client.post(apiurl, requestingUser, feedbackData);
    // Return the response
    return response.data;
  }
);

// =================================================
// SLICE
const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postFeedback.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postFeedback.fulfilled, (state, action) => {
        state.status = "succeeded";
        adapter.addOne(state, action.payload);
      })
      .addCase(postFeedback.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// =================================================
// EXPORT ACTIONS
// =================================================
// EXPORT SELECTORS
export const { selectAll: selectAllFeedback, selectById: selectFeedbackById } =
  adapter.getSelectors((state) => state.feedback);

// =================================================
// EXPORT REDUCER
export default feedbackSlice.reducer;
