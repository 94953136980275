// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useCallback } from "react";
import {produce} from "immer";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
// =================================================
// VARIABLES
const languages = ["en", "nl", "de"];
// =================================================
// TRANSLATE ROW COMPONENT
const TranslateRow = React.memo(
  ({ keyProp, values, fieldname, changeFcnOptions, handleSetObj }) => {
    return (
      ((values && values.en !== null) || Object.keys(values).length > 1) && (
        <Grid container className="mb-2">
          {languages.map((lang) => (
            <Grid
              item
              xs
              key={`${keyProp}-${fieldname}-${lang}`}
              className="px-2"
            >
              <Typography variant="overline" color="slategray">
                {`${fieldname
                  .replaceAll(".", " ")
                  .toUpperCase()} ${lang.toUpperCase()}`}
              </Typography>
              <InputBase
                disableInjectingGlobalStyles={true}
                fullWidth
                multiline
                size="small"
                type="text"
                value={values && values[lang] ? values[lang] : ""}
                color="secondary"
                variant="outlined"
                className="translation-input"
                onChange={(e) => {
                  handleSetObj(fieldname, lang, {
                    ...changeFcnOptions,
                    value: e.target.value,
                  });
                }}
              />
            </Grid>
          ))}
        </Grid>
      )
    );
  }
);
// =================================================
// FUNCTIONAL COMPONENT
const SurveysEditTranslate = (props) => {
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Sets the local state
  const handleSetObj = useCallback(
    (type, lang, params) => {
      props.setObj((prevState) =>
        produce(prevState, (draftState) => {
          const {
            pageIdx,
            gcIdx,
            giIdx,
            articleIdx,
            answerIdx,
            rowIdx,
            colIdx,
            validationIdx,
            scoreIdx,
            labelIdx,
          } = params;
          switch (type) {
            case "name.official":
              if (!draftState.name.official) draftState.name.official = {};
              draftState.name.official[lang] = params.value;
              break;
            case "name.display":
              if (!draftState.name.display) draftState.name.display = {};
              draftState.name.display[lang] = params.value;
              break;
            case "page.title.text":
              if (!draftState.pageList[pageIdx].title)
                draftState.pageList[pageIdx].title = {};
              if (!draftState.pageList[pageIdx].title.text)
                draftState.pageList[pageIdx].title.text = {};
              draftState.pageList[pageIdx].title.text[lang] = params.value;
              break;
            case "gc.title.text":
              if (!draftState.pageList[pageIdx].gridContainerList[gcIdx].title)
                draftState.pageList[pageIdx].gridContainerList[gcIdx].title =
                  {};
              if (
                !draftState.pageList[pageIdx].gridContainerList[gcIdx].title
                  .text
              )
                draftState.pageList[pageIdx].gridContainerList[
                  gcIdx
                ].title.text = {};
              draftState.pageList[pageIdx].gridContainerList[gcIdx].title.text[
                lang
              ] = params.value;
              break;
            case "gi.title.text":
              if (
                !draftState.pageList[pageIdx].gridContainerList[gcIdx]
                  .gridItemList[giIdx].title
              )
                draftState.pageList[pageIdx].gridContainerList[
                  gcIdx
                ].gridItemList[giIdx].title = {};
              if (
                !draftState.pageList[pageIdx].gridContainerList[gcIdx]
                  .gridItemList[giIdx].title.text
              )
                draftState.pageList[pageIdx].gridContainerList[
                  gcIdx
                ].gridItemList[giIdx].title.text = {};
              draftState.pageList[pageIdx].gridContainerList[
                gcIdx
              ].gridItemList[giIdx].title.text[lang] = params.value;
              break;
            case "article.formLabel":
              if (
                !draftState.pageList[pageIdx].gridContainerList[gcIdx]
                  .gridItemList[giIdx].articleList[articleIdx].formLabel
              )
                draftState.pageList[pageIdx].gridContainerList[
                  gcIdx
                ].gridItemList[giIdx].articleList[articleIdx].formLabel = {};
              draftState.pageList[pageIdx].gridContainerList[
                gcIdx
              ].gridItemList[giIdx].articleList[articleIdx].formLabel[lang] =
                params.value;
              break;
            case "article.inputLabel":
              if (
                !draftState.pageList[pageIdx].gridContainerList[gcIdx]
                  .gridItemList[giIdx].articleList[articleIdx].inputLabel
              ) {
                draftState.pageList[pageIdx].gridContainerList[
                  gcIdx
                ].gridItemList[giIdx].articleList[articleIdx].inputLabel = {};
              }
              draftState.pageList[pageIdx].gridContainerList[
                gcIdx
              ].gridItemList[giIdx].articleList[articleIdx].inputLabel[lang] =
                params.value;
              break;

            case "article.title.text":
              if (
                !draftState.pageList[pageIdx].gridContainerList[gcIdx]
                  .gridItemList[giIdx].articleList[articleIdx].title
              ) {
                draftState.pageList[pageIdx].gridContainerList[
                  gcIdx
                ].gridItemList[giIdx].articleList[articleIdx].title = {};
              }
              if (
                !draftState.pageList[pageIdx].gridContainerList[gcIdx]
                  .gridItemList[giIdx].articleList[articleIdx].title.text
              ) {
                draftState.pageList[pageIdx].gridContainerList[
                  gcIdx
                ].gridItemList[giIdx].articleList[articleIdx].title.text = {};
              }
              draftState.pageList[pageIdx].gridContainerList[
                gcIdx
              ].gridItemList[giIdx].articleList[articleIdx].title.text[lang] =
                params.value;
              break;

            case "article.content.text":
              if (
                !draftState.pageList[pageIdx].gridContainerList[gcIdx]
                  .gridItemList[giIdx].articleList[articleIdx].content
              ) {
                draftState.pageList[pageIdx].gridContainerList[
                  gcIdx
                ].gridItemList[giIdx].articleList[articleIdx].content = {};
              }
              if (
                !draftState.pageList[pageIdx].gridContainerList[gcIdx]
                  .gridItemList[giIdx].articleList[articleIdx].content.text
              ) {
                draftState.pageList[pageIdx].gridContainerList[
                  gcIdx
                ].gridItemList[giIdx].articleList[articleIdx].content.text = {};
              }
              draftState.pageList[pageIdx].gridContainerList[
                gcIdx
              ].gridItemList[giIdx].articleList[articleIdx].content.text[lang] =
                params.value;
              break;

            case "article.instructions.text":
              if (
                !draftState.pageList[pageIdx].gridContainerList[gcIdx]
                  .gridItemList[giIdx].articleList[articleIdx].instructions
              ) {
                draftState.pageList[pageIdx].gridContainerList[
                  gcIdx
                ].gridItemList[giIdx].articleList[articleIdx].instructions = {};
              }
              if (
                !draftState.pageList[pageIdx].gridContainerList[gcIdx]
                  .gridItemList[giIdx].articleList[articleIdx].instructions.text
              ) {
                draftState.pageList[pageIdx].gridContainerList[
                  gcIdx
                ].gridItemList[giIdx].articleList[
                  articleIdx
                ].instructions.text = {};
              }
              draftState.pageList[pageIdx].gridContainerList[
                gcIdx
              ].gridItemList[giIdx].articleList[articleIdx].instructions.text[
                lang
              ] = params.value;
              break;

            case "answer.text":
              if (
                !draftState.pageList[pageIdx].gridContainerList[gcIdx]
                  .gridItemList[giIdx].articleList[articleIdx].answerList[
                  answerIdx
                ].text
              ) {
                draftState.pageList[pageIdx].gridContainerList[
                  gcIdx
                ].gridItemList[giIdx].articleList[articleIdx].answerList[
                  answerIdx
                ].text = {};
              }
              draftState.pageList[pageIdx].gridContainerList[
                gcIdx
              ].gridItemList[giIdx].articleList[articleIdx].answerList[
                answerIdx
              ].text[lang] = params.value;
              break;

            case "answer.group":
              if (
                !draftState.pageList[pageIdx].gridContainerList[gcIdx]
                  .gridItemList[giIdx].articleList[articleIdx].answerList[
                  answerIdx
                ].group
              ) {
                draftState.pageList[pageIdx].gridContainerList[
                  gcIdx
                ].gridItemList[giIdx].articleList[articleIdx].answerList[
                  answerIdx
                ].group = {};
              }
              draftState.pageList[pageIdx].gridContainerList[
                gcIdx
              ].gridItemList[giIdx].articleList[articleIdx].answerList[
                answerIdx
              ].group[lang] = params.value;
              break;

            case "row.text":
              if (
                !draftState.pageList[pageIdx].gridContainerList[gcIdx]
                  .gridItemList[giIdx].articleList[articleIdx].rowList[rowIdx]
                  .text
              ) {
                draftState.pageList[pageIdx].gridContainerList[
                  gcIdx
                ].gridItemList[giIdx].articleList[articleIdx].rowList[
                  rowIdx
                ].text = {};
              }
              draftState.pageList[pageIdx].gridContainerList[
                gcIdx
              ].gridItemList[giIdx].articleList[articleIdx].rowList[
                rowIdx
              ].text[lang] = params.value;
              break;

            case "col.text":
              if (
                !draftState.pageList[pageIdx].gridContainerList[gcIdx]
                  .gridItemList[giIdx].articleList[articleIdx].columnList[
                  colIdx
                ].text
              ) {
                draftState.pageList[pageIdx].gridContainerList[
                  gcIdx
                ].gridItemList[giIdx].articleList[articleIdx].columnList[
                  colIdx
                ].text = {};
              }
              draftState.pageList[pageIdx].gridContainerList[
                gcIdx
              ].gridItemList[giIdx].articleList[articleIdx].columnList[
                colIdx
              ].text[lang] = params.value;
              break;

            case "col.inputLabel":
              if (
                !draftState.pageList[pageIdx].gridContainerList[gcIdx]
                  .gridItemList[giIdx].articleList[articleIdx].columnList[
                  colIdx
                ].inputLabel
              ) {
                draftState.pageList[pageIdx].gridContainerList[
                  gcIdx
                ].gridItemList[giIdx].articleList[articleIdx].columnList[
                  colIdx
                ].inputLabel = {};
              }
              draftState.pageList[pageIdx].gridContainerList[
                gcIdx
              ].gridItemList[giIdx].articleList[articleIdx].columnList[
                colIdx
              ].inputLabel[lang] = params.value;
              break;

            case "validation.errorMsg":
              if (
                !draftState.pageList[pageIdx].gridContainerList[gcIdx]
                  .gridItemList[giIdx].articleList[articleIdx].validation[
                  validationIdx
                ].errorMsg
              ) {
                draftState.pageList[pageIdx].gridContainerList[
                  gcIdx
                ].gridItemList[giIdx].articleList[articleIdx].validation[
                  validationIdx
                ].errorMsg = {};
              }
              draftState.pageList[pageIdx].gridContainerList[
                gcIdx
              ].gridItemList[giIdx].articleList[articleIdx].validation[
                validationIdx
              ].errorMsg[lang] = params.value;
              break;

            case "score.category.label":
              if (
                !draftState.scoring[scoreIdx].grouping.categoryList[labelIdx]
                  .label
              ) {
                draftState.scoring[scoreIdx].grouping.categoryList[
                  labelIdx
                ].label = {};
              }
              draftState.scoring[scoreIdx].grouping.categoryList[
                labelIdx
              ].label[lang] = params.value;
              break;

            case "score.category.feedback":
              if (
                !draftState.scoring[scoreIdx].grouping.categoryList[labelIdx]
                  .feedback
              ) {
                draftState.scoring[scoreIdx].grouping.categoryList[
                  labelIdx
                ].feedback = {};
              }
              draftState.scoring[scoreIdx].grouping.categoryList[
                labelIdx
              ].feedback[lang] = params.value;
              break;
            default:
              return;
          }
        })
      );
    },
    [props]
  );
  // ===============================================
  // RENDER COMPONENT
  return (
    <Box>
      <Paper className="mt-2 mx-2 px-1 pt-3 pb-1">
        <Grid container alignItems="center">
          <TranslateRow
            keyProp={`survey-official-name`}
            values={props.obj.name.official}
            fieldname="name.official"
            changeFcnOptions={{}}
            handleSetObj={handleSetObj}
          />
          <TranslateRow
            keyProp={`survey-display-name`}
            values={props.obj.name.display}
            fieldname="name.display"
            changeFcnOptions={{}}
            handleSetObj={handleSetObj}
          />
        </Grid>
      </Paper>
      {/* ----------------------------------------------- */}
      {/* PAGES */}
      <Box className="mx-2 px-1 pt-3 pb-1">
        {props.obj.pageList.map((page, i) => (
          <Grid
            container
            key={page._id}
            alignItems="center"
            className="border border-secondary rounded bg-transparant-grey-33 pb-2 mb-2"
          >
            <Grid item xs={12}>
              <Typography variant="overline" color="slategray" className="ps-2">
                {`page ${i + 1}`}
              </Typography>
            </Grid>
            {page.title && page.title.text && (
              <Grid item className="flex-grow-1">
                <Grid container>
                  <TranslateRow
                    keyProp={`${page._id}-title`}
                    values={page.title.text}
                    fieldname="page.title.text"
                    changeFcnOptions={{
                      pageIdx: i,
                    }}
                    handleSetObj={handleSetObj}
                  />
                </Grid>
              </Grid>
            )}
            {page.gridContainerList.map((gc, j) => (
              <Grid
                container
                key={gc._id}
                alignItems="center"
                className="border border-secondary border-dashed rounded bg-transparant-grey-33 pb-2 mt-2 mx-2"
              >
                <Grid item xs={12}>
                  <Typography
                    variant="overline"
                    color="slategray"
                    className="ps-2"
                  >
                    {`container ${j + 1}`}
                  </Typography>
                </Grid>
                {gc.title && (
                  <TranslateRow
                    keyProp={`${gc._id}-title`}
                    values={gc.title.text}
                    fieldname="gc.title.text"
                    changeFcnOptions={{
                      pageIdx: i,
                      gcIdx: j,
                    }}
                    handleSetObj={handleSetObj}
                  />
                )}
                <Card className="w-100 mx-2 p-2 pb-0">
                  {gc.gridItemList.map((gi, k) => (
                    <React.Fragment key={gi._id}>
                      {gi.title && (
                        <TranslateRow
                          keyProp={`${gi._id}-title`}
                          values={gi.title.text}
                          fieldname="gi.title.text"
                          changeFcnOptions={{
                            pageIdx: i,
                            gcIdx: j,
                            giIdx: k,
                          }}
                          handleSetObj={handleSetObj}
                        />
                      )}
                      <Grid
                        container
                        alignItems="center"
                        className="border rounded mb-2"
                      >
                        <Grid item xs={12}>
                          <Typography
                            variant="overline"
                            color="slategray"
                            className="ps-2"
                          >
                            {`article box ${k + 1}`}
                          </Typography>
                        </Grid>
                        {gi.articleList.map((article, m) => (
                          <Grid item className="flex-grow-1" key={article._id}>
                            {/* FORMLABEL */}
                            {article.formLabel && (
                              <TranslateRow
                                keyProp={`${article._id}-formlabel`}
                                values={article.formLabel}
                                fieldname="article.formLabel"
                                changeFcnOptions={{
                                  pageIdx: i,
                                  gcIdx: j,
                                  giIdx: k,
                                  articleIdx: m,
                                }}
                                handleSetObj={handleSetObj}
                              />
                            )}
                            {/* INPUTLABEL */}
                            {article.inputLabel && (
                              <TranslateRow
                                keyProp={`${article._id}-inputlabel`}
                                values={article.inputLabel}
                                fieldname="article.inputLabel"
                                changeFcnOptions={{
                                  pageIdx: i,
                                  gcIdx: j,
                                  giIdx: k,
                                  articleIdx: m,
                                }}
                                handleSetObj={handleSetObj}
                              />
                            )}
                            {/* TITLE */}
                            {article.title && (
                              <TranslateRow
                                keyProp={`${article._id}-title`}
                                values={article.title.text}
                                fieldname="article.title.text"
                                changeFcnOptions={{
                                  pageIdx: i,
                                  gcIdx: j,
                                  giIdx: k,
                                  articleIdx: m,
                                }}
                                handleSetObj={handleSetObj}
                              />
                            )}
                            {/* CONTENT.TEXT */}
                            {article.content && (
                              <TranslateRow
                                keyProp={`${article._id}-content`}
                                values={article.content.text}
                                fieldname="article.content.text"
                                changeFcnOptions={{
                                  pageIdx: i,
                                  gcIdx: j,
                                  giIdx: k,
                                  articleIdx: m,
                                }}
                                handleSetObj={handleSetObj}
                              />
                            )}
                            {/* INSTRUCTIONS.TEXT */}
                            {article.instructions &&
                              article.instructions.text && (
                                <TranslateRow
                                  keyProp={`${article._id}-instructions`}
                                  values={article.instructions.text}
                                  fieldname="article.instructions.text"
                                  changeFcnOptions={{
                                    pageIdx: i,
                                    gcIdx: j,
                                    giIdx: k,
                                    articleIdx: m,
                                  }}
                                  handleSetObj={handleSetObj}
                                />
                              )}
                            {/* ROWS */}
                            {article.rowList && article.rowList.length > 0 && (
                              <Grid container>
                                <Grid
                                  item
                                  className="flex-grow-1 border border-dashed rounded mx-2 mb-2"
                                >
                                  {article.rowList.map((row, r) => (
                                    <React.Fragment key={row._id}>
                                      <Grid item xs={12}>
                                        <Typography
                                          variant="overline"
                                          color="slategray"
                                          className="ps-2"
                                        >
                                          {`row ${r + 1}`}
                                        </Typography>
                                      </Grid>
                                      {/* ROW.FORMLABEL */}
                                      {row.formLabel && (
                                        <TranslateRow
                                          keyProp={`${row._id}-formLabel`}
                                          values={row.formLabel}
                                          fieldname="row.formLabel"
                                          changeFcnOptions={{
                                            pageIdx: i,
                                            gcIdx: j,
                                            giIdx: k,
                                            articleIdx: m,
                                            rowIdx: r,
                                          }}
                                          handleSetObj={handleSetObj}
                                        />
                                      )}
                                      {/* ROW.INPUTLABEL */}
                                      {row.inputLabel && (
                                        <TranslateRow
                                          keyProp={`${row._id}-inputLabel`}
                                          values={row.inputLabel}
                                          fieldname="row.inputLabel"
                                          changeFcnOptions={{
                                            pageIdx: i,
                                            gcIdx: j,
                                            giIdx: k,
                                            articleIdx: m,
                                            rowIdx: r,
                                          }}
                                          handleSetObj={handleSetObj}
                                        />
                                      )}
                                      {/* ROW.TEXT */}
                                      {row.text && (
                                        <TranslateRow
                                          keyProp={`${row._id}-text`}
                                          values={row.text}
                                          fieldname="row.text"
                                          changeFcnOptions={{
                                            pageIdx: i,
                                            gcIdx: j,
                                            giIdx: k,
                                            articleIdx: m,
                                            rowIdx: r,
                                          }}
                                          handleSetObj={handleSetObj}
                                        />
                                      )}
                                    </React.Fragment>
                                  ))}
                                </Grid>
                              </Grid>
                            )}
                            {/* COLUMNS */}
                            {article.columnList &&
                              article.columnList.length > 0 && (
                                <Grid container>
                                  <Grid
                                    item
                                    className="flex-grow-1 border border-dashed rounded mx-2 mb-2"
                                  >
                                    {article.columnList.map((col, c) => (
                                      <React.Fragment key={col._id}>
                                        <Grid item xs={12}>
                                          <Typography
                                            variant="overline"
                                            color="slategray"
                                            className="ps-2"
                                          >
                                            {`column ${c + 1}`}
                                          </Typography>
                                        </Grid>
                                        {/* COL.INPUTLABEL */}
                                        {col.inputLabel && (
                                          <TranslateRow
                                            keyProp={`${col._id}-inputLabel`}
                                            values={col.inputLabel}
                                            fieldname="col.inputLabel"
                                            changeFcnOptions={{
                                              pageIdx: i,
                                              gcIdx: j,
                                              giIdx: k,
                                              articleIdx: m,
                                              colIdx: c,
                                            }}
                                            handleSetObj={handleSetObj}
                                          />
                                        )}
                                        {/* COL.TEXT */}
                                        {col.text && (
                                          <TranslateRow
                                            keyProp={`${col._id}-text`}
                                            values={col.text}
                                            fieldname="col.text"
                                            changeFcnOptions={{
                                              pageIdx: i,
                                              gcIdx: j,
                                              giIdx: k,
                                              articleIdx: m,
                                              colIdx: c,
                                            }}
                                            handleSetObj={handleSetObj}
                                          />
                                        )}
                                      </React.Fragment>
                                    ))}
                                  </Grid>
                                </Grid>
                              )}
                            {/* ANSWERS */}
                            {article.answerList &&
                              article.answerList.length > 0 && (
                                <Grid container>
                                  <Grid
                                    item
                                    className="flex-grow-1 border border-dashed rounded mx-2 mb-2"
                                  >
                                    {article.answerList.map((answer, a) => (
                                      <React.Fragment key={answer._id || a}>
                                        <Grid item xs={12}>
                                          <Typography
                                            variant="overline"
                                            color="slategray"
                                            className="ps-2"
                                          >
                                            {`answer option ${a + 1}`}
                                          </Typography>
                                        </Grid>
                                        {/* ANSWER.TEXT */}
                                        {answer.text && (
                                          <TranslateRow
                                            keyProp={`answer-${answer.value}-text`}
                                            values={answer.text}
                                            fieldname="answer.text"
                                            changeFcnOptions={{
                                              pageIdx: i,
                                              gcIdx: j,
                                              giIdx: k,
                                              articleIdx: m,
                                              answerIdx: a,
                                            }}
                                            handleSetObj={handleSetObj}
                                          />
                                        )}
                                        {/* ANSWER.GROUP */}
                                        {answer.group && (
                                          <TranslateRow
                                            keyProp={`answer-${answer.value}-group`}
                                            values={answer.group}
                                            fieldname="answer.group"
                                            changeFcnOptions={{
                                              pageIdx: i,
                                              gcIdx: j,
                                              giIdx: k,
                                              articleIdx: m,
                                              answerIdx: a,
                                            }}
                                            handleSetObj={handleSetObj}
                                          />
                                        )}
                                      </React.Fragment>
                                    ))}
                                  </Grid>
                                </Grid>
                              )}
                            {/* VALIDATION */}
                            {article.validation &&
                              article.validation.length > 0 && (
                                <Grid container>
                                  <Grid
                                    item
                                    className="flex-grow-1 border border-dashed rounded mx-2 mb-2"
                                  >
                                    {article.validation.map(
                                      (validation, v) =>
                                        validation.test && (
                                          <React.Fragment
                                            key={validation._id || v}
                                          >
                                            <Grid item xs={12}>
                                              <Typography
                                                variant="overline"
                                                color="slategray"
                                                className="ps-2"
                                              >
                                                validation error message
                                              </Typography>
                                            </Grid>
                                            {/* VALIDATION.ERRORMSG */}
                                            {validation.errorMsg && (
                                              <TranslateRow
                                                keyProp={`validation-${v}-error-message`}
                                                values={validation.errorMsg}
                                                fieldname="validation.errorMsg"
                                                changeFcnOptions={{
                                                  pageIdx: i,
                                                  gcIdx: j,
                                                  giIdx: k,
                                                  articleIdx: m,
                                                  validationIdx: v,
                                                }}
                                                handleSetObj={handleSetObj}
                                              />
                                            )}
                                          </React.Fragment>
                                        )
                                    )}
                                  </Grid>
                                </Grid>
                              )}
                          </Grid>
                        ))}
                      </Grid>
                    </React.Fragment>
                  ))}
                </Card>
              </Grid>
            ))}
          </Grid>
        ))}
        {/* SCORING */}
        {props.obj.scoring &&
          props.obj.scoring.filter((score) => score.method !== "arithmetic")
            .length > 0 && (
            <Paper className="mt-2 px-2 pt-1 pb-1">
              <Grid container>
                {props.obj.scoring.map((score, i) =>
                  score.method === "classification-label" ? (
                    <React.Fragment key={`score-${score._id}`}>
                      <Grid item xs={12}>
                        <Typography
                          variant="overline"
                          color="slategray"
                          className="ps-2"
                        >
                          {`Score classification to label ${i + 1}`}
                        </Typography>
                      </Grid>
                      {score.grouping &&
                        score.grouping.categoryList &&
                        score.grouping.categoryList.map((cat, j) => (
                          <React.Fragment key={`score-${score._id}-label-${j}`}>
                            {cat.label && (
                              <TranslateRow
                                keyProp={`score-${score._id}-label`}
                                values={cat.label}
                                fieldname="score.category.label"
                                changeFcnOptions={{
                                  scoreIdx: i,
                                  labelIdx: j,
                                }}
                                handleSetObj={handleSetObj}
                              />
                            )}
                            {cat.feedback && (
                              <TranslateRow
                                keyProp={`score-${score._id}-feedback`}
                                values={cat.feedback}
                                fieldname="score.category.feedback"
                                changeFcnOptions={{
                                  scoreIdx: i,
                                  labelIdx: j,
                                }}
                                handleSetObj={handleSetObj}
                              />
                            )}
                          </React.Fragment>
                        ))}
                    </React.Fragment>
                  ) : score.method === "classification-value" ? (
                    <React.Fragment key={`score-${score._id}`}>
                      <Grid item xs={12}>
                        <Typography
                          variant="overline"
                          color="slategray"
                          className="ps-2"
                        >
                          {`Score classification to value ${i + 1}`}
                        </Typography>
                      </Grid>
                      {score.grouping &&
                        score.grouping.categoryList &&
                        score.grouping.categoryList.map((cat, j) => (
                          <React.Fragment key={`score-${score._id}-value-${j}`}>
                            {cat.feedback && (
                              <TranslateRow
                                keyProp={`score-${score._id}-feedback`}
                                values={cat.feedback}
                                fieldname="score.category.feedback"
                                changeFcnOptions={{
                                  scoreIdx: i,
                                  labelIdx: j,
                                }}
                                handleSetObj={handleSetObj}
                              />
                            )}
                          </React.Fragment>
                        ))}
                    </React.Fragment>
                  ) : null
                )}
              </Grid>
            </Paper>
          )}
      </Box>
    </Box>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveysEditTranslate;
