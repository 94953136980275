import React from "react";
// -------------------------------------------------
// Contexts
import { useAuth } from "../../contexts/auth";
import AuthSignIn from "../../pages/AuthSignIn";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
const Hero = (props) => {
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Context state
  const { currentAuth } = useAuth();
  return (
    <Box
      className="shadow"
      sx={{
        backgroundImage: `url("./images/hero.jpg")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Box
        className="h-100 bd-filt-blur-5px"
        sx={{ padding: "96px 0rem 2rem 0rem" }}
      >
        <Container>
          <Grid container alignItems="center">
            <Grid
              item
              xs={12}
              sm={!currentAuth ? 6 : 12}
              md={!currentAuth ? 7 : 12}
              lg={!currentAuth ? 6 : 12}
              className="pe-3 pt-4"
            >
              <Typography
                gutterBottom
                variant="h1"
                color="white"
                sx={{ textShadow: "0px 0px 6px #000" }}
              >
                {props.title && props.title}
              </Typography>
              <Typography
                variant="subtitle1"
                color="white"
                className="mb-3"
                sx={{ textShadow: "0px 0px 6px #000" }}
              >
                {props.description && props.description}
              </Typography>
            </Grid>
            {!currentAuth && (
              <Grid item xs={12} sm={6} md={5} lg={5}>
                <Card className="p-3">
                  <AuthSignIn contentOnly />
                </Card>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Hero;
