// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
// -------------------------------------------------
// Component elements
import TopDrawer from "../App_TopDrawer";
import VolunteersEditCurrentEnrollment from "./Volunteers_EditCurrentEnrollment";
// -------------------------------------------------
// Redux
import { studiesSelectors } from "../../redux/reducers/studies";
import { toggleSecDrawer } from "../../redux/reducers/ui";
import { supervisionSelectors } from "../../redux/reducers/supervision";
import { consumersSelectors } from "../../redux/reducers/consumers";
// -------------------------------------------------
// Basic elements
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
// -------------------------------------------------
// Icons
import EditIcon from "@mui/icons-material/Edit";
import Save from "@mui/icons-material/Save";
// =================================================
// FUNCTIONAL COMPONENT
// -----------------------------------------------
const VolunteersContentCurrentEnrollment = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "volunteers.Volunteers_ContentCurrentEnrollment",
  });
  // ===============================================
  // VARIABLES
  // -------------------------------------------------
  // Router
  const navigate = useNavigate();
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const secDrawerIsOpen = useSelector((state) => state.ui.secDrawerIsOpen);
  const studyEntities = useSelector((state) =>
    studiesSelectors.selectEntities(state)
  );
  const studiesStatus = useSelector((state) => state.studies.status);
  const consumersStatus = useSelector((state) => state.consumers.status);
  const hasGroupAssignment =
    props.currentConsumer.studyEnrollmentList[0].groupAssignmentList &&
    props.currentConsumer.studyEnrollmentList[0].groupAssignmentList.length >
      0 &&
    props.currentConsumer.studyEnrollmentList[0].groupAssignmentList[0]
      .groupId !== "n.a." &&
    props.currentConsumer.studyEnrollmentList[0].groupAssignmentList[0]
      .groupId !== null;
  const consumerEntities = useSelector((state) =>
    consumersSelectors.selectEntities(state)
  );
  const supervisionList = useSelector((state) =>
    supervisionSelectors.selectAll(state)
  );
  // -----------------------------------------------
  // Local state
  const [obj, setObj] = useState({
    userStudyNumber:
      props.currentConsumer.studyEnrollmentList[0].userStudyNumber,
    groupId: hasGroupAssignment
      ? props.currentConsumer.studyEnrollmentList[0].groupAssignmentList[0]
          .groupId
      : "n.a.",
  });
  // ===============================================
  // FUNCTIONS
  const getSupervisorNameEmail = (userId) => {
    return consumerEntities[userId] && consumerEntities[userId].name
      ? consumerEntities[userId].name
      : consumerEntities[userId]
      ? consumerEntities[userId].email
      : "unknown";
  };
  // -----------------------------------------------
  // Handles setting the participant id and group
  const handleEditCurrentEnrollment = () => {
    dispatch(
      toggleSecDrawer({
        isOpen: true,
        id: "volunteers_content-current-enrollment",
      })
    );
  };
  // -----------------------------------------------
  // Resets local state upon closing of the drawer
  useEffect(() => {
    if (!secDrawerIsOpen) {
      setObj({
        userStudyNumber:
          props.currentConsumer.studyEnrollmentList[0].userStudyNumber,
        groupId: hasGroupAssignment
          ? props.currentConsumer.studyEnrollmentList[0].groupAssignmentList[0]
              .groupId
          : "n.a.",
      });
    }
  }, [props.currentConsumer._id, secDrawerIsOpen]); // eslint-disable-line react-hooks/exhaustive-deps
  // ===============================================
  // RENDER COMPONENT
  // -----------------------------------------------
  return props.currentConsumer ? (
    <>
      {/* ================================================== */}
      {/* TOP DRAWER */}
      <TopDrawer
        id={"volunteers_content-current-enrollment"}
        title={t("Current enrollment")}
        buttons={
          <Button
            disabled={consumersStatus === "loading"}
            color="inherit"
            startIcon={<Save />}
            className="m-2"
            onClick={() =>
              props.handleMutateCurrentConsumer("current-enrollment", obj)
            }
          >
            {consumersStatus === "loading" ? (
              <CircularProgress size="1.5rem" className="text-light" />
            ) : (
              t("Save")
            )}
          </Button>
        }
      >
        <VolunteersEditCurrentEnrollment
          obj={obj}
          setObj={setObj}
          groupHistory={
            props.currentConsumer.studyEnrollmentList[0].groupAssignmentList
          }
          groupList={
            studyEntities[props.currentConsumer.studyEnrollmentList[0].studyId]
              ? studyEntities[
                  props.currentConsumer.studyEnrollmentList[0].studyId
                ].groupList
              : []
          }
        />
      </TopDrawer>
      {/* SECTION SHOWING CURRENT ENROLLMENT */}
      <Card className="mb-3">
        <List disablePadding className="px-3 pt-2">
          <ListItem
            disablePadding
            secondaryAction={
              <Tooltip arrow title={t("Edit")} placement="top">
                <IconButton
                  disabled={consumersStatus === "loading"}
                  edge="end"
                  onClick={handleEditCurrentEnrollment}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            }
          >
            <ListItemText
              primary={
                <Typography variant="h3">
                  {t("Current study enrollment")}
                </Typography>
              }
            />
          </ListItem>
        </List>
        <List disablePadding className="px-3">
          <ListItem divider>
            <Grid container alignItems="center">
              <Grid item xs={12} sm="auto">
                <ListItemText
                  secondary="STUDY ACRONYM"
                  className="min-width-125px"
                />
              </Grid>
              <Grid item xs={9} sm="auto">
                <Chip
                  label={
                    studiesStatus === "succeeded" &&
                    studyEntities[
                      props.currentConsumer.studyEnrollmentList[0].studyId
                    ] &&
                    studyEntities[
                      props.currentConsumer.studyEnrollmentList[0].studyId
                    ].acronym
                  }
                  size="small"
                  className="me-2"
                  onClick={() =>
                    navigate(
                      `/studies?studyId=${props.currentConsumer.studyEnrollmentList[0].studyId}`,
                      {
                        replace: false,
                      }
                    )
                  }
                />
              </Grid>
            </Grid>
          </ListItem>
          <ListItem divider>
            <Grid container alignItems="center">
              <Grid item xs={12} sm="auto">
                <ListItemText
                  secondary="SUPERVISED BY"
                  className="min-width-125px"
                />
              </Grid>
              <Grid item xs={9} sm="auto">
                {supervisionList
                  .filter(
                    (supervision) =>
                      supervision.studyId ===
                      props.currentConsumer.studyEnrollmentList[0].studyId
                  )
                  .filter(
                    (supervision) =>
                      supervision.isAllParticipants ||
                      supervision.userIdList.some(
                        (uid) => uid === props.currentConsumer._id
                      )
                  )
                  .map((supervision) => (
                    <Tooltip
                      key={supervision._id}
                      arrow
                      title={
                        supervision.isAllParticipants
                          ? "supervises all participants"
                          : "supervises a subset of participants"
                      }
                      placement="top"
                    >
                      <Chip
                        label={getSupervisorNameEmail(supervision.userId)}
                        size="small"
                        className="me-2"
                        onClick={() =>
                          navigate(
                            `/investigators?consumerId=${supervision.userId}`,
                            {
                              replace: false,
                            }
                          )
                        }
                      />
                    </Tooltip>
                  ))}
              </Grid>
            </Grid>
          </ListItem>
          <ListItem divider>
            <Grid container alignItems="center">
              <Grid item xs={12} sm="auto">
                <ListItemText
                  secondary="ENROLLED"
                  className="min-width-125px"
                />
              </Grid>
              <Grid item xs={9} sm="auto">
                {DateTime.fromISO(
                  props.currentConsumer.studyEnrollmentList[0].enrollmentDate
                ).toRelativeCalendar()}
                <Typography
                  paragraph={false}
                  variant="caption"
                  color="GrayText"
                  className="ps-3"
                >
                  {DateTime.fromISO(
                    props.currentConsumer.studyEnrollmentList[0].enrollmentDate
                  ).toFormat("ccc d LLL yyyy")}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem divider>
            <Grid container alignItems="center">
              <Grid item xs={12} sm="auto">
                <ListItemText
                  secondary="PARTICIPANT ID"
                  className="min-width-125px"
                />
              </Grid>
              <Grid item xs={9} sm="auto">
                {props.currentConsumer.studyEnrollmentList[0].userStudyNumber}
              </Grid>
            </Grid>
          </ListItem>
          <ListItem>
            <Grid container alignItems="center">
              <Grid item xs={12} sm="auto">
                <ListItemText secondary="GROUP" className="min-width-125px" />
              </Grid>
              <Grid item xs={9} sm="auto">
                {studiesStatus === "succeeded" && hasGroupAssignment
                  ? studyEntities[
                      props.currentConsumer.studyEnrollmentList[0].studyId
                    ].groupList.find(
                      (group) =>
                        group._id ===
                        props.currentConsumer.studyEnrollmentList[0]
                          .groupAssignmentList[0].groupId
                    ).label
                  : "Not assigned"}
                {hasGroupAssignment && (
                  <Typography paragraph variant="caption" color="textSecondary">
                    {t("Assigned on {{ date }}", {
                      date: props.currentConsumer.studyEnrollmentList[0]
                        .groupAssignmentList[0].assignmentDate,
                    })}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </Card>
    </>
  ) : null;
};
// =================================================
// EXPORT COMPONENT
export default VolunteersContentCurrentEnrollment;
