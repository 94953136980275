// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
// -------------------------------------------------
// Redux
import { consumersSelectors } from "../../redux/reducers/consumers";
// -------------------------------------------------
// Component elements
import TopDrawer from "../App_TopDrawer";
// -------------------------------------------------
// Basic elements
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
// -------------------------------------------------
// Icons
import Save from "@mui/icons-material/Save";
// =================================================
// FUNCTIONAL COMPONENT
const InvestigatorsInvite = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "investigators.Investigators_Invite",
  });
  // =================================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const isXS = useSelector((state) => state.ui.isXS);
  const consumersStatus = useSelector((state) => state.consumers.status);
  const consumerList = useSelector((state) =>
    consumersSelectors.selectAll(state)
  );
  // -----------------------------------------------
  // Local State
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  // -----------------------------------------------
  // Data table
  const columnList = [
    { field: "userNumber", headerName: t("PID"), width: 75, type: "number" },
    { field: "email", headerName: t("Email"), width: 250 },
    {
      field: "createdAt",
      headerName: t("Created on"),
      width: 100,
      type: "dateTime",
      valueFormatter: (value) =>
        DateTime.fromISO(value).toFormat("dd LLL yyyy"),
    },
    {
      field: "lastSignInOn",
      headerName: t("Last sign-in"),
      width: 100,
      type: "dateTime",
      valueFormatter: (value) =>
        DateTime.fromISO(value).toFormat("dd LLL yyyy"),
    },
  ];
  // =================================================
  // FUNCTIONS
  // -----------------------------------------------
  // Parses the RegEx
  const escapeRegExp = (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  };
  // -----------------------------------------------
  // Sets the search text as a RegEx
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = rows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };
  // -----------------------------------------------
  // Adds a role document to the DB
  const handleAddInvestigator = async () => {
    selectionModel.forEach((consumerId) => {
      props.handlePatchCurrentConsumer(consumerId, "admin");
    });
  };
  // -----------------------------------------------
  useEffect(() => {
    setRows(
      consumerList
        .filter((consumer) => consumer.deletedOn === null)
        .filter((consumer) => consumer.primaryRole === "participant")
        .map((consumer) => {
          return {
            id: consumer._id,
            userNumber: consumer.userNumber,
            email: consumer.email,
            createdAt: consumer.createdAt,
            lastSignInOn: consumer.lastSignInOn,
          };
        })
    );
    return () => {
      setSearchText("");
      setRows([]);
      setSelectionModel([]);
    };
  }, [consumersStatus]); // eslint-disable-line react-hooks/exhaustive-deps
  // =================================================
  // SUB COMPONENTS
  // -----------------------------------------------
  const QuickSearchToolbar = () => {
    return (
      <GridToolbarContainer className="m-2">
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <TopDrawer
      id="invite-roles"
      title={t("Add investigator")}
      buttons={
        <Button
          disabled={consumersStatus === "loading"}
          color="inherit"
          startIcon={<Save />}
          className="m-2"
          onClick={handleAddInvestigator}
        >
          {consumersStatus === "loading" ? (
            <CircularProgress size="1.5rem" className="text-light" />
          ) : (
            t("Save")
          )}
        </Button>
      }
    >
      <Grid container direction="column" className="h-100">
        <Grid item>
          <Paper className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-92vw"}>
            <Typography variant="h4" className="mb-2">
              {t("New investigators")}
            </Typography>
            {selectionModel.map((consumerId) => (
              <Chip
                key={consumerId}
                label={
                  consumerList.find((consumer) => consumer._id === consumerId)
                    .email
                }
                size="small"
                className="me-2"
              />
            ))}
          </Paper>
        </Grid>
        <Grid item className="flex-grow-1 p-2 pt-0">
          <DataGrid
            checkboxSelection
            rows={rows}
            columns={columnList}
            rowSelectionModel={selectionModel}
            components={{
              Toolbar: QuickSearchToolbar,
            }}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (e) => requestSearch(e.target.value),
                clearSearch: () => requestSearch(""),
              },
            }}
            className="bg-white h-100"
            onRowSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
          />
        </Grid>
      </Grid>
    </TopDrawer>
  );
};
// =================================================
// EXPORT COMPONENT
export default InvestigatorsInvite;
