// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useMemo } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
// -------------------------------------------------
// Basic elements
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
// -------------------------------------------------
dayjs.extend(customParseFormat);
// =================================================
// FUNCTIONAL COMPONENT
const SurveyVersionMenuList = ({
  surveyEntities,
  studyList,
  selectedSurveyId,
  onSurveyIdChange,
}) => {
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  const selectedSurvey = surveyEntities[selectedSurveyId];
  const selectedParentId = selectedSurvey?.parentId || "";

  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Compute the latest version of each parent survey
  const parentSurveys = useMemo(() => {
    const latestSurveysByParentId = {};

    Object.values(surveyEntities)
      .sort((a, b) => (a.name.official > b.name.official ? 1 : -1))
      .filter((survey) => survey.folder !== "archive")
      .forEach((survey) => {
        const { parentId, version } = survey;
        if (
          !latestSurveysByParentId[parentId] ||
          version > latestSurveysByParentId[parentId].version
        ) {
          latestSurveysByParentId[parentId] = survey;
        }
      });

    return Object.values(latestSurveysByParentId);
  }, [surveyEntities]);

  // Group parent surveys by folder
  const surveysGroupedByFolder = useMemo(() => {
    return parentSurveys.reduce((groups, survey) => {
      const { folder } = survey;
      if (!groups[folder]) {
        groups[folder] = [];
      }
      groups[folder].push(survey);
      return groups;
    }, {});
  }, [parentSurveys]);

  // Create menu items for parent surveys
  const parentSurveyMenuItems = useMemo(() => {
    return Object.entries(surveysGroupedByFolder).flatMap(
      ([folder, surveys]) => {
        const subheader =
          folder === "standardized" ? (
            <ListSubheader key={`folder-${folder}`}>
              Standardized Surveys
            </ListSubheader>
          ) : (
            <ListSubheader key={`folder-${folder}`}>
              {studyList.find((study) => study._id === folder)?.name ||
                "Unknown Study"}
            </ListSubheader>
          );

        const menuItems = surveys.map((survey) => (
          <MenuItem
            key={survey.parentId}
            value={survey.parentId}
            className="ps-5"
          >
            {survey.name.official}
          </MenuItem>
        ));

        return [subheader, ...menuItems];
      },
    );
  }, [surveysGroupedByFolder, studyList]);

  // Get all versions of the selected parent survey
  const surveyVersions = useMemo(() => {
    return Object.values(surveyEntities)
      .filter(
        (survey) =>
          survey.folder !== "archive" && survey.parentId === selectedParentId,
      )
      .sort((a, b) => b.version - a.version);
  }, [surveyEntities, selectedParentId]);

  // Create menu items for survey versions
  const surveyVersionMenuItems = useMemo(() => {
    return surveyVersions.map((survey) => (
      <MenuItem key={survey._id} value={survey._id} className="ps-5">
        Version {survey.version} ({dayjs(survey.updatedAt).format("DD-MM-YYYY")}
        )
      </MenuItem>
    ));
  }, [surveyVersions]);

  // Handlers
  const handleParentSurveyChange = (e) => {
    const selectedParentId = e.target.value;
    const latestSurvey = parentSurveys.find(
      (survey) => survey.parentId === selectedParentId,
    );
    if (latestSurvey) {
      onSurveyIdChange(latestSurvey._id);
    }
  };

  const handleSurveyVersionChange = (e) => {
    onSurveyIdChange(e.target.value);
  };

  return (
    <>
      <Select
        fullWidth
        hiddenLabel
        size="small"
        variant="filled"
        value={selectedParentId}
        color="secondary"
        className="mb-2"
        onChange={handleParentSurveyChange}
      >
        {parentSurveyMenuItems}
      </Select>

      <Select
        fullWidth
        hiddenLabel
        size="small"
        variant="filled"
        value={selectedSurveyId || ""}
        color="secondary"
        className="mb-2"
        onChange={handleSurveyVersionChange}
      >
        {surveyVersionMenuItems}
      </Select>
    </>
  );
};

export default SurveyVersionMenuList;
