// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, Navigate, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
// -------------------------------------------------
// Authentication API functions
import auth from "../api-routes/auth";
// -------------------------------------------------
// Contexts
import { useAuth } from "../contexts/auth";
import { useSocket } from "../contexts/socket";
// -------------------------------------------------
// Redux
import { setAlert } from "../redux/reducers/ui";
import { patchCurrentUser } from "../redux/reducers/user";
// -------------------------------------------------
// Component elements
import MainCard from "../components/App_MainCard";
// -------------------------------------------------
// Basic elements
import {
  Alert,
  Button,
  Chip,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";

// -------------------------------------------------
// Icons
import AccountCircle from "@mui/icons-material/AccountCircle";
// =================================================
// FUNCTIONAL COMPONENT
const AuthUserManagement = () => {
  const { t } = useTranslation("pages", { keyPrefix: "AuthUserManagement" });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const currentUserId = useSelector(
    (state) => state.user.currentUser && state.user.currentUser._id
  );
  // -----------------------------------------------
  // Get the URL location
  const location = useLocation();
  const navigate = useNavigate();
  // -----------------------------------------------
  // Context state
  const { authStatus, currentAuth, verifiedAuth, reloadCurrentAuth } =
    useAuth();
  const { socket } = useSocket();
  // -----------------------------------------------
  // Local state
  let timeoutId;
  const [waitingOn, setWaitingOn] = useState("All cogwheels are turning.");
  const [isLoading, setIsLoading] = useState(false);
  const [warning, setWarning] = useState(null);
  const [error, setError] = useState(null);
  const [explainError, setExplainError] = useState(null);
  const [actionMode, setActionMode] = useState(null);
  const [actionCode, setActionCode] = useState(null);
  const [continueURL, setContinueURL] = useState(null);
  const [redirectURL, setRedirectURL] = useState(null);
  const [currentEmail, setCurrentEmail] = useState(null);
  const [newEmail, setNewEmail] = useState(null);
  const [currentPassword, setCurrentPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [timer, setTimer] = useState(60);
  // ===============================================
  // Functions
  // -----------------------------------------------
  // Executes when the component loads, when location changes or when user is loaded
  useEffect(() => {
    // If the user is still being loaded, return and wait
    if (authStatus === "loading") {
      return;
    }
    // Get the parameters from URI
    const searchParams = new URLSearchParams(location.search);
    const mode = searchParams.get("mode");
    const code = searchParams.get("oobCode");
    const url = searchParams.get("continueURL");
    // Set local state
    setRedirectURL(null);
    setActionMode(mode);
    setActionCode(code);
    setContinueURL(url);
    setWaitingOn(t("All cogwheels are turning."));
    setWarning(null);
    setError(null);
    setExplainError(null);
    // Check which mode we're in, and call the appropriate function
    switch (mode) {
      case "askToReauthenticate":
        isCurrentValidatedUser();
        break;
      case "sendResetPasswordLink":
        break;
      case "hasSendResetPasswordLink":
        break;
      case "resetPassword":
        handleGetEmailFromPasswordResetCode(code);
        break;
      case "updatePassword":
        isCurrentValidatedUser();
        break;
      case "updateEmail":
        break;
      case "hasSendUpdateEmailLink":
        break;
      case "verifyAndChangeEmail":
        handleVerifyEmail(code);
        break;
      case "recoverEmail":
        handleRecoverEmail(code, url);
        break;
      case "sendVerifyEmailLink":
        handleSendVerifyEmailLink();
        break;
      case "verifyEmail":
        handleVerifyEmail(code);
        break;
      case "confirmToRemoveAccount":
        break;
      case "hasRemovedAccount":
        break;
      default:
        setError(t("We don't know what to do, there is no expected action."));
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [location.search, authStatus]); // eslint-disable-line react-hooks/exhaustive-deps
  // -----------------------------------------------
  // Check that there is a current and validated user
  const isCurrentValidatedUser = () => {
    if (!currentAuth) {
      setError(t("Please sign in first."));
      setExplainError(
        t(
          "We can't verify or change your account details if you're not signed in."
        )
      );
      return false;
    }

    if (!currentAuth.emailVerified) {
      setError(t("Your account is not verified yet."));
      setExplainError(
        t(
          "For security reasons, we need to check that the registered email address is actually yours. Please sign out, sign in again, and follow the instructions."
        )
      );
      return false;
    }

    setError(null);
    setExplainError(null);
    return true;
  };
  // -----------------------------------------------
  // Reauthenticates a verified user and continues to the next URL
  const handleReauthentication = async (e) => {
    e.preventDefault();
    if (!currentPassword) {
      setWarning(
        t("First verify it's you by typing your current password below.")
      );
      return;
    }
    // Check if there is a valid user
    if (!isCurrentValidatedUser()) {
      return;
    }
    // Indicate we're loading
    setIsLoading(true);
    setWaitingOn(t("Trying to reauthenticate you."));
    // Try to reauthenticate and redirect
    try {
      const credential = await auth.getCredential(
        currentAuth.email,
        currentPassword
      );
      await auth.reauthenticateWithCredential(currentAuth, credential);
      setWarning(null);
      setIsLoading(false);
      setWaitingOn(null);
      // Redirect if all went well
      setRedirectURL(continueURL);
    } catch (error) {
      setWarning(auth.parseErrorMessage(error));
      setIsLoading(false);
      setWaitingOn(null);
    }
  };
  // -----------------------------------------------
  // Sends a link to the user's email address to reset password
  const handleSendResetPasswordLink = async (e) => {
    e.preventDefault();
    // Indicate we're loading
    setIsLoading(true);
    setWaitingOn(t("Trying to send the password reset email."));
    // Extract email address
    let email;
    if (currentAuth) {
      email = currentAuth.email; // Get email from current signed in user
    } else {
      email = currentEmail; // Get email from the user input
    }
    // Try to send the link to 'email'
    try {
      await auth.sendPasswordResetEmail(email);
      setWarning(null);
      setIsLoading(false);
      setWaitingOn(null);
      setRedirectURL("/user-management?mode=hasSendResetPasswordLink");
    } catch (error) {
      setWarning(auth.parseErrorMessage(error));
      setIsLoading(false);
      setWaitingOn(null);
    }
  };
  // -----------------------------------------------
  // Checks the reset password code and sets currentEmail local state
  const handleGetEmailFromPasswordResetCode = async (code) => {
    // Indicate we're loading
    setIsLoading(true);
    setWaitingOn(t("Checking the validity of the link."));
    try {
      const email = await auth.verifyPasswordResetCode(code);
      setCurrentEmail(email);
      setError(null);
      setExplainError(null);
    } catch (error) {
      setError(auth.parseErrorMessage(error));
      setExplainError(
        t(
          "We tried to validate the link that was sent to your email address, but this has failed."
        )
      );
    }
    // Indicate we're done
    setIsLoading(false);
    setWaitingOn(null);
  };
  // -----------------------------------------------
  // Applies the action code to reset password
  const handleApplyResetPasswordLink = async (e) => {
    e.preventDefault();
    // Prevent this function if there is no action code
    if (!actionCode) {
      return;
    }
    const url = "/account?feedback=Successfully%20updated%20your%20password.";
    setIsLoading(true);
    setWaitingOn(t("Trying to change your password."));
    // Apply the action code and sign in
    try {
      await auth.confirmPasswordReset(actionCode, newPassword);
      // Reset action code so it can't be used again
      setActionCode(null);
      setIsLoading(false);
      setWaitingOn(null);
      // Reauthenticate
      if (currentAuth) {
        setRedirectURL(url);
        const credential = await auth.getCredential(
          currentAuth.email,
          newPassword
        );
        await auth.reauthenticateWithCredential(currentAuth, credential);
      } else {
        await auth.signIn(currentEmail, newPassword);
        navigate(url);
      }
    } catch (error) {
      setWarning(auth.parseErrorMessage(error));
      setIsLoading(false);
      setWaitingOn(null);
    }
  };
  // -----------------------------------------------
  // Directly updates the password of a re-authenticated user
  const handleUpdatePassword = async (e, password) => {
    e.preventDefault();
    // Check if there is a valid user
    if (!isCurrentValidatedUser()) {
      return;
    }
    // Indicate we're loading
    setIsLoading(true);
    setWaitingOn(t("Trying to change your password."));
    // Update the password of current user
    try {
      await auth.updatePassword(currentAuth, password);
      // Inform the user
      await dispatch(
        setAlert({
          type: "snackbar",
          variant: "success",
          message: t("Successfully updated your password."),
          duration: 10000,
        })
      );
      // Redirect to account page
      setWarning(null);
      setIsLoading(false);
      setRedirectURL("/account");
    } catch (error) {
      setWarning(auth.parseErrorMessage(error));
      setIsLoading(false);
      setWaitingOn(null);
    }
  };
  // -----------------------------------------------
  // Sends a link to the new email address, when clicked, updates the email address
  const handleSendUpdateEmailLink = async (e, email) => {
    e.preventDefault();
    // Check if there is a valid user
    if (!isCurrentValidatedUser()) {
      return;
    }
    // Check that the new email address is not the same as the current one (not explicily tested in Firebase)
    if (email && email.toLowerCase() === currentAuth.email.toLowerCase()) {
      setWarning(
        t("The provided email address is already assigned to your account.")
      );
      return;
    }
    // Indicate we're loading
    setIsLoading(true);
    setWaitingOn(t("Trying to send the update-email-address message."));
    try {
      await auth.sendEmailUpdateEmail(currentAuth, email);
      setWarning(null);
      setIsLoading(false);
      setRedirectURL("/user-management?mode=hasSendUpdateEmailLink");
    } catch (error) {
      setIsLoading(false);
      setWaitingOn(null);
      setWarning(auth.parseErrorMessage(error));
    }
  };
  // -----------------------------------------------
  // Recovers the previous email of a user
  const handleRecoverEmail = async (code) => {
    // Indicate we're loading
    setIsLoading(true);
    setWaitingOn(t("Trying to recover your email address."));
    // Try to apply the recover-email code
    try {
      // Check to whom this action code belongs
      const actionCodeInfo = await auth.checkActionCode(code);
      // If there is a current user, then first sign-out this user
      // This will invoke a re-render, and this function is called again with 'currentAuth' set to 'null'
      if (currentAuth) {
        await auth.signOut();
        return;
      }
      //If there is no user signed in, then 'authStatus' must be 'succeeded' indicating that the sign-out has completed:
      if (!currentAuth && authStatus === "succeeded") {
        // Go on to restore to the old email
        await auth.recoverEmail(code); // This will reset Firebase
        // Success! Otherwise, the catch statement would fire.
        setCurrentEmail(actionCodeInfo.data.email);
        // The page will tell the user that the email is restored and asks to sign-in
        setIsLoading(false);
        setWaitingOn(null);
      }
    } catch (error) {
      setError(auth.parseErrorMessage(error));
      setExplainError(
        t(
          "We tried to restore your email address with the link that was sent to you, but this has failed."
        )
      );
      setIsLoading(false);
      setWaitingOn(null);
    }
  };
  // -----------------------------------------------
  // Sends a link to the user's email address to verify email address
  const handleSendVerifyEmailLink = async () => {
    // Check if there is a valid user
    if (!currentAuth) {
      setError(t("Please sign in first."));
      setExplainError(
        t("We can't verify your account details if you're not signed in.")
      );
      return;
    }
    // If the user is already verified, return. The user will be shown a messsage that all is ok.
    if (currentAuth.emailVerified) {
      return;
    }
    // Indicate we're loading
    setIsLoading(true);
    setWaitingOn(
      t("Trying to send an email with a link to verify your email address.")
    );
    // Try to send the verification link
    try {
      await auth.sendEmailVerification(currentAuth);
    } catch (error) {
      setError(auth.parseErrorMessage(error));
      setExplainError(
        t(
          "We tried sending an email with a link to verify your email address, but this has failed. Please try again later."
        )
      );
    }
    // Indicate we're done
    setIsLoading(false);
    setWaitingOn(null);
    // Set a timer to release the "resent email" button
    handleSetTimeout(60);
  };
  // Runs a timer of 60 seconds before a button is enabled
  const handleSetTimeout = (start) => {
    let currentTimer;
    setTimer((timer) => {
      currentTimer = start ? start - 1 : timer - 1;
      return currentTimer;
    });
    // Set next reload
    if (currentTimer > 0) {
      timeoutId = setTimeout(handleSetTimeout, 1000);
    } else {
      clearTimeout(timeoutId);
    }
  };
  // -----------------------------------------------
  // Verifies the (new) email address of a user
  const handleVerifyEmail = async (code) => {
    // Indicate we're loading
    setIsLoading(true);
    setWaitingOn(t("Trying to verify your email address."));
    // Try to apply the email verification code
    try {
      // Check to whom this action code belongs
      const actionCodeInfo = await auth.checkActionCode(code);
      // If there is a current user, but this user is not the owner of the action-code, sign-out.
      // This will invoke a re-render, and this function is called again with 'currentAuth' set to 'null'
      if (currentAuth && actionCodeInfo.data.email !== currentAuth.email) {
        await auth.signOut();
        return;
      }
      // If there is an authorized user, and this user is the owner of the action code:
      if (currentAuth && actionCodeInfo.data.email === currentAuth.email) {
        // Go on to verify the email
        await auth.verifyEmail(code);
        // Success! Otherwise, the catch statement would fire.
        // Reload the user and redirect to Permissions page
        setIsLoading(false);
        setWaitingOn(null);
        setRedirectURL("/permissions");
        await reloadCurrentAuth(currentAuth);
      } else if (!currentAuth && authStatus === "succeeded") {
        // Otherwise: if there is no user signed in, then 'authStatus' must be 'succeeded' indicating that the sign-out has completed:
        // Go on to verify the email
        await auth.verifyEmail(code);
        // Success! Otherwise, the catch statement would fire.
        // Tell the user that the email is verified and redirect to sign-in
        await dispatch(
          setAlert({
            type: "snackbar",
            variant: "success",
            message: t("Successfully verified your account. Please sign-in."),
            duration: 10000,
          })
        );
        setIsLoading(false);
        setWaitingOn(null);
        setRedirectURL("/sign-in");
      }
    } catch (error) {
      setError(auth.parseErrorMessage(error));
      setExplainError(
        t(
          "We tried to verify your email address with the link that was sent to you, but this has failed."
        )
      );
      setIsLoading(false);
      setWaitingOn(null);
    }
  };
  // -----------------------------------------------
  // Removes a user from the auth-dataset and users-dataset
  const handleRemoveAccount = async () => {
    // Check if there is a valid user
    if (!currentAuth) {
      setError(t("Please sign in first."));
      setExplainError(
        t("We can't delete your account details if you're not signed in.")
      );
      return;
    }
    setIsLoading(true);
    setWaitingOn(t("Removing your account."));
    // Try removing the user (MongoDB) and authentication (Firebase)
    try {
      // Initialize errors to null
      setError(null);
      setExplainError(null);
      // If auth is verified, we can change the user in MongoDB
      if (currentAuth.emailVerified) {
        await dispatch(
          patchCurrentUser({
            socket,
            requestingUser: currentAuth,
            body: {
              data: {
                _id: currentUserId,
                consent: null,
                email: "deleted",
              },
            },
          })
        );
      }
      // Ok user has updated on Mongo, set the redirect url and remove the authentication from Firebase
      setRedirectURL("/user-management?mode=hasRemovedAccount");
      setIsLoading(false);
      setWaitingOn(null);
      // Deletes account from Firebase (last action becuase 'authStatus' will change which invokes a useEffect-rerender)
      await auth.deleteUser(currentAuth);
      // Can't set any other local state changes, as the component will be rerendered directly
    } catch (error) {
      setIsLoading(false);
      setWaitingOn(null);
      setError(auth.parseErrorMessage(error));
      setExplainError(
        t("We tried to delete your account, but this has failed.")
      );
    }
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <MainCard hasAction={verifiedAuth} isLoading={isLoading}>
      <Helmet>
        <title>
          {process.env.REACT_APP_TITLE} | {t("Loading, please wait...")}
        </title>
      </Helmet>
      {isLoading || authStatus === "loading" ? (
        // -----------------------------------------------
        // LOADING
        <React.Fragment>
          <Typography variant="h1" className="mb-2">
            {t("Please wait...")}
          </Typography>
          <Typography variant="h3" color="textSecondary">
            {waitingOn}
          </Typography>
        </React.Fragment>
      ) : error ? (
        // -----------------------------------------------
        // ERROR
        <Grid container alignItems="center" justifyContent="center">
          <Helmet>
            <title>
              {process.env.REACT_APP_TITLE} | {t("Oh no! An error.")}
            </title>
          </Helmet>
          <Grid item xs={9}>
            <img
              alt="Sorry, something's gone wrong"
              src="images/icon-stranded.png"
              className="w-100"
            />
          </Grid>
          <Grid item xs={12} className="mb-3 text-center">
            <Typography variant="h2">
              {t("Sorry, we've left you stranded")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography paragraph>{explainError}</Typography>
            <Alert severity="error" className="align-items-center my-3">
              {error}
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              type="button"
              variant="contained"
              color="primary"
              component={NavLink}
              to={verifiedAuth ? "/account" : !currentAuth ? "/sign-in" : "/"}
            >
              {verifiedAuth
                ? t("Back to Account")
                : !currentAuth
                ? t("Sign In")
                : t("Back to Start")}
            </Button>
          </Grid>
        </Grid>
      ) : redirectURL ? (
        <Navigate to={redirectURL} />
      ) : actionMode === "askToReauthenticate" ? (
        // -----------------------------------------------
        // REAUTHENTICATE A USER
        <React.Fragment>
          <Helmet>
            <title>
              {process.env.REACT_APP_TITLE} | {t("Verify account")}
            </title>
          </Helmet>
          <Typography variant="h1" className="mb-4">
            {t("To continue, verify that it's you")}
          </Typography>
          <Chip
            variant="outlined"
            label={currentAuth.email}
            icon={<AccountCircle />}
            className="p-auto"
          />
          {warning && (
            <Alert severity="warning" className="align-items-center mt-3">
              {warning}
            </Alert>
          )}
          <form noValidate onSubmit={handleReauthentication}>
            <TextField
              required
              fullWidth
              id="current-password"
              type="password"
              name="current-password"
              label={t("Enter your current password")}
              variant="filled"
              color="secondary"
              margin="normal"
              autoComplete="current-password"
              onChange={(e) => {
                setCurrentPassword(e.target.value);
              }}
            />
            <Button fullWidth variant="contained" color="primary" type="submit">
              {t("Next")}
            </Button>
          </form>
          <Grid item xs={12} className="pt-3">
            <NavLink
              to="/user-management?mode=sendResetPasswordLink"
              className="text-secondary text-decoration-none"
            >
              {t("Forgot password?")}
            </NavLink>
          </Grid>
        </React.Fragment>
      ) : actionMode === "sendResetPasswordLink" ? (
        // -----------------------------------------------
        // SEND RESET PASSWORD LINK TO?
        <React.Fragment>
          <Helmet>
            <title>
              {process.env.REACT_APP_TITLE} | {t("Reset password")}
            </title>
          </Helmet>
          <Typography className="mb-2" variant="h1">
            {t("Reset your password")}
          </Typography>
          <Typography className="mb-3" variant="h3" color="textSecondary">
            {t("We'll send an email with a link to reset your password")}
          </Typography>
          {warning && (
            <Alert severity="warning" className="align-items-center mb-3">
              {warning}
            </Alert>
          )}
          <form noValidate onSubmit={handleSendResetPasswordLink}>
            {!currentAuth ? (
              <TextField
                required
                fullWidth
                id="email"
                type="email"
                name="email"
                label={t("Email Address")}
                value={currentEmail ? currentEmail : ""}
                variant="filled"
                color="secondary"
                autoComplete="username"
                className="mb-3"
                onChange={(e) => {
                  setCurrentEmail(e.target.value);
                }}
              />
            ) : (
              <Chip
                variant="outlined"
                label={currentAuth.email}
                icon={<AccountCircle />}
                className="p-auto mb-3"
              />
            )}
            <Button
              fullWidth
              disabled={isLoading}
              type="submit"
              variant="contained"
              color="primary"
            >
              {t("Send Password Reset Link")}
            </Button>
          </form>
          {currentAuth && (
            <Button
              fullWidth
              type="button"
              variant="outlined"
              color="inherit"
              className="mt-3"
              onClick={async () => {
                await auth.signOut();
              }}
            >
              <Typography className="text-secondary text-decoration-none">
                {t("Use Another Account")}
              </Typography>
            </Button>
          )}
        </React.Fragment>
      ) : actionMode === "hasSendResetPasswordLink" ? (
        // -----------------------------------------------
        // HAS SUCCESSFULLY SEND RESET PASSWORD LINK
        <React.Fragment>
          <Helmet>
            <title>
              {process.env.REACT_APP_TITLE} | {t("Reset password")}
            </title>
          </Helmet>
          <Typography variant="h1" className="mb-2">
            {t("Reset your password")}
          </Typography>
          <Typography variant="h3" color="textSecondary" className="mb-3">
            {t("We've sent an email with a link to reset your password")}
          </Typography>
          {currentAuth && (
            <Chip
              variant="outlined"
              label={currentAuth.email}
              icon={<AccountCircle />}
              className="p-auto mb-3"
            />
          )}
          <Typography paragraph>
            {t(
              "Please check your inbox for a message from the SLEEPREGISTRY, and follow the instructions.",
              { SLEEPREGISTRY: process.env.REACT_APP_TITLE }
            )}
          </Typography>
          <Typography paragraph>
            {t(
              "Did you not receive any email? Try your spam folder or try again."
            )}
          </Typography>
          <NavLink
            to="/user-management?mode=sendResetPasswordLink"
            className="text-secondary text-decoration-none"
          >
            <Button fullWidth type="button" variant="outlined" color="inherit">
              {t("Try Again")}
            </Button>
          </NavLink>
        </React.Fragment>
      ) : actionMode === "resetPassword" ? (
        // -----------------------------------------------
        // ASK FOR NEW PASSWORD AND SET IT
        <React.Fragment>
          <Helmet>
            <title>
              {process.env.REACT_APP_TITLE} | {t("Reset password")}
            </title>
          </Helmet>
          <Typography variant="h1" className="mb-2">
            {t("Reset your password")}
          </Typography>
          <Typography variant="h3" color="textSecondary" className="mb-3">
            {t("Please type in a new secure password")}
          </Typography>
          <Chip
            variant="outlined"
            label={currentEmail}
            icon={<AccountCircle />}
            className="p-auto mb-3"
          />
          {warning && (
            <Alert severity="warning" className="align-items-center mb-3">
              {warning}
            </Alert>
          )}
          <form noValidate onSubmit={handleApplyResetPasswordLink}>
            <TextField
              required
              fullWidth
              id="new-password"
              type="password"
              name="new-password"
              label={t("New Password")}
              variant="filled"
              color="secondary"
              autoComplete="new-password"
              className="mb-3"
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
            <Button
              fullWidth
              disabled={isLoading}
              type="submit"
              variant="contained"
              color="primary"
              className="mb-3"
            >
              {t("Save New Password")}
            </Button>
          </form>
          <NavLink
            to="/account"
            className="text-secondary text-decoration-none"
          >
            <Button fullWidth type="button" variant="outlined" color="inherit">
              {t("Cancel")}
            </Button>
          </NavLink>
        </React.Fragment>
      ) : actionMode === "updatePassword" ? (
        // -----------------------------------------------
        // ASK FOR NEW PASSWORD AND SET IT
        <React.Fragment>
          <Helmet>
            <title>
              {process.env.REACT_APP_TITLE} | {t("Update password")}
            </title>
          </Helmet>
          <Typography variant="h1" className="mb-2">
            {t("Update your password")}
          </Typography>
          <Typography variant="h3" color="textSecondary" className="mb-3">
            {t("Please type in a new secure password")}
          </Typography>
          <Chip
            variant="outlined"
            label={currentAuth.email}
            icon={<AccountCircle />}
            className="p-auto mb-3"
          />
          {warning && (
            <Alert severity="warning" className="align-items-center mb-3">
              {warning}
            </Alert>
          )}
          <form
            noValidate
            onSubmit={(e) => {
              handleUpdatePassword(e, newPassword);
            }}
          >
            <TextField
              required
              fullWidth
              id="new-password"
              type="password"
              name="new-password"
              label={t("New Password")}
              variant="filled"
              color="secondary"
              autoComplete="new-password"
              className="mb-3"
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
            <Button
              fullWidth
              disabled={isLoading}
              type="submit"
              variant="contained"
              color="primary"
              className="mb-3"
            >
              {t("Save New Password")}
            </Button>
          </form>
          <NavLink
            to="/account"
            className="text-secondary text-decoration-none"
          >
            <Button fullWidth type="button" variant="outlined" color="inherit">
              {t("Cancel")}
            </Button>
          </NavLink>
        </React.Fragment>
      ) : actionMode === "updateEmail" ? (
        // -----------------------------------------------
        // ASK FOR NEW EMAIL ADDRESS AND SEND A EMAIL WITH LINK TO VERIFY
        <React.Fragment>
          <Helmet>
            <title>
              {process.env.REACT_APP_TITLE} | {t("Update email address")}
            </title>
          </Helmet>
          <Typography variant="h1" className="mb-2">
            {t("Update your email address")}
          </Typography>
          <Typography variant="h3" color="textSecondary" className="mb-3">
            {t("Please type in your new email address")}
          </Typography>
          {warning && (
            <Alert severity="warning" className="align-items-center mb-3">
              {warning}
            </Alert>
          )}
          <form
            noValidate
            onSubmit={(e) => {
              handleSendUpdateEmailLink(e, newEmail);
            }}
          >
            <TextField
              required
              fullWidth
              key="email"
              id="email"
              type="email"
              name="email"
              value={newEmail ? newEmail : ""}
              label={t("New Email Address")}
              variant="filled"
              color="secondary"
              autoComplete="username"
              className="mb-3"
              onChange={(e) => {
                setNewEmail(e.target.value);
              }}
            />
            <Button
              fullWidth
              disabled={isLoading}
              type="submit"
              variant="contained"
              color="primary"
              className="mb-3"
            >
              {t("Next")}
            </Button>
          </form>
          <NavLink
            to="/account"
            className="text-secondary text-decoration-none"
          >
            <Button fullWidth type="button" variant="outlined" color="inherit">
              {t("Cancel")}
            </Button>
          </NavLink>
        </React.Fragment>
      ) : actionMode === "hasSendUpdateEmailLink" ? (
        // -----------------------------------------------
        // HAS SUCCESSFULLY SEND UPDATE EMAIL ADDRESS LINK
        <React.Fragment>
          <Helmet>
            <title>
              {process.env.REACT_APP_TITLE} | {t("Update email")}
            </title>
          </Helmet>
          <Typography variant="h1" className="mb-2">
            {t("You're almost done")}
          </Typography>
          <Typography variant="h3" color="textSecondary" className="mb-3">
            {t(
              "We've sent an email with a link to update your email address to:"
            )}
          </Typography>
          {currentAuth && (
            <Chip
              variant="outlined"
              label={newEmail}
              icon={<AccountCircle />}
              className="p-auto mb-3"
            />
          )}
          <Typography paragraph>
          {t(
              "Please check your inbox for a message from the SLEEPREGISTRY, and follow the instructions.",
              { SLEEPREGISTRY: process.env.REACT_APP_TITLE }
            )}
          </Typography>
          <Typography paragraph>
            {t(
              "Did you not receive any email? Try your spam folder or try again."
            )}
          </Typography>
          <NavLink
            to="/user-management?mode=updateEmail"
            className="text-secondary text-decoration-none"
          >
            <Button fullWidth type="button" variant="outlined" color="inherit">
              {t("Try Again")}
            </Button>
          </NavLink>
        </React.Fragment>
      ) : actionMode === "recoverEmail" ? (
        // -----------------------------------------------
        // SHOW FEEDBACK INDICATING THE ACCOUNT EMAIL IS RESTORED
        <React.Fragment>
          <Helmet>
            <title>
              {process.env.REACT_APP_TITLE} | {t("Recover email address")}
            </title>
          </Helmet>
          <Typography variant="h1" className="mb-2">
            {t("You email address is recovered")}
          </Typography>
          <Typography variant="h3" color="textSecondary" className="mb-3">
            {t("We've restored your account back to")}
          </Typography>
          <Chip
            variant="outlined"
            label={currentEmail}
            icon={<AccountCircle />}
            className="p-auto mb-3"
          />
          <Typography paragraph>
            {t(
              "Please sign in now. You might want to reset your password in case your account was compromised."
            )}
          </Typography>
          <Button
            fullWidth
            type="button"
            variant="contained"
            color="primary"
            component={NavLink}
            to="/sign-in"
          >
            {t("Sign In")}
          </Button>
        </React.Fragment>
      ) : actionMode === "sendVerifyEmailLink" &&
        currentAuth &&
        !currentAuth.emailVerified ? (
        // -----------------------------------------------
        // SHOW FEEDBACK THAT THE VERIFICATION EMAIL HAS BEEN SEND
        <React.Fragment>
          <Helmet>
            <title>
              {process.env.REACT_APP_TITLE} | {t("Verify account")}
            </title>
          </Helmet>
          <Typography variant="h1" className="mb-2">
            {t("Verify your email address")}
          </Typography>
          <Typography variant="h3" color="textSecondary" className="mb-3">
            {t("We've sent an email to")}
          </Typography>
          <Chip
            variant="outlined"
            label={currentAuth.email}
            icon={<AccountCircle />}
            className="p-auto mb-3"
          />
          <Typography paragraph>
          {t(
              "Please check your inbox for a message from the SLEEPREGISTRY, and follow the instructions.",
              { SLEEPREGISTRY: process.env.REACT_APP_TITLE }
            )}
          </Typography>
          <Typography paragraph>
            {t(
              "Did you not receive any email? Try your spam folder or resend the email"
            )}
          </Typography>
          <Button
            fullWidth
            type="button"
            disabled={isLoading || timer > 0}
            variant="outlined"
            color="inherit"
            className="text-secondary text-decoration-none"
            onClick={async (e) => {
              setIsLoading(true);
              await handleSendVerifyEmailLink();
              setIsLoading(false);
              // Inform the user
              await dispatch(
                setAlert({
                  type: "snackbar",
                  variant: "success",
                  message: t("Verification email was sent."),
                  duration: 10000,
                })
              );
            }}
          >
            {timer > 0
              ? t("Resend Verification Email (please wait)")
              : t("Resend Verification Email")}
          </Button>
          {timer > 0 && (
            <LinearProgress
              variant="determinate"
              value={(100 * (60 - timer)) / 60}
              className="rounded lin-prog-margin-4px"
            />
          )}
        </React.Fragment>
      ) : actionMode === "sendVerifyEmailLink" &&
        currentAuth &&
        currentAuth.emailVerified ? (
        // -----------------------------------------------
        // SHOW FEEDBACK THAT THE EMAIL IS ALREADY VERIFIED
        <React.Fragment>
          <Helmet>
            <title>
              {process.env.REACT_APP_TITLE} | {t("Verify account")}
            </title>
          </Helmet>
          <Typography variant="h1" className="mb-2">
            {t("You're all set")}
          </Typography>
          <Typography variant="h3" color="textSecondary" className="mb-3">
            {t("Your email address is already verified")}
          </Typography>
          <Button
            fullWidth
            type="button"
            variant="contained"
            color="primary"
            component={NavLink}
            to="/welcome"
          >
            {t("Continue")}
          </Button>
        </React.Fragment>
      ) : actionMode === "confirmToRemoveAccount" ? (
        // -----------------------------------------------
        // ASK TO PERMANENTLY REMOVE THE ACCOUNT
        <React.Fragment>
          <Helmet>
            <title>
              {process.env.REACT_APP_TITLE} | {t("Remove account")}
            </title>
          </Helmet>
          <Typography variant="h1" className="mb-2">
            {t("Permanently remove your account")}
          </Typography>
          <Typography variant="h3" color="textSecondary" className="mb-3">
            {t("Are you sure to remove your account?")}
          </Typography>
          {currentAuth && (
            <Chip
              variant="outlined"
              label={currentAuth.email}
              icon={<AccountCircle />}
              className="p-auto mb-3"
            />
          )}
          <Alert severity="warning" className="align-items-center mb-3">
            {t("This cannot be undone")}
          </Alert>
          <Grid container>
            <Grid item xs={6} className="pe-2">
              <Button
                fullWidth
                disabled={isLoading}
                type="submit"
                variant="contained"
                color="primary"
                component={NavLink}
                to="/account"
              >
                {t("Keep Account")}
              </Button>
            </Grid>
            <Grid item xs={6} className="ps-2">
              <Button
                fullWidth
                disabled={isLoading}
                type="button"
                variant="contained"
                color="error"
                onClick={handleRemoveAccount}
              >
                {t("Remove Account")}
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      ) : actionMode === "hasRemovedAccount" ? (
        <Grid container alignItems="center" justifyContent="center">
          <Helmet>
            <title>
              {process.env.REACT_APP_TITLE} | {t("Goodbye")}
            </title>
          </Helmet>
          <Grid item xs={9}>
            <img
              alt="Thank you and goodbye"
              src="images/icon-goodbye.png"
              className="w-100"
            />
          </Grid>
          <Grid item>
            <Typography variant="h1" className="mb-3">
              {t("We're sorry to see you go")}
            </Typography>
            <Typography variant="h3" color="textSecondary">
              {t("We'll stay right here, in case you ever want to return.")}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </MainCard>
  );
};
// =================================================
// EXPORT COMPONENT
export default AuthUserManagement;
