// ########################################
// IMPORT
// ========================================
// Dependencies
import React from "react";
// ----------------------------------------
// Components
import Header from "./App_Header";
import Navigation from "./App_Navigation";
import FeedbackForm from "./App_FeedbackForm";
// ========================================
// Material UI
import Box from "@mui/material/Box";
// ########################################
// FUNCTIONAL COMPONENT
const Root = (props) => {
  // ========================================
  // RETURN
  return (
    <Box sx={{ display: "flex" }}>
      <FeedbackForm />
      <Header forceNavDrawer={props.forceNavDrawer}>{props.header}</Header>
      {props.drawerVariant !== "none" && (
        <Navigation drawerVariant={props.drawerVariant} />
      )}
      <Box component="main" className="vh-100 w-100">
        {props.children}
      </Box>
    </Box>
  );
};
// ########################################
// EXPORT
export default Root;
