// =================================================
// IMPORT
// -------------------------------------------------
// React
import React from "react";
// MUI Components
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { InputAdornment } from "@mui/material";
import { Typography } from "antd";

// =================================================
// FUNCTIONAL COMPONENT
const ForkSurveyDialog = ({
  open,
  onClose,
  onSubmit,
  version,
  setVersion,
  changelog,
  setChangelog,
}) => {
  const { t } = useTranslation("components", {
    keyPrefix: "surveys.Surveys_ForkSurveyDialog",
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="fork-survey-dialog-title"
    >
      <DialogTitle id="fork-survey-dialog-title">
        {t("Fork Survey")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>
            {t("Please enter the new version name and describe the updates")}
          </Typography>
          <TextField
            autoFocus
            fullWidth
            variant="filled"
            margin="dense"
            label={t("Version")}
            value={version}
            onChange={(e) => setVersion(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ marginRight: "2px" }}>
                  v
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            multiline
            margin="dense"
            label={t("Changelog")}
            variant="filled"
            rows={4}
            value={changelog}
            onChange={(e) => setChangelog(e.target.value)}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("Cancel")}</Button>
        <Button onClick={onSubmit} color="primary">
          {t("Fork")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// =================================================
// EXPORT COMPONENT
export default ForkSurveyDialog;
